import React, {useEffect, useState} from 'react';
import {FormControlLabel, Switch, TextField} from "@material-ui/core";


const OtherData = (props: any) => {

    useEffect(() => {
    }, []);

    const [constructorHasRun, setConstructorHasRun] = useState(false);

    const constructor = () => {
        if (constructorHasRun) return;
        console.log(props.data);
        setConstructorHasRun(true);
    };

    constructor();


    const setNewState = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.setNewState) {
            props.setNewState(event);
        }
    }


    return (
        <div>
            <ul className="floatList">
                <li style={{width: "91.8%"}}>
                    <TextField className="kontakt-feld"
                               id="UserDeathDate"
                               label="verstorben am"
                               type="date"
                               defaultValue=""
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               fullWidth
                               value={props.data.DeathDate}
                               disabled={true}/>
                </li>
                <li style={{width: "91.8%"}}>
                    <FormControlLabel control={
                        <Switch
                            defaultChecked
                            color="primary"
                            id="UserExist"
                            checked={props.data.UserExit}
                            onChange={setNewState}
                            inputProps={{'aria-label': 'Aus dem Fanclub ausgetreten'}}
                        />}
                                      label="Benutzer hat den Fanclub verlassen"/>

                </li>
                <li style={{width: "75%"}}>
                    {props.role == 9 && <TextField className="kontakt-feld"
                                id="Role"
                                label="Rolle (0 Benutzer, 1 Karten, 2 Kassierer, 3 Schriftf&uuml;hrer, 4 Vorstand, 9 Webadmin)"
                                type="text"
                                fullWidth
                                value={props.data.Role}
                                onChange={setNewState}
                                required
                    />}
                </li>
            </ul>

        </div>
    )

}

export default OtherData;
