import {useEffect} from "react";
import {withRouter} from "react-router-dom";

function FacebookSite() {
    useEffect(() => {
        document.location.href = "https://www.facebook.com/FCBForellenbachtal";
    }, []);
    return (
        <div className="content">
            Seite wird geladen...
        </div>
    )
}

export default withRouter(FacebookSite);
