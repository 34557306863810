import React, {useEffect, useRef, useState} from 'react';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.material-purple.css';
import JqxEditor from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxeditor';
import {getWindowDimensions, useStyles} from "../App";
import JqxGrid from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid";


const AppEditor = (props: any) => {
    const classes = useStyles();

    const myEditor = useRef<JqxEditor>(null);

    const [value, setValue] = React.useState("");

    const addImage = (event: any) => {
        return false;
    }

    const onChange = (e: any) => {
        if(e !== undefined){
            let text = myEditor.current!.val();
            if (props.onChange !== undefined)
                props.onChange(text);
        }
    };

    useEffect(() => {
    }, []);

    const createJqxCommand = (name: string | undefined) => {
        switch (name) {
            case "image":
                return {
                    type: 'button',
                    tooltip: 'Bild einfügen',
                    init: (widget: any) => {
                        widget.jqxButton({height: 25, width: 21}).on("click", addImage);
                        //widget.html("<span style='line-height: 23px;'>Bild</span>");
                    },
                    refresh: (widget: any) => {
                        // do something here when the selection is changed.

                    },
                    action: (widget: any) => {
                        const widgetValue = widget.val();
                        return {
                            command: 'inserthtml',
                            value: "<img src='' alt='' />"
                        };
                    }
                }
            case 'datetime':
                return {
                    type: 'list',
                    tooltip: 'Datum Uhrzeit einfügen',
                    init: (widget: any) => {
                        widget.jqxDropDownList({
                            placeHolder: 'Datum/Zeit',
                            width: 160,
                            source: ['Uhrzeit', 'Datum'],
                            autoDropDownHeight: true
                        });
                    },
                    refresh: (widget: any) => {
                        // do something here when the selection is changed.
                        widget.jqxDropDownList('clearSelection');
                    },
                    action: (widget: any) => {
                        const widgetValue = widget.val();
                        const date = new Date();
                        let hour = date.getHours().toString().length === 1 ? "0" + date.getHours().toString() : date.getHours().toString();
                        let month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
                        return {
                            command: 'inserthtml',
                            value: widgetValue == 'Uhrzeit' ? hour + ':' + date.getMinutes() : date.getDate() + '.' + month + '.' + date.getFullYear()
                        };
                    }
                }
        }

    }
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    return (
        <aside className={classes.aside}>
            <JqxEditor
                ref={myEditor}
                width={windowDimensions.width - 285} height={"700px"}
                onChange={onChange}
                createCommand={createJqxCommand}
                lineBreak="p"
                localization={{
                    "bold": "Fett",
                    "italic": "Kursiv",
                    "underline": "Unterstreichen",
                    "format": "Block-Format",
                    "font": "Schriftart",
                    "size": "Schriftgröße",
                    "color": "Textfarbe",
                    "background": "Hintergrundfarbe",
                    "left": "Links ausrichten",
                    "center": "Zentriert/Mitte ausrichten",
                    "right": "Rechts ausrichten",
                    "outdent": "Weniger Einzug",
                    "indent": "Mehr Einzug",
                    "ul": "Ungeordnete Liste einfügen",
                    "ol": "Geordnete Liste einfügen",
                    "image": "Bild einfügen",
                    "link": "Link einfügen",
                    "html": "Html-Code anzeigen",
                    "clean": "Formatierung entfernen"
                }}
                tools={"bold italic underline | format font size | color background | left center right justify | outdent indent | ul ol | image | link | clean | html | datetime"}
            >

            </JqxEditor>
            </aside>
    )

}

export default AppEditor;
