import {AnyAction, applyMiddleware, combineReducers, compose, createStore} from '@reduxjs/toolkit';
import userReducer from '../reducers/userReducer';
import appReducer from "../reducers/appReducer";
import {composeWithDevTools} from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import {connectRouter, routerMiddleware} from "connected-react-router";
import {createHashHistory} from "history";
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2"; // defaults to localStorage for web
import expireIn from "redux-persist-transform-expire-in";
import rootSaga from "../sagas";

const composeEnhancer: typeof compose = composeWithDevTools({ });
const sagaMiddleware = createSagaMiddleware();

const history = createHashHistory();

const rootReducer = combineReducers<any, AnyAction>({
    router: connectRouter(history),
    appData: appReducer,
    userData: userReducer
});


const expireInTime = 30 * 24 * 60 * 60 * 1000; // expire in 48h
const expirationKey = "expirationKey";

const persistConfig = {
    key: "FCB_HOHENFELS_DE_2022_07_08",
    version: 2022_07_08_1,
    storage,
    stateReconciler: autoMergeLevel2,
    transforms: [expireIn(expireInTime, expirationKey, [])],
    whitelist: ["userData","appData"]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const appStore = createStore(
    persistedReducer,
    composeEnhancer(
        applyMiddleware(
            routerMiddleware(history),
            sagaMiddleware
        )
    ),
);

const persistor = persistStore(appStore)
sagaMiddleware.run(rootSaga)

export {history, persistor}

export default appStore
