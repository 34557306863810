import {createSlice} from "@reduxjs/toolkit";
import {RegisterEvent} from "../app/dto/appData";

export const appSlice = createSlice({
    name: 'appData',
    initialState: {
        NewsletterText: "",
        succeded: false,
        succededText: "",
        failed: false,
        errorText: "",
        openImportDialog: false,
        contactFormular: {
            Name: "",
            City: "",
            Email: "",
            Website: "",
            Subject: "",
            Message: "",
            ValidCaptcha: "",
            Recipient: {
                executive: false,
                memberService: false,
                cardsService: false,
                webMaster: true,
            }
        },
        Event: {
            Current: 0,
            Notes: "",
            LastName: "",
            FirstName: "",
            LoginNumber: "",
            LoginName: "",
            Unkosten:false,
            GemischterSalat: false,
            KartoffelSalat: false,
            NudelSalat: false,
            WurstSalat: false,
            GurkenSalat: false,
            TomatenSalat: false,
            Kuchen: false,
            CountGemischterSalat: 0,
            CountKartoffelSalat: 0,
            CountNudelSalat: 0,
            CountWurstSalat: 0,
            CountGurkenSalat: 0,
            CountTomatenSalat: 0,
            CountKuchen: 0,
            UserId: null,
            EventId: null
        },
        events: new Array<RegisterEvent>()
    },
    reducers: {
        setAppData: (state, action) => {
            if (state.hasOwnProperty(action.payload.key)) {
                // @ts-ignore
                state[action.payload.key] = action.payload.value;
            }
        },
        hideSnackbar: (state) => {
            state.succeded = false;
            state.failed = false;
            state.succededText = "";
            state.errorText = "";
        },
        showSuccededSnackbar: (state, action) => {
            state.succeded = true;
            state.failed = false;
            state.succededText = action.payload.message;
            state.errorText = "";
        },
        showFailedSnackbar: (state, action) => {
            state.succeded = false;
            state.failed = true;
            state.succededText = "";
            state.errorText = action.payload.message;
        },
        addContact: (state) => {
            // sendet das Kontaktformular ab.
        },
        getEventByType: (state) => {
            // liest die Veranstaltungen aus.
        },
        getEventData: (state) => {
            // liest die Anzahl der angemeldeten Salate/Kuchen.
        },
        setEventByType: (state, action) => {
            state.Event.Current = action.payload.eventType;
        },
        setNewsletterText: (state, action) => {
            state.NewsletterText = action.payload.value;
        },
        addUserToEvent: (state, action) => {
            console.log("UserId: " + action.payload.UserId)
            state.Event.UserId = action.payload.UserId;
        },
        setAllEvents: (state, action) => {
            console.log("Events: " + action.payload)
            state.events = action.payload;
        },
        setEventId: (state, action) => {
            state.Event.EventId = action.payload.EventId;
        },
        setEventDataCount: (state, action) => {
            state.Event.CountGemischterSalat = action.payload.CountGemischterSalat;
            state.Event.CountKartoffelSalat = action.payload.CountKartoffelSalat;
            state.Event.CountNudelSalat = action.payload.CountNudelSalat;
            state.Event.CountWurstSalat = action.payload.CountWurstSalat;
            state.Event.CountGurkenSalat = action.payload.CountGurkenSalat;
            state.Event.CountTomatenSalat = action.payload.CountTomatenSalat;
            state.Event.CountKuchen = action.payload.CountKuchen;
        },
        registerToEvent: (state) => {
            // meldet den Benutzer beim Event an.
        },
        getAllEvents: (state) => {
            // lest alle definierten Events aus de DB
        },
        sendNewsletterMail: (state) => {
            // sendet Newsletter.
        },
        unregisterToEvent: (state) => {
            // meldet den Benutzer beim Event ab.
        },
        sendNewsletterMailSucceeded: (state, action) => {

        },
        sendNewsletterMailFailed: (state, action) => {

        },

        setContactFormularData: (state, action) => {
            const name = action.payload.key;
            if ((name === "cardsService" ||
                    name === "executive" ||
                    name === "memberService" ||
                    name === "webMaster") &&
                state.contactFormular.Recipient.hasOwnProperty(name)) {
                // @ts-ignore
                state.contactFormular.Recipient[name] = action.payload.value;
            } else if (state.contactFormular.hasOwnProperty(name)) {
                // @ts-ignore
                state.contactFormular[name] = action.payload.value;
            }
        },

        setEventLoginData: (state, action) => {
            const name = action.payload.key;
            if (state.Event.hasOwnProperty(name)) {
                // @ts-ignore
                state.Event[name] = action.payload.value;
            }
        },
    },

})

export const {
    setAppData,
    addContact,
    hideSnackbar,
    showFailedSnackbar,
    showSuccededSnackbar,
    setContactFormularData,
    setEventLoginData,
    setEventByType,
    getEventByType,
    setNewsletterText,
    getEventData,
    registerToEvent,
    sendNewsletterMail,
    unregisterToEvent,
    setEventId,
    addUserToEvent,
    setAllEvents,
    getAllEvents,
    setEventDataCount
} = appSlice.actions;

export default appSlice.reducer;
