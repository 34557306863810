import React, {ChangeEvent, useEffect} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField} from "@material-ui/core";
import {useStyles} from "../App";
import {
    checkRegistrationData,
    checkSecureCode,
    confirmRegistrationData,
    currentUserData,
    getUserEvents,
    login,
    logout, newPassword, newRegistration,
    resetLoginSate,
    setLoginData,
    setNewPasswordData,
    setNewPasswordDialog,
    setUserData,
    setUserId
} from "../reducers/userReducer";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../app/appState";
import {withRouter} from "react-router-dom";
import AppSnackbar from "../components/snackbar";
import PasswordStrengthBar from "react-password-strength-bar";
import {showFailedSnackbar} from "../reducers/appReducer";

function AnmeldeSeite() {

    const stateData = useSelector((state: AppState) => state);
    const appData = stateData.appData;
    const userData = stateData.userData;
    const user = userData.currentUser;
    const dispatch = useDispatch();
    const hash = window.location.hash;
    let hashSplit = hash.split("?");
    let key = "";
    if (hashSplit.length > 1) {
        let search = hashSplit[1];
        key = search.substr(2);

        if (search.substr(0, 1) === "k" && !userData.setNewPassword) {
            dispatch(setNewPasswordDialog({setNewPassword: true, key: key}));
        } else if (search.substr(0, 1) === "r" && !userData.confirmRegistration) {
            dispatch(setUserId({Id: key, confirmRegistration: false}));
            dispatch(checkRegistrationData());
        }
    } else if (userData.setNewPassword) {
        dispatch(setNewPasswordDialog({setNewPassword: false, key: ""}));
    } else if (userData.confirmRegistration) {
        dispatch(setUserId({confirmRegistration: false, Id: ""}));
    }

    const UserAuthInitial = {
        AuthCode: {
            error: false,
            helperText: ''
        }
    };

    const twoWayAuthFieldsValid = () => {
        let isValid = true;
        let newUserAuthState = {
            AuthCode: {
                error: false,
                helperText: ''
            }
        };
        if (user.Login.twoFactoryAuthentication.code.length === 0) {
            newUserAuthState.AuthCode.error = true;
            newUserAuthState.AuthCode.helperText = 'Eingabe erforderlich!';
            isValid = false;
        }

        setUserAuthState(newUserAuthState);
        return isValid;
    };

    const [UserAuth, setUserAuthState] = React.useState(UserAuthInitial);

    useEffect(() => {
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            bg.className = 'hohenfels';
    }, []);

    const classes = useStyles();

    const setNewState = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.id;
        if (event.target.type === "checkbox") {
            dispatch(setLoginData({key: name, value: event.target.checked}));
        } else {
            dispatch(setLoginData({key: name, value: String(event.target.value)}));
        }
    };

    const senden = () => {

        if(user.Login.newPassword ){
            dispatch(newPassword());
        } else if (user.Login.registration){
            dispatch(newRegistration());
        }

        else if (userData.setNewPassword) {
            if (userData.currentUser.Login.newLoginPassword !== userData.currentUser.Login.newLoginPasswordConfirmed) {
                dispatch(showFailedSnackbar({message: "Die eingegebenen Kennwörter stimmen nicht überein! Bitte wiederhole deine Eingaben."}))
            } else {
                dispatch(setNewPasswordData());
            }
        } else if (userData.confirmRegistration) {
            dispatch(confirmRegistrationData());
        } else {
            dispatch(login());
        }
    };

    const gotoStartView = () => {
        dispatch(getUserEvents());
        if (hashSplit.length > 1) {
            let search = hashSplit[1];
            if (search.substr(0, 1) === "k") {
                dispatch(resetLoginSate());
            } else if (search.substr(0, 1) === "r") {
                dispatch(resetLoginSate());
            }
            dispatch(logout());
            window.location.href = "#/aktuelles";
        } else if (user.Id !== null &&
            user.Login.twoFactoryAuthentication.useToken &&
            user.Login.twoFactoryAuthentication.code.length === 6) {
            if (appData.failed) {
                dispatch(logout());
            } else if (appData.succeded) {
                dispatch(currentUserData());
            }
            window.location.href = "#/aktuelles";
        } else
            window.location.href = "#/aktuelles";
    }

    const setAuthenticationCode = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length <= 6) {
            dispatch(setUserData({key: "code", value: String(e.target.value)}));
        }
    };

    const validateSecureCodeFinish = () => {
        if (twoWayAuthFieldsValid()) {
            dispatch(checkSecureCode());
        }
    };

    return (
        <div className="content" >
            <h3>Benutzeranmeldung f&uuml;r Mitglieder des FC Bayern Fanclub Forellenbachtal Hohenfels</h3>
            <aside>
                <FormControl className={classes.root}>
                    {user.Login.login && <article className={classes.maxSize}>
                        <p>Du kannst dich auf unserer Webseite anmelden und </p>
                        <ul className="list">
                            <li>deine pers&ouml;nlichen Daten, die wir im Rahmen deiner Mitgliedschaft gespeichert haben
                                bearbeiten
                            </li>
                            <li>dich f&uuml;r geplante Veranstaltungen anmelden</li>
                            <li>dich f&uuml;r Fussballfahrten anmelden</li>
                        </ul>
                        <p>Bist du bereits Mitglied im FC Bayern Fanclub Forellenbachtal Hohenfels und du hast noch
                            keine
                            Anmeldedaten, dann w&auml;hle die Option <i><b>Registrieren</b></i>. Hast du dein
                            Anmeldekennwort
                            vergessen,
                            dann w&auml;hle die Option <i><b>Kennwort vergessen</b></i>.</p>
                        <TextField className="kontakt-feld" id="loginName" label="E-Mail Adresse" type="email"
                                   fullWidth
                                   value={user.Login.loginName}
                                   onChange={setNewState}
                                   required/>
                        <TextField className="kontakt-feld" id="loginNumber" label="Anmeldenummer" type="number"
                                   fullWidth
                                   value={user.Login.loginNumber}
                                   onChange={setNewState}
                                   required/>
                        <TextField className="kontakt-feld" id="loginPassword" label="Kennwort" type="password"
                                   fullWidth
                                   value={user.Login.loginPassword}
                                   onChange={setNewState}
                                   required/>
                        <Button id="registrieren" disableFocusRipple disableRipple style={{textTransform: "none"}}
                                variant="text" onClick={() => {
                            dispatch(setLoginData({key: "registration", value: true}));
                        }
                        }>Registrieren</Button>

                        <Button id="neuesKennwort" disableFocusRipple disableRipple style={{textTransform: "none"}}
                                variant="text" onClick={() => {
                            dispatch(setLoginData({key: "setNewPassword", value: true}));

                        }}>Kennwort vergessen</Button>
                    </article>}
                    {user.Login.registration && <article>
                        <h4>Benutzerregistrierung</h4>
                        <p>Gebe bitte deine e-Mailadresse und ein Kennwort ein, um dich zu registrieren. Wenn deine
                            Mitgliedschaft im Fanclub Forellenbachtal Hohenfels best&auml;tigt werden kann,
                            erh&auml;lst du in den n&auml;chsten Tagen einen Aktivierungslink per e-Mail zugesand, wo du
                            dann dein Benutzerkonto aktivieren kannst. Erst nach erfolgter Aktivierung deines
                            Benutzerkontos, kannst du dich hier dann anmelden.</p>
                        <TextField className="kontakt-feld" id="registrationEmail" label="E-Mail Adresse" type="email"
                                   fullWidth
                                   autoFocus value={user.Login.registrationEmail}
                                   onChange={setNewState}
                                   required/>
                        <TextField className="kontakt-feld" id="registrationLoginNumber" label="Anmeldenummer"
                                   type="number"
                                   fullWidth
                                   value={user.Login.registrationLoginNumber}
                                   onChange={setNewState}
                                   required/>
                        <TextField className="kontakt-feld" id="registrationPassword" label="Kennwort" type="password"
                                   fullWidth
                                   value={user.Login.registrationPassword}
                                   onChange={setNewState}
                                   required/>
                        <PasswordStrengthBar
                            minLength={6}
                            password={user.Login.registrationPassword}
                            scoreWords={['schwach', 'schwach', 'okay', 'gut', 'stark']}
                            shortScoreWord="zu kurz"/>
                        <Button id="abbrechen" disableFocusRipple disableRipple style={{textTransform: "none"}}
                                variant="text" onClick={() => {
                            dispatch(setLoginData({key: "registration", value: false}));
                        }}>Abbrechen</Button>
                    </article>}
                    {user.Login.newPassword && <article>
                        <h4>Kennwort vergessen</h4>
                        <p>Gebe bitte deine e-Mailadresse ein, um dein Kennwort zur&uuml;ckzusetzen. </p>
                        <p>Du bekommst dann an deine e-Mailadresse einen Link zugesandt, so dass du dein neues Kennwort
                            festlegen kannst. Beachte dabei, dass der Link nur 24 Stunden g&uuml;ltig ist.</p>
                        <TextField className="kontakt-feld" id="loginName" label="E-Mail Adresse" type="email"
                                   fullWidth
                                   value={user.Login.loginName}
                                   onChange={setNewState}
                                   required/>
                        <TextField className="kontakt-feld" id="loginNumber" label="Anmeldenummer" type="number"
                                   fullWidth
                                   value={user.Login.loginNumber}
                                   onChange={setNewState}
                                   required/>

                        <Button id="abbrechen" disableFocusRipple disableRipple
                                style={{textTransform: "none", float: "right"}}
                                variant="text" onClick={() => {
                            dispatch(setLoginData({key: "setNewPassword", value: false}));
                        }}>Abbrechen</Button>

                    </article>}
                    {userData.confirmRegistration && <article>
                        <h4>Registrierung abschlie&szlig;en</h4>
                        <p>Gebe bitte dein Kennwort aus deiner Registrierung ein und schlie&szlig;e die Registrierung
                            ab. Danach kannst du dich im
                            Mitgliederbereich anmelden.
                        </p>
                        <TextField className="kontakt-feld" id="registrationPasswordConfirmed" label="Kennwort"
                                   type="password"
                                   fullWidth
                                   value={user.Login.registrationPassword}
                                   onChange={setNewState}
                                   required/>
                        <PasswordStrengthBar
                            minLength={6}
                            password={user.Login.registrationPassword}
                            scoreWords={['schwach', 'schwach', 'okay', 'gut', 'stark']}
                            shortScoreWord="zu kurz"/>
                    </article>}
                    {userData.setNewPassword && <article>
                        <h4>Neues Password vergeben</h4>
                        <p>Gebe bitte dein neues Kennwort ein. <br/> Bitte w&auml;hle dabei ein starkes und nicht zu
                            kurzes Kennwort ein. <br/>
                            Die Mindestl&auml;nge des Kennwort betr&auml;gt 6 Zeichen.
                            Unterhalb des Kennwortfeldes wird dir die Qualit&auml;t der Kennwortst&auml;rke unmittelbar
                            angezeigt.<br/>
                            Bitte best&auml;tige dein neues Kennwort durch die wiederholte Eingabe deines Kennwortes.
                        </p>
                        <TextField className="kontakt-feld" id="newLoginPassword" label="neues Kennwort" type="password"
                                   fullWidth
                                   value={user.Login.newLoginPassword}
                                   onChange={setNewState}
                                   required/>
                        <PasswordStrengthBar
                            minLength={6}
                            password={user.Login.newLoginPassword}
                            scoreWords={['schwach', 'schwach', 'okay', 'gut', 'stark']}
                            shortScoreWord="zu kurz"/>
                        <TextField className="kontakt-feld" id="newLoginPasswordConfirmed"
                                   label="neues Kennwort wiederholen" type="password"
                                   fullWidth
                                   value={user.Login.newLoginPasswordConfirmed}
                                   onChange={setNewState}
                                   required/>
                        <PasswordStrengthBar
                            minLength={6}
                            password={user.Login.newLoginPasswordConfirmed}
                            scoreWords={['schwach', 'schwach', 'okay', 'gut', 'stark']}
                            shortScoreWord="zu kurz"/>
                    </article>}
                    {user.Login.twoFactoryAuthentication.useToken &&
                    <Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        maxWidth="xs"
                        aria-labelledby="auth-token-code-dialog"
                        open={user.Login.twoFactoryAuthentication.useToken}
                    >
                        <DialogTitle id="auth-token-code-dialog">Authentifizierungscode eingeben</DialogTitle>
                        <DialogContent>
                            <TextField className="kontakt-feld" id="authCode" label="Best&auml;tigungscode"
                                       type="number"
                                       fullWidth
                                       autoFocus
                                       value={user.Login.twoFactoryAuthentication.code}
                                       aria-valuemax={999999}
                                       onChange={setAuthenticationCode}
                                       error={UserAuth.AuthCode.error}
                                       helperText={UserAuth.AuthCode.helperText}
                                       required/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={validateSecureCodeFinish} color="primary">
                                Best&auml;tigen
                            </Button>
                        </DialogActions>
                    </Dialog>
                    }
                    <article>
                        <Button variant="outlined" className="btnSubmit" onClick={senden}>
                            Senden
                        </Button>
                    </article>

                    <AppSnackbar message={appData.succeded ? appData.succededText : appData.errorText}
                                 showSnackbar={appData.succeded || appData.failed}
                                 severity={appData.failed ? "error" : "success"}
                                 onClose={gotoStartView}
                    />
                </FormControl>
            </aside>

        </div>
    )
}

export default withRouter(AnmeldeSeite);
