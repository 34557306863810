import React, {useEffect, useState} from 'react';
import {TextField} from "@material-ui/core";


const PaymenmtData = (props: any) => {

    const PaymentInitial = {
        BankName: {
            error: false,
            helperText: ''
        },
        Owner: {
            error: false,
            helperText: ''
        },
        IBAN: {
            error: false,
            helperText: ''
        },
        BIC: {
            error: false,
            helperText: ''
        }
    };

    const [Payment, setPaymentState] = React.useState(PaymentInitial);

    useEffect(() => {
    }, []);

    const [constructorHasRun, setConstructorHasRun] = useState(false);

    const constructor = () => {
        if (constructorHasRun) return;
        console.log(props.data);
        setConstructorHasRun(true);
    };

    constructor();

    const setNewState = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.setNewState) {
            props.setNewState(event);
        }
    }

    return (
        <div>
            {!props.isAdmin &&
            <div><p>Hier kannst du deine Bankverbindungsdaten bearbeiten. Deine Bankverbindungsdaten werden
                verschl&uuml;sselt in unserem System abgelegt. Zu deiner Sicherheit, werden deine Bankdaten
                hierauch nicht vollst&auml;ndig angezeigt. Wenn sich deine Daten nicht ge&auml;ndert haben,
                brauchst
                du hier nichts eingeben.</p>
                <div className="infoBox">
                    <p><b>Bitte beachte folgenden Hinweis</b><br/>
                        Deine hinterlegten Kontodaten werden bei uns verschl&uuml;sselt hinterlegt, so dass nur
                        berechtigte Vorstandsmitglieder Zugang zu diesen Informationen haben.<br/>
                        Zu deiner Sicherheit werden dir hier nur Fragmente deiner bereits hinterlegten IBAN und
                        BIC
                        angezeigt. Wenn also kein Grund zur &Auml;nderung der Bankverbindungsdaten besteht,
                        brauchst du
                        hier nichts weiter eintragen.</p>
                </div>
            </div>}
            <ul className="floatList">
                <li style={{width: "91.8%"}}>
                    <TextField className="kontakt-feld"
                               id="BankName"
                               label="Name der Bank"
                               type="text"
                               fullWidth
                               value={props.data.BankName}
                               onChange={setNewState}
                               error={Payment.BankName.error}
                               helperText={Payment.BankName.helperText}
                               required/>
                </li>
                <li style={{width: "91.8%"}}>
                    <TextField className="kontakt-feld"
                               id="Owner"
                               label="Kontoinhaber"
                               type="text"
                               fullWidth
                               value={props.data.Owner}
                               onChange={setNewState}
                               error={Payment.Owner.error}
                               helperText={Payment.Owner.helperText}
                               required/>
                </li>
                <li style={{width: "91.8%"}}>
                    <span>IBAN: {props.data.IBANSEC}</span>
                    <TextField className="kontakt-feld"
                               id="IBAN"
                               label="IBAN"
                               type="text"
                               fullWidth
                               value={props.data.IBAN}
                               onChange={setNewState}
                               error={Payment.IBAN.error}
                               helperText={Payment.IBAN.helperText}
                               required
                    />
                </li>
                <li style={{width: "91.8%"}}>
                    <span>BIC: {props.data.BICSEC}</span>
                    <TextField className="kontakt-feld"
                               id="BIC"
                               label="BIC"
                               type="text"
                               fullWidth
                               value={props.data.BIC}
                               onChange={setNewState}
                               error={Payment.BIC.error}
                               helperText={Payment.BIC.helperText}
                               required
                    />
                </li>
                <li>
                    <TextField className="kontakt-feld"
                               id="PaymentChangeDate"
                               label="zu Letzt ge&auml;ndert am"
                               type="date"
                               defaultValue=""
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               fullWidth
                               value={props.data.PaymentChangeDate}
                               disabled={true}/>
                </li>
            </ul>
        </div>
    )

}

export default PaymenmtData;
