import {Link, withRouter} from "react-router-dom";
import React, {useEffect} from "react";
import ImageViewer from "../components/imageviewer";
import {gebTage, vorstand} from "../stammdaten";
import ReactPlayer from 'react-player/lazy'
import {AppState} from "../app/appState";
import PDFViewer from "../components/pdfViewer";
import * as config from "../app/config";
import {getActiveUsersCount} from "../reducers/userReducer";
import {useDispatch, useSelector} from "react-redux";

const validateBirthday = () => {
    let data = gebTage;
    let birthdayItems = [];
    for (let d = 0; d < data.length; d++) {
        let item = data[d];
        if (item.gebDate.length > 0) {
            let itemGebDate = item.gebDate.split(".");
            let day = itemGebDate[0];
            let month = itemGebDate[1];
            let year = itemGebDate[2];
            let currentDate = new Date();
            let _day = currentDate.getDate();
            let _month = currentDate.getMonth() + 1;
            let _year = currentDate.getFullYear();
            if (Number(day) === _day && Number(month) === _month) {
                let age = _year - Number(year);
                birthdayItems.push({
                    Name: item.name,
                    Age: age
                });
            }
        }

    }

    return birthdayItems.map((item: any) =>
        <article className="gebCard">
            <h2>Herzlichen Gl&uuml;ckwunsch, {item.Name}</h2>
            <p>Wir alle vom FC Bayern Fanclub Forellenbachtal Hohenfels w&uuml;nschen Dir alles Gute zu deinem heutigen
                Geburtstag. Bleib gesund und feier heute ordentlich.</p>
            <br/>
            <i>{vorstand.ErsterVorstand.Name}</i>

        </article>
    );
}


function Aktuelles() {
    const dispatch = useDispatch();
    const stateData = useSelector((state: AppState) => state);
    const appData = stateData.appData;
    const userData = stateData.userData;

    const constructor = () => {
        dispatch(getActiveUsersCount());
    }


    useEffect(() => {

        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            bg.className = 'hohenfels';
    }, []);

    constructor();

    return (
        <div className="content">
            <h2>AKTUELLES</h2>
            {validateBirthday()}
            <div className="leereVorlage">
                <article className="articleContent">
                    <h4>&nbsp;</h4>
                    <p>&nbsp;</p>
                    <ImageViewer path="./events/images/.." items={[
                        {
                            name: "",
                            description: "",
                            src: "Bild1.jpg"
                        }]}/>
                    <ReactPlayer
                        url='./events/images/.../video/....mp4' controls={true}
                        width={"100%"}
                        loop={true}/>
                </article>
                <hr/>
            </div>
            <article className="articleContent">
                <h4>30.11.2024 - Weihnachtsfeier im Gasthaus Zur Taverne in Hohenfels</h4>
                <p>Es war wieder ein sehr schöner Abend, über 60 Mitglieder sind der Einladung zur jährlichen
                    Weihnachtsfeier in das Gasthaus Zur Taverne in Hohenfels gefolgt.</p>
                <p>Der erste Vorstand, Thomas Stadlmeier, begrüßte alle anwesenden Mitglieder und lud alle ein, den
                    Abend in geselliger Runde zu verbringen. Ebenso gab Thomas einen kurzen Überblick, über die nächsten
                    Termine. Besonders freute er sich, dass wir zum Basketballspiel des FC Bayern München und zum
                    Champions League Spiel im Januar 2025 fahren. Infos findet ihr unter der Rubrik <i>Termine</i> hier
                    auf der Webseite.</p>
                <p>Dass der BVB Dortmund die Mannschaft des FC Bayern München zu Gast hatte, machte den Abend um so
                    schöner, gleichwohl der BVB von der 27. Minute an mit 1:0 führte. Zum Glück war Jamal Musiala in
                    der 85. Minute mit seinem Köpfchen zur rechten Zeit an der richtigen Stelle und versenkte den Ball
                    zum 1:1 Ausgleich in den schwarz-gelben Kasten. Sicher wäre ein Sieg uns lieber gewesen, aber so
                    haben die Jungs wenigstens einen Punkt an die Isar entführt. Zwei glückliche Gewinner konnten sich
                    beim Tip-Spiel freuen.</p>
                <ImageViewer path="./events/images/2024_11_30_Weihnachtsfeier_2024/"  items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild01.jpg"
                    },{
                        name: "",
                        description: "",
                        src: "Bild06.jpg"
                    },{
                        name: "",
                        description: "",
                        src: "Bild10.jpg"
                    },{
                        name: "",
                        description: "",
                        src: "Bild24.jpg"
                    },
                ]}/>
                <p style={{fontSize:"small"}}>Weitere Bilder findet ihr in der Veranstaltungsübersicht im Bereich <i>Bilder</i></p>
                <p>Die Küche des Gasthauses gab im Hintergrund ihr Bestes und zauberte indes ein köstliches Abendessen.
                    Es gab panierte Schnitzel mit Pommes bzw. Schweinebraten mit Knödel und Soße, sowie einen kleinen
                    Salat. Ein besonderes Dankeschön geht an dieser Stelle auch an die Bedienungen Martina und Renate,
                    die alle Hände voll zu tun hatten, denn die Taverne war bis auf den letzten Platz gefüllt. </p>
                <p>Nach dem Essen waren alle schon gespannt auf die Tombola, warteten doch wieder viele interessante
                    Preise darauf, einen neuen Besitzer zu finden.</p>
                <p>Um 23:30 Uhr haben dann auch die letzten ihren Heimweg angetreten.</p>
                <p>Abschließend kann man sagen, es war wieder eine sehr schöne Weihnachtsfeier. Der Vorstand des FC
                    Bayern Fanclub bedankt sich bei allen, die mit dazu beigetragen haben, dass der Abend wieder so
                    schön statt finden konnte. </p>
            </article>
            <hr/>
            <article className="articleContent trauerContent">
                <h4 style={{color: "#000"}}>07.11.2024 - Abschied von Werner Ott</h4>
                <p>In gro&szlig;er Trauer nehmen wir Abschied von unserem Mitglied Werner Ott.
                    Danke, dass du mit dabei warst. Ruhe in Frieden.</p>
                <p>Unsere Anteilnahme gilt auch insbesondere der Familie und den Hinterbliebenen von Werner Ott.</p>
            </article>
            <hr/>
            <article className="articleContent">
                <h4>07.11.2024 - Einladungen zur Weihnachtsfeier verschickt</h4>
                <p>Am 30.11.2024 findet wieder unsere Weihnachtsfeier im Gasthaus zur Taverne in Hohenfels statt. Die
                    Einladungen sind per E-Mail an alle Mitglieder versandt worden. Wer noch keine
                    E-Mail-Adresse angegeben hat, hat seine Einladung in seinem Briefkasten gefunden.</p>
                <p>Denkt daran, dass am 17.11.2024 Anmeldeschluss ist.</p>
                <p>Wir freuen uns auf euer zahlreiches Erscheinen.</p>
                <p><a href="#/Termine?tab=4">Hier geht es zur Onlineanmeldung</a></p>

            </article>
            <hr/>
            <article className="articleContent trauerContent">
                <h4 style={{color: "#000"}}>31.10.2024 - Abschied von unserem Gr&uuml;ndungsmitglied Erika
                    Lang</h4>
                <p>In gro&szlig;er Trauer nehmen wir Abschied von unserem Gr&uuml;ndungsmitglied Erika
                    Lang. Erika war von der ersten Stunde dabei. Danke, Erika, f&uuml;r deine Verdienste im Fanclub -
                    Danke, dass du mit dabei warst. Ruhe in Frieden.</p>
                <p>Am 07.11.2024 findet in Hohenfels um 17:45 Uhr die Aussegnung und anschlie&szlig;endem Rosenkranz
                    statt. Der Trauergottesdienst mit anschlie&szlig;ender Urnenbeisetzung ist am Samstag, den
                    09.11.2024 um
                    10:30 Uhr in Hohenfels.</p>
                <p>Unsere Anteilnahme gilt auch insbesondere der Familie und den Hinterbliebenen von Erika Lang.</p>
            </article>
            <hr/>
            <article className="articleContent">
                <h4>07.09.2024 - Sommergrillen 2024</h4>
                <p>Bei wunderbarem Sommerwetter trafen sich wieder zahlreiche Mitglieder des FC Bayern Fanclub
                    Forellenbachtal Hohenfels zum alljährichen Sommergrillen.</p>
                <p>Auch in diesem Jahr wurde die kulinarische Seite vom <a href="http://www.wildhandel-waffler.de"
                                                                           target="_blank">Wildhandel Andreas
                    Waffler</a> sichergestellt.</p>
                <p>Der Wildhandel Waffler bietet dabei einen Fullservice an, den wir gerne in Anspruch genommen haben.
                    Dieses Jahr stand Spanferkel vom Grill auf der Speisekarte. Dazu gab es, wie es sich für gute
                    bayrische Küche gehört Knödel und Soße.</p>
                <p>Das üppige Salatangebot, welches einige Mitglieder sponsorten, rundete das Essen ab.</p>
                <p>Die anwesenden Kinder nutzten die Gelegenheit sich beim Ballspiel auf dem Sportplatz auszutoben.</p>
                <p>Die erwachsenen Mitglieder unterhielten sich bei Bier und anderen Getränken angeregt.</p>
                <ImageViewer path="./events/images/2024_09_07_Sommergrillen_2024/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild2.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild6.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild11.jpg"
                    }

                ]}/>
                <p>Weitere Bilder findet ihr unter dem Menüpunkt Bilder</p>
                <p>Alles in allem war es wieder ein sehr gelungenes Grillfest.</p>
                <p>Der Vorstand bedankt sich bei allen Helfern, Sponsoren und anwesenden Gästen für den schönen
                    Abend.</p>
            </article>
            <hr/>
            <article className="articleContent">
                <h4>02.08.2024 - Einladungen zum Sommergrillen verschickt</h4>
                <p>Am 07.09.2024 findet wieder unser Sommergrillen im Sportheim am Sportplatz Haarziegelhütte
                    Hohenfels statt. Die Einladungen sind per E-Mail an alle Mitglieder versandt worden. Wer noch keine
                    E-Mail-Adresse angegeben hat, hat seine Einladung in seinem Briefkasten gefunden.</p>
                <p>Denkt daran, dass am 25.08.2024 Anmeldeschluss ist.</p>
                <p>Wir freuen uns auf euer zahlreiches Erscheinen.</p>
                <p><a href="#/Termine?tab=3">Hier geht es zur Onlineanmeldung</a></p>

            </article>
            <hr/>
            <article className="articleContent">
                <h4>04.05.2024 - Kirchliche Trauung von Julia Hollmayer &amp; Daniel Kotzbauer</h4>
                <p>Am Samstag den 04.05.2024 best&auml;tigten Julia Hollmayer &amp; Daniel Kotzbauer ihren Bund der Ehe
                    vor Gott. </p>
                <p>Der FC Bayern Fanclub Hohenfels war mit vertreten zum Spalier stehen und überbrachte dem Brautpaar
                    die besten W&uuml;nsche.</p>
                <ImageViewer path="./events/images/2024_05_04_Kirchliche_Trauung_Daniel_Kotzbauer/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild1.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild2.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild3.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild4.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild5.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild6.jpg"
                    },
                ]}/>

            </article>
            <hr/>
            <article className="articleContent">
                <h4>20.04.2024 - Jahreshauptversammlung mit Neuwahlen</h4>
                <p>Am 20.04.2024 trafen sich die Mitglieder das FC Bayern Fanclubs Forellenbachtal Hohenfels zu ihrer
                    Jahreshauptversammlung</p>
                <ImageViewer path="./events/images/2024_04_20_JHV2024/" items={[
                    {
                        name: "",
                        description: "",
                        src: "2024.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild1.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild2.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild3.jpg"
                    }]}/>
                <p>Der Vorstandsvorsitzende Thomas Stadlmeier begr&uuml;&szlig;te alle anwesenden Mitglieder und gab
                    nach der
                    Schweigeminute zu Ehren der verstorbenen Mitglieder einen &Uuml;berblick &uuml;ber das Jahr 2023. So
                    fanden
                    viele Veranstaltungen statt, wie
                    <ul>
                        <li>10 Vorstandschaftsitzungen,</li>
                        <li>Fahrt in Blaue,</li>
                        <li>Kinderferienprogramm der Gemeinde,</li>
                        <li>Sommergrillfest Sportplatz Haarziegelh&uuml;tte,</li>
                        <li>3 gemeinsame Fahrten ins Stadion,</li>
                        <li>B&uuml;rgerfest Hohenfels,</li>
                        <li>Weihnachtsmarkt in Hohenfels,</li>
                        <li>Kinder Nikolausaktion am 06.12.23,</li>
                        <li>Weihnachtsfeier im Gasthaus „Zur Taverne“</li>
                    </ul></p>
                <p>Dar&uuml;ber hinaus war der Fanclub bei zahlreichen Veranstaltungen der Gemeinde mit vertreten, wie
                    zum
                    Beispiel
                    <ul>
                        <li>Sommernachtfest der FFW Hohenfels,</li>
                        <li>40 Jahre Burschenverein Hohenfels,</li>
                        <li>Neujahresempfang der Gemeinde,</li>
                        <li>...</li>
                    </ul>
                </p>
                <p>Sodann informierte der Vorstandsvorsitzende, dass die Mitgliederzahl unter Ber&uuml;cksichtigung der
                    Ein-
                    und Austritte relativ konstant bleibt. Die aktuelle Mitgliederzahl liegt bei {userData.Count}.</p>
                <p>Der erste Kassierer Lukas Ring gab dann einen &Uuml;berblick &uuml;ber die Finanzen des Fanclubs. Die
                    Kassenpr&uuml;fer best&auml;tigten die ordnungsgem&auml;&szlig;e Kassenf&uuml;hrung.</p>
                <p>Im Anschluss wurde der bisherige Vorstand von seinen Aufgaben entbunden und es kam zur Neuwahl des
                    Vorstandes. Im Wesentlichen wurde der Vorstand nahezu best&auml;tigt. F&uuml;r Dietmar
                    Walter &uuml;bernahm Corina
                    Hofmann die Aufgaben des Beirats. Matthias Hofmann &uuml;bernahm von Albert Vogl die Verantwortung
                    des
                    2. Kassierers. Ebenso wird Matthias die Aufgaben des Kartenbeauftragten von Stephan Walter
                    &uuml;bernehmen.</p>
                <p>Volker Kotzbauer, der in der Eigenschaft als zweiter B&uuml;rgermeister die Gr&uuml;&szlig;e des
                    B&uuml;rgermeisters
                    Christian Graf &uuml;berbrachte, berichtete u.a. davon, wie sehr die Gemeinde es sch&auml;tzt, dass
                    das
                    Vereinsleben in Hohenfels so aktiv ist. Besonders hob er hervor, das in diesem Jahr stattgefundene
                    Starkbierfest.</p>
            </article>
            <hr/>
            <article className="articleContent">
                <h4>01.04.2024 - Einladung zur Jahreshauptversammlung mit Neuwahlen</h4>
                <div><p>Am 20.04.2024 sind unsere Jahreshauptversammlung mit Neuwahl des Vorstandes.</p>
                    <p>Am Samstag, den 20.04.2024 findet zudem um 18:00 Uhr in der Hohenfelser Pfarrkirche
                        St. Ulrich ein Gottesdienst f&uuml;r die verstorbenen Vereinsmitglieder statt, so dass wir uns
                        17:45
                        Uhr
                        vor der Kirche alle treffen k&ouml;nnen.</p>
                    <p>Anschlie&szlig;end findet die Jahreshauptversammlung mit Neuwahl des Vorstandes im Sportheim am
                        Sportplatz
                        Haarziegelh&uuml;tte statt. Bis 20:00 Uhr kann jeder seine neue Vereinskleidung abholen (sofern
                        er/sie welche bestellt hat).</p>
                </div>
                <div style={{textAlign: "center"}}>
                    <p style={{fontWeight: "bold", textDecoration: "underline"}}>Tagesordnung</p>
                    <ol style={{margin: 0, padding: 0, textAlign: "left", marginLeft: "35%"}}>
                        <li>Begr&uuml;&szlig;ung durch den 1. Vorstand</li>
                        <li>Jahresbericht des 1.Vorstandes</li>
                        <li>Bericht 1. Kassierer</li>
                        <li>Bericht der Kassenpr&uuml;fer</li>
                        <li>Entlastung der Vorstandschaft</li>
                        <li>Neuwahlen</li>
                        <li>Ehrungen</li>
                        <li>Jahresausblick 2024</li>
                        <li>W&uuml;nsche und Antr&auml;ge</li>
                    </ol>
                </div>
                <div>
                    <p>Auf Dein Kommen freut sich die Vorstandschaft des</p>
                    <p> FC Bayern-Fanclubs Forellenbachtal Hohenfels</p></div>
            </article>
            <hr/>
            <article className="articleContent">
                <h4>07.03.2024 - Bericht vom Starkbierfest</h4>
                <p>Am 02.03.2024 veranstalteten wir im Keltensaal in Hohenfels ein Starkbierfest.</p>
                <p>All denen, die nicht dabei waren sein schon gesagt - es war ein toller Abend.</p>
                <p>Da wir es dieses Jahr nicht geschafft hatten, mit einem eigenen Faschingsball dabei zu sein, hatten
                    wir uns dazu entschlossen, ein Starkbierfest zu veranstalten. Es brauchte dabei nicht noch ein
                    Singspiel, wie auf dem Nockherberg - mithalten konnten wir allemal.</p>
                <p>Gut vorbereitet konnte an diesem Abend nicht mehr viel schief gehen. P&uuml;nktlich um 19:00 Uhr
                    &ouml;ffneten die T&uuml;ren vom Keltensaal. 5 EUR Eintritt und schon gab es ein rotes
                    Eingangsb&auml;ndchen am
                    Einlass. Der Saal war traditionell bayrisch ausgeschm&uuml;ckt und links am Eingang war extra eine
                    kleine
                    Bar aufgebaut worden.</p>
                <p>F&uuml;r die musikalische Begleitung stand Florian Hengl aus Maxh&uuml;tte-Haidhof bereit. Und der
                    Florian hat
                    in der Tat f&uuml;r gute Stimmung gesorgt - es muss nicht immer eine laute Band sein.</p>
                <p>Unmittelbar vor der B&uuml;hne stand das 1. Fass'l Starkbier, das zugleich nach dem Anzapfen als
                    Freibier
                    verteilt wurde. Unser B&uuml;rgermeister Christian Graf gab dann unserem Vorstandsvorsitzenden ein
                    paar
                    Ratschl&auml;ge, wie das mit dem Anzapfen dann wohl funktioniert. Am Ende hat's der
                    B&uuml;rgermeister selbst
                    in die Hand genommen. Den kr&auml;ftigen Ausruf: <i><b>O'zapft is!</b></i> muss der
                    B&uuml;rgermeister aber
                    noch &uuml;ben.</p>
                <p>Nachdem das erste Starkbier floss, wurde ein Teil f&uuml;r die Bodenpflege geopfert und der Rest dann
                    z&uuml;gig an die anwesenden durstenden G&auml;ste verteilt. Und manch einer dachte sich, "so viel
                    hab ich
                    doch noch gar nicht getrunken", als er zum beherzten lang ersehnten kr&auml;ftigen Schluck Starkbier
                    ansetzte und irgendwie doch alles nach Cola schmeckte. Da das Starkbier dunkel und mit wenig Schaum
                    daher kam, flog der Schwindel auch nicht gleich auf. Der Lukas hat es aber mit viel Humor genommen,
                    gleichwohl man ihm die Selbstzweifel vom Gesicht ablesen konnte.</p>
                <ImageViewer path="./events/images/2024_03_02_starkbierfest/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild1.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild2.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild3.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild4.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild19.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild24.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild27.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild28.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild32.jpg"
                    }]}/>
                <p>Am Ausschank sorgten dann Bernhard Spangler und Mathias Hofmann daf&uuml;r, dass die restlichen
                    F&auml;sser
                    fachgerecht entleert bzw. in Gl&auml;ser umgef&uuml;llt wurden. Andy Graf richtete zusammen mit
                    Steffi im
                    Hintergrund die zahlreichen Brotzeitteller der Metzgerei Bogner her. Dass Getr&auml;nke und Essen
                    Ihre
                    Abnehmer fanden, ist der Unterst&uuml;tzung von Manu Sch&auml;tzl und Christian Seitz zu verdanken.
                    Getr&auml;nke
                    und Essen wurden
                    dabei zu sehr moderaten Preisen angeboten. Die Bar wurde dann bis in den sp&auml;ten Abend von
                    Christina
                    Hofmann, Theresa La&szlig;leben und Stefan Spangler organisiert.</p>
                <p>Dann war es endlich gegen 21:00 Uhr soweit, dass "Bruder J&uuml;rgen" und "Bruder Lukas" ihre
                    Fastenpredigt zum Besten gaben.</p>
                <p>So dankte man dem "Amerikaner", ist er doch neben der E.T.A. der gr&ouml;&szlig;te Arbeitgeber am
                    Ort, den
                    niemand wirklich missen m&ouml;chte. Nicht ganz ungeschoren kam unser neuer Pfarrer davon, der
                    selbst
                    leider nicht beim Starkbierfest dabei sein konnte. Aber die Hohenfelser machten keinen Hehl draus,
                    dass sie ihren neuen Pfarrer l&auml;ngst in ihr Herz geschlossen haben und ihn auch nicht mehr
                    hergeben
                    m&ouml;chten, funktioniert doch die Arbeit mit dem Pfarrgemeinderat und der Kirchenverwaltung so
                    gut.</p>
                <p>Der B&uuml;rgermeister war hingegen selbst anwesend und kam bei der Fastenpredigt auch ganz gut weg:
                    so freute man sich, dass er jetzt wieder runderneuert ist und voller Elan seiner Arbeit nachgehen
                    kann. Auch nicht vergessen, die Versch&ouml;nerung seines Autos, oder die
                    R&uuml;ckw&auml;rts-Einpark-Aktion, der
                    eine Stra&szlig;enlampe zum Opfer fiel. Auch, dass Simon Koller nach h&ouml;heren Aufgaben strebt,
                    kam zur
                    Sprache.
                    Es wird die Zeit kommen, wo auch er (der Simon) &uuml;ber sich hinauswachsen kann - doch bis dahin
                    wird
                    er sich mit seinem B&uuml;rgermeister noch arrangieren m&uuml;ssen.</p>
                <p>Der Burschenverein h&auml;lt sich wacker im "Horziegl". Hin und wieder schauen die Burschen dann zu
                    tief
                    ins Glas, so dass eine Schl&uuml;sselsuche schon mal ein Problem sein kann. Mit etwas
                    Handwerksgeschick
                    l&auml;sst sich jede T&uuml;r &ouml;ffnen - egal wie.</p>
                <p>Am Ende haben unsere Br&uuml;der in ihrer Fastenpredigt f&uuml;r jeden etwas dabeigehabt. Und wer den
                    Lukas
                    und den Stumpf kennt: es h&auml;tte keiner besser vortragen k&ouml;nnen.</p>
                <p>Der Florian Hengl behauptete sich als Alleinunterhalter und stellte sein vielseitiges Talent unter
                    Beweis. Er sorgte nicht nur f&uuml;r Stimmung mit dem Akkordeon, sondern spielte so manch tanzbaren
                    Titel, was viele G&auml;ste auf die Tanzfl&auml;che zog. </p>
                <p>Nachdem um 02:45 Uhr dann auch die letzten G&auml;ste gegangen waren, sperrte unser
                    Vorstandsvorsitzende
                    Thomas Stadlmeier den Keltensaal zu. Alles in allem war es ein gelungener Abend.</p>


                <ImageViewer path="./events/images/2024_03_02_starkbierfest/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild55.jpg"
                    }]}/>
                <p>Der Vorstand bedankt sich nochmals bei allen Beteiligten und Helfern, die ma&szlig;geblich zum guten
                    Gelingen an diesem Abend beigetragen haben.</p>

                <p>Weitere Bilder findet ihr in der Galerie.</p>

            </article>
            <hr/>
            <article className="articleContent">
                <h4>05.02.2024 - Termin zum Starkbierfest steht</h4>
                <p>Am 02.03.2024 veranstalten wir im Keltensaal in Hohenfels ein Starkbierfest. Merkt euch also diesen
                    Termin schon mal vor.</p>
                <ImageViewer path="./images/termine/2024/" items={[
                    {
                        name: "",
                        description: "",
                        src: "FlyerStarkbierfest_2024.jpg"
                    }]}/>
            </article>
            <hr/>
            <article className="articleContent trauerContent">
                <h4 style={{color: "#000"}}>21.01.2024 - Abschied von unserem Gr&uuml;ndungsmitglied Heinz
                    S&ouml;llner</h4>
                <p>In gro&szlig;er Trauer nehmen wir Abschied von unserem Gr&uuml;ndungsmitglied Mitglied Heinz
                    S&ouml;llner. Heinz war von der ersten Stunde dabei und viele Male den Bus ins Stadion gefahren.
                    Danke, Heinz, f&uuml;r deine Verdienste im Fanclub - Danke, dass du mit dabei warst.
                    Ruhe in Frieden.</p>
                <p>Am 25.01.2024 findet in Hohenfels um 17:45 Uhr die Aussegnung und anschlie&szlig;endem Rosenkranz
                    statt.
                    Der Trauergottesdienst mit anschlie&szlig;ender Urnenbeisetzung ist am 03.02.2024 um 10:00 Uhr in
                    Hohenfels.</p>
                <p>Unsere Anteilnahme gilt auch insbesondere der Familie und den Hinterbliebenen von Heinz
                    S&ouml;llner.</p>
            </article>
            <hr/>
            <article className="articleContent trauerContent">

                <h4 style={{color: "#000"}}>07.01.2024 - Abschied von Franz Beckenbauer</h4>

                <p>Wir vom FCB-Fanclub Forellenbachtal Hohenfels nehmen Abschied von Franz Beckenbauer </p>
                <p>
                    Wir vom Fanclub sagen Danke Franz, f&uuml;r alles, was du f&uuml;r den FC Bayern M&uuml;nchen und
                    den gesamten
                    deutschen Fu&szlig;ball geleistet hast.
                    Wir werden dich nie vergessen und immer in unseren Herzen tragen. Ruhe in Frieden.
                </p>
                <p>Unsere Anteilnahme gilt auch insbesondere der Familie und den Hinterbliebenen von Franz
                    Beckenbauer.</p>
                {(new Date() < new Date("2024-01-31")) &&
                    <p> Der FC Bayern M&uuml;nchen hat bis zum 31.01.2024 ein <a
                        href="https://fcbayern.com/de/news/2024/01/digitales-kondolenzbuch-des-fc-bayern-fuer-franz-beckenbauer"
                        target="_blank">digitales Kondolenzbuch</a> eingerichtet.</p>}
            </article>
            <hr/>
            <article className="articleContent">
                <h4>26.11.2023 - Impressionen von der Weihnachtsfeier im Gasthaus Zur Taverne in Hohenfels </h4>
                <p>Der FC Bayern Fanclub Forellenbachtal Hohenfels traf sich am 25.11.2023 um 18:30 Uhr im Gasthaus
                    Zur Taverne zu seiner allj&auml;hrlichen Weihnachtsfeier.</p>
                <p>&Uuml;ber 6o Mitglieder sind der Einladung gefolgt und machten den Abend mit Ihrer Anwesenheit zu
                    einem
                    sch&ouml;nen Beisammensein.</p>
                <p>Jedes der Mitglieder hatte einen Platz im Gasthaus gefunden und es gab viele interessante
                    Gespr&auml;che.</p>
                <p>Einige der anwesenden Mitglieder nutzten auch die Gelegenheit, um sich den Anmeldeprozess an der
                    Fanclub-Webseite noch einmal erkl&auml;ren zu lassen.</p>
                <p>Das Gasthaus Zur Taverne stellte die Versorgung, nicht nur mit Getr&auml;nken sicher, sondern sorgte
                    auch
                    daf&uuml;r, dass niemand mit leeren Magen wieder heimgehen musste. Es gab Schweine- und Kalbsbraten,
                    Kl&ouml;&szlig;e, Sp&auml;tzle und Salat. </p>
                <p>Unser Vorstandsvorsitzender Thomas Stadlmeier bedankte sich bei allen anwesenden f&uuml;r das
                    zahlreiche
                    Erscheinen und insbesondere bei seinem Vorstand f&uuml;r die tatkr&auml;ftige Unterst&uuml;tzung
                    unterm Jahr.
                    Zus&auml;tzlich gab Thomas noch einen Ausblick auf das anstehende Starkbierfest im n&auml;chsten
                    Jahr und
                    erinnerte alle noch an den anstehenden Hohenfelser Weihnachtsmarkt, wo der FC Bayern Fanclub mit
                    einem eigenen Stand vertreten sein und Bratw&uuml;rste vom Grill und Getr&auml;nke anbieten
                    wird.</p>
                <p>Nach dem Essen, welches sehr lecker und reichhaltig war, richtete sich die Aufmerksamkeit auf
                    die Weihnachtstombola, war doch jeder Neugierig, was dieses Jahr an &Uuml;berraschungen dabei sein
                    wird.</p>
                <p>Thomas Mulzer und Lukas Ring sorgten f&uuml;r eine rege Verteilung der Tombola-Lose unter den
                    anwesenden
                    G&auml;sten.</p>
                <p>Erwartungsgem&auml;&szlig; hatten unsere j&uuml;ngsten Mitglieder sichtlich den meisten
                    Spa&szlig; dabei. Auch gab es
                    einige recht kuriose Loskombinationen, die gezogen wurden. So zog Bernhard Schr&ouml;der die
                    Losnummer 1
                    und die Losnummer 100 - welch lustiger Zufall.</p>
                <p>Der Vorstand bedankt sich auf diesem Wege auch noch einmal bei allen Sponsoren, die das eine oder
                    andere Pr&auml;sent mit beigesteuert haben.</p>
                <p>Um 0:30Uhr sind dann auch die letzten aufgebrochen und so ging ein wundersch&ouml;ner Abend mit
                    Freunden
                    zu Ende. Insbesondere vor dem Hintergrund der zur&uuml;ckliegenden Coronabeschr&auml;nkungen war
                    jeder
                    begeistert, dass solche Begegnungen wieder im Alltag dauerhaft zur&uuml;ck gekehrt sind.</p>
                <p>Der Vorstand bedankt sich bei allen Helfern und Beteiligten des Abends, die dies &uuml;berhaupt
                    erm&ouml;glicht
                    haben und w&uuml;nscht allen eine sch&ouml;ne Zeit mit Blick auf die anstehende Weihnachtszeit und
                    dem
                    bevorstehenden Jahreswechsel.</p>
                <ImageViewer path="./events/images/2023_11_25_Weihnachtsfeier/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild1.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild2.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild3.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild4.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild5.jpg"
                    }
                ]}/>
                <p>Weitere Bilder von der Weihnachtsfeier findet ihr in unserer Bilder Galerie (Hauptmen&uuml; -
                    Bilder)</p>
            </article>
            <hr/>
            <article className="articleContent">
                <h4>25.11.2023 - Gr&uuml;ndungsmitglied Peter Reindl feiert seinen 60. Geburtstag </h4>
                <p>Am Tag der gemeinsamen Weihnachtsfeier feierte auch unser Gr&uuml;ndungsmitglied Peter Reindl seinen
                    60.
                    Geburtstag. F&uuml;r uns war klar, dass der Peter nicht auf zwei Veranstaltungen gleichzeitig sein
                    konnte.</p>
                <p>Der gesamte Fanclub w&uuml;nscht Peter alles Gute zu seinem 60. Jubil&auml;um.</p>
                <p>Dietmar Walter und René Rendant &uuml;berreichten Peter eine kleine Aufmerksamtkeit im Namen des
                    Vorstands
                    des FC Bayern Fanclubs Forellenbachtal Hohenfels.</p>
                <ImageViewer path="./events/images/2023_11_25_ReindlPeter60/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild1.jpg"
                    }]}/>
            </article>
            <hr/>
            <article className="articleContent">
                <h4>25.11.2023 - Weihnachtsfeier im Gasthaus Zur Taverne in Hohenfels </h4>
                <p>Der FC Bayern Fanclub Forellenbachtal Hohenfels trifft sich heute um 18:30 Uhr im Gasthaus
                    Zur Taverne zu seiner diesj&auml;hrigen Weihnachtsfeier.</p>
                <p>Im Rahmen der Weihnachtsfeier wird es neben dem gem&uuml;tlichen Beisammensein, dem leckeren
                    Essen auch wieder die beliebte Tombola geben.</p>
                <p>Der Vorstand w&uuml;nscht allen Mitgliedern einen einen sch&ouml;nen gemeinsamen Abend.</p>

            </article>
            <hr/>
            <article className="articleContent">
                <h4>02.09.2023 - Sommergrillfest des FC Bayern Fanclub</h4>
                <p>Endlich war es wieder mal soweit, dass wir unser Sommergrillen veranstalten konnten. Im Sportheim des
                    TSV Hohenfels an der Haarziegelh&uuml;tte trafen sich heute gut 60 Mitglieder mit ihren Partnern und
                    Kindern zum Sommergrillfest des FC Bayern Fanclubs. Einige der G&auml;ste brachten eigens f&uuml;r
                    das
                    Sommergrillen angerichtete Salate mit.</p>
                <p>Neben dem leckeren Essen, hatten die Anwesenden vor allem viel Freude beim gemeinsamen Schauen der
                    Ausw&auml;rtsbegegnung der FC Bayern M&uuml;nchen in M&ouml;nchengladbach, welches nach packenden 97
                    Minuten mit
                    einem 2:1 Sieg f&uuml;r den FC Bayern M&uuml;nchen ausging.</p>
                <ImageViewer maxWidth={800} path="./events/images/2023_09_02_Grillfest_2023/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild44.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild12.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild6.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild9.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild8.JPG"
                    },

                    {
                        name: "",
                        description: "",
                        src: "Bild28.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild37.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild31.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild40.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild41.JPG"
                    }
                ]}/>
                <p>Aus dem Tipspiel, wo 55 Tips abgegeben wurden, gingen dann 8 gl&uuml;ckliche Gewinner hervor.
                    Erstaunlicherweise gab es aber auch 2 Freunde, die unserer Mannschaft heute keinen Sieg
                    zutrauten.</p>
                <p>Die Kleinsten in der Runde hatten auch sichtlich ihren Spa&szlig; heute und es war sehr sch&ouml;n,
                    dass so
                    viele Kinder mitgekommen sind. So konnte der wohl J&uuml;ngste in der Runde schon mal mit der Oma am
                    Kicker &uuml;ben oder mit seinen kleinen Freunden seinen Berufswunsch als k&uuml;nftiger
                    Baggerfahrer
                    erkunden.</p>
                <p>Noch mehr Bilder findet ihr in der Bildergalerie.</p>
                <p>Der Vorstand des FC Bayern Fanclub bedankt sich bei allen, die gekommen waren und so, mit dazu
                    beigetragen haben, dass es heute ein wunderbarer Abend wurde.</p>
            </article>
            <hr/>
            <article className="articleContent">
                <h4>22.07.2023 - 300 Jahre St.Ulrich Hohenfels - <i>Lichtermesse</i> der besonderen Art</h4>
                <p>
                    Nachdem am Abend die Kindergartenkinder ihren pers&ouml;nlichen Abschlussgottesdienst feierten und
                    dabei
                    gezeigt haben, was sie alles schon k&ouml;nnen (sie haben die komplette musikalische Gestaltung des
                    Gottesdienstes mehr oder weniger sichergestellt), lud der Pfarrer Markus Lettner noch einmal alle
                    Anwesenden zur <i>Lichtermesse</i> ein.
                </p>
                <p>
                    P&uuml;nktlich um 21.30 Uhr pr&auml;sentierte sich die Kirche in Kerzenschein geh&uuml;llt in einem
                    v&ouml;llig anderem
                    Erscheinungsbild.
                    Pfarrer Markus Lettner hat mit Unterst&uuml;tzung guter Freunde ein Lichterspiel der besonderen Art
                    auf
                    die Beine gestellt. Dabei wurden immer einzelne Elemente, wie Decken- und Wandmalereien, die alt
                    ehrw&uuml;rdige
                    Kanzel, das Taufbecken oder auch das Jesus-Kreuz abwechselnd in ein besonderes Licht gestellt.
                </p>
                <p>Mit jedem Spotwechsel lie&szlig; es sich der Pfarrer Markus Lettner nicht nehmen, etwas zur
                    Entstehungs-
                    und/oder Kirchengeschichte vorzutragen. Daneben wurde der Abend von Musik im Gesang durch den
                    "&Auml;gidi-Dreigesang" aus Hainsacker und an der Orgel begleitet.</p>
                <p>Nach gut einer Stunde der doch ganz anderen Messe, lud der Pfarrer die Anwesenden noch auf ein
                    pers&ouml;nliches Gespr&auml;ch mit einem guten Glas Wein in den angrenzenden Pfarrgarten ein. </p>
                <p>Wie sehr alle Besucher dieser Abend gefallen hatte, konnte man beim Verlassen der Kirche am
                    Spendenkorb ablesen, waren doch weitgehend nur Scheine im K&ouml;rbchen zu entdecken.</p>
                <ImageViewer path="./events/images/2023_07_22_300Jahre_StUlrich/Lichtermesse/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild1.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild2.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild3.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild4.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild5.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild6.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild7.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild8.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild9.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild10.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild11.jpg"
                    }]}/>
                <p>Hohenfels kann sich sehr gl&uuml;cklich sch&auml;tzen mit ihrem Pfarrer Markus Lettner.</p>
            </article>
            <hr/>
            <article className="articleContent">
                <h4>22.07.2023 - Isabella und Tobias Gassner gaben sich das JA Wort vor Gott</h4>
                <p>
                    Am 22.07.2023 fand f&uuml;r Isabella und Tobias Gassner die kirchliche Trauung in Rechberg statt.
                    Selbstverst&auml;ndlich
                    waren wir auch wieder vom Fanclub vor Ort, um die besten W&uuml;nsche aller Fanclubmitglieder zu
                    &uuml;berbringen.
                    <ImageViewer path="./events/images/Hochzeit/20230722/" items={[
                        {
                            name: "",
                            description: "Das Brautpaar mit Mitgliedern des Fanclub-Vorstands",
                            src: "Bild1.jpg"
                        }]}/>

                </p>
            </article>
            <hr/>
            <article className="articleContent">
                <h4>27.05.2023 - Deutscher Fu&szlig;ballmeister 2023 - FC Bayern M&uuml;nchen</h4>
                <p>Eine verr&uuml;ckte Fu&szlig;ballsaison geht zu Ende und wieder einmal ist unser FC Bayern
                    M&uuml;nchen
                    Deutscher
                    Fu&szlig;ballmeister geworden.</p>
                <p>Der Rekordmeister verbucht damit seine 33. Deutsche Meisterschaft und seine 11. Deutsche
                    Meisterschaft in Folge.</p>

                <p>Und nicht zu vergessen, auch die Frauenmannschaft des FC Bayern M&uuml;nchen holte sich in Potsdam
                    den
                    f&uuml;nften Meistertitel im Frauenfu&szlig;ball.</p>
                <p>Wir vom FC Bayern Fanclub Forellenbachtal Hohenfels gratulieren recht herzlich der Mannschaft
                    (Frauen/Herren) des FC
                    Bayern M&uuml;nchen zum Gewinn der Deutschen Fu&szlig;ballmeisterschaft.</p>
                <p>Doch lassen wir mal die Saison 2022/23 der Herrenmannschaft Revue passieren. Man muss zugestehen, es
                    lief wahrlich nicht immer alles glatt dieses Jahr. Am wenigsten hatte man verstanden, warum Julian
                    Nagelsmann seinen Platz r&auml;umen musste. Es hatte den Anschein, dass der Trainer gehen musste,
                    um &uuml;ber
                    eigene Schw&auml;chen in der F&uuml;hrungsetage hinwegzut&auml;uschen.</p>
                <p>Andererseits war es doch seit &uuml;ber 10 Jahren mal wieder ein richtiges Herzschlag-Saison-Finale:
                    wer
                    Deutscher Fu&szlig;ballmeister wird, entscheidet sich am letzten Spieltag. Und dabei sah der BVB
                    Dortmund
                    schon wie der sichere Sieger aus. Keiner h&auml;tte es f&uuml;r m&ouml;glich gehalten, dass die
                    Spieler des
                    BVB
                    Dortmund so das flattern bekommen. Und eines ist auch eine altbekannte Weisheit: untersch&auml;tze
                    nie
                    deinen Gegner (FSV Mainz). Es war schon sch&ouml;n mit anzusehen, wie der FSV Mainz aufgespielt
                    hatte,
                    ging es doch f&uuml;r die Mannschaft mehr um die goldene Ananas, als um das Erreichen einer
                    irgendwie
                    relevanten Platzierung. </p>
                <p>Und der 1.FC K&ouml;ln, die ja bekannterma&szlig;en so dicke sein sollen mit dem BVB Dortmund, hatte
                    irgendwie
                    auch nicht so die rechte Lust am Spiel. Auf jeden Fall war es ein packendes und spannendes
                    Saisonfinale, in dem der FC Bayern als Sieger hervorging.</p>
                <p>Interessant ist dann auch der Blick auf die Schlusstabelle: Mit 71 Punkten und 15 Toren mehr ist der
                    FC Bayern M&uuml;nchen zum 11. mal in Folge Deutscher Fu&szlig;ballmeister vor Dauerrivalen Borussia
                    Dortmund
                    geworden. Schmerzhaft f&uuml;r die Borussen, haben sie doch ebenfalls 71 Punkte auf dem Konto.</p>
                <p>Da stellt sich doch die Frage: wie oft hatten wir eigentlich in der Bundesliga die Situation, dass
                    allein das bessere Torverh&auml;ltnis &uuml;ber die Meisterschaft entschieden hatte: bisher 6 Mal
                    und
                    seit dem
                    27.05.2023 nun 7 Mal. Beide Mannschaften waren schon einmal in der Situation, BVB Dortmund zu Letzt
                    1991/92 und der FC Bayern M&uuml;nchen zu Letzt 1999/2000.</p>
                <p>Bei allem, was diese Saison schief gelaufen ist, haben unsere Jungs es doch noch geschafft, die 11.
                    Deutsche Fu&szlig;ballmeisterschaft in Folge einzufahren. Auch wenn einige meinen: <i>ja, die Bayern
                        schon
                        wieder. Wird denn auch noch jemand anderes mal wieder Deutscher Meister...?</i>, denen sein nur
                    gesagt: Deutscher Fu&szlig;ballmeister kann jeder werden, jede Mannschaft hat in jeder Saison 34
                    Spieltage
                    dazu Zeit. Bleibt abzuwarten, wer es in der n&auml;chsten Saison wieder mit unserem FC Bayern
                    M&uuml;nchen aufnehmen will.</p>
                <p>Drum schlie&szlig;e ich heute mit einem alt bekannten Slogan aus dem Stadion: <b><i>Deutscher Meister
                    wird nur der FCB, nur der FCB, nur der FCB...</i></b></p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>22.05.2023 - Gl&uuml;ckw&uuml;nsche zur Hochzeit </h4>
                <p>Der FC Bayern Fanclub Hohenfels gratuliert dem Brautpaar Veronika Gro&szlig;er und Rainer Birnthaler
                    zur
                    kirchlichen Trauung und w&uuml;nschen ihnen auf ihrem gemeinsamen Lebensweg von Herzen alles
                    Gute.</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>27.05.2023 - Elfmeterschie&szlig;en beim DJK-SV-Oberpfraundorf</h4>
                <p>Der DJK-SV-Oberpfraundorf hatte am Samstag ein Elfmeterschie&szlig;en veranstaltet und benachbarte
                    Vereine
                    eingeladen. Andreas Graf, 2. Vorstand informierte hierzu in der letzten Vorstandsschaftssitzung und
                    der Vorstand hat beschlossen, dass wir vom Fanclub daran teilnehmen werden und Adreas Graf sich um
                    die Aufstellung der "Mannschaft" k&uuml;mmert. </p>
                <p>Am Ende haben Andreas Graf und Marie Graf sowie 4 potentielle Neumitglieder den Fanclub
                    vertreten.</p>
                <p>Leider hat es nicht gereicht, so dass wir fr&uuml;hzeitig ausgeschieden sind. Aber dabei sein ist
                    alles.</p>
                <ImageViewer path="./events/images/2023_05_27_DJK_SV/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild1.jpeg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild2.jpeg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild3.jpeg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild4.jpeg"
                    }]}/>

            </article>
            <hr/>

            <article className="articleContent">
                <h4>22.05.2023 - Vorstand hat sich wieder getroffen</h4>
                <p>Der Vorstand des FC Bayern Fanclub Hohenfels hat sich im Sportheim Haarziegelh&uuml;tte getroffen und
                    die
                    anstehenden n&auml;chsten Termine besprochen und abgestimmt.</p>
                <p>So werden wir am 27.05.2023 bei der kirchlichen Trauung von Veronika Birnthaler Spalier stehen.</p>
                <p>F&uuml;r den gleichen Tag wird Andreas Graf eine Mannschaft aufstellen, die den Fanclub w&uuml;rdig
                    beim
                    Elfmeterschie&szlig;en in Oberpfraundorf vertritt, denn hier wurden wir vom DJK-SV Oberpfraundorf
                    herzlich eingeladen.</p>
                <p>Am 07.06.2023 werden zwei Vertreter des Vorstandes an der Geburtstagsfeier unseres Mitgliedes und
                    B&uuml;rgermeisters Christan Graf teilnehmen und den FC Bayern Fanclub Hohenfels vertreten.</p>
                <p>Das n&auml;chst gr&ouml;&szlig;ere Highlight dieses Jahres wird die Teilnahme des Fanclubs beim
                    B&uuml;rgerfest am
                    08./09.07.2023 sein. Hier werden wir mit W&uuml;rstchengulasch und Dosenwerfen f&uuml;r die Kleinen
                    pr&auml;sent
                    sein. Freiwillige Helfer k&ouml;nnen sich gerne beim Thomas Stadlmeier melden.</p>
                <article>
                    <div className="dekstopAntrag">
                        <PDFViewer source={config.api.pdfPath() + "Buergerfest_2023_Markt_Hohenfels_Flyer_A5.pdf"}/>
                    </div>
                    <div className="mobileAntrag">
                        <a href={config.api.pdfPath() + "Buergerfest_2023_Markt_Hohenfels_Flyer_A5.pdf"}
                           target="_blank">Download
                            Flyer B&uuml;rgerfest Markt Hohenfels 2023</a>
                    </div>
                </article>
                <p>Abschlie&szlig;end berichtete unser erster Schriftf&uuml;hrer Thomas Mulzer &uuml;ber den Stand der
                    Bestellung der
                    neuen Vereinskleidung.</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>21.04.2023 - Jahreshauptversammlung des FC Bayern Fanclub Forellenbachtal</h4>
                <p>Am 21.04.2023 trafen sich die Mitglieder des Fanclubs im Sportheim in Hohenfels zu ihrer
                    allj&auml;hrlichen Jahreshauptversammlung.</p>
                <p>Nach den Begr&uuml;&szlig;ung durch den Vorstandsvorsitzenden Thomas Stadlmeier gedachten die
                    anwesenden
                    Mitglieder in einer Schweigeminute den verstorbenen Mitgliedern.</p>
                <p>Im Anschluss legte der Vorstandsvorsitzende in seinem Jahresbericht Rechenschaft &uuml;ber das
                    vergangene
                    Jahr ab. So konnte zur&uuml;ckgeblickt werden auf einen Kappenabend, einer Weihnachtsfeier, einem
                    Ehrenabend, einem Stand am Weihnachtsmarkt und der Besuch von 3 Fu&szlig;ballspielen des FC Bayern
                    M&uuml;nchen.</p>
                <p>Besonders erfreulich war, dass neben 2 Austritten der Fanclub 8 neue Mitglieder begr&uuml;&szlig;en
                    durfte.</p>
                <p>Im Anschluss gab der Kassenwart Lukas Ring seinen Bericht, der einmal mehr deutlich machte, dass der
                    Verein wieder sehr gut gewirtschaftet hatte. Die Kassenpr&uuml;fer best&auml;tigten die
                    Ausf&uuml;hrungen des
                    Kassenwartes.</p>
                <p>Somit konnte der aktuelle Vorstand entlastet werden.</p>
                <p>Weiter gab Thomas Stadlmeier einen Ausblick auf die n&auml;chsten anstehenden Termine.</p>
                <p>Im gem&uuml;tlichen Beisammensein lie&szlig;en die anwesenden Mitglieder die Jahreshauptversammlung
                    dann
                    ausklingen.</p>
                <ImageViewer path="./events/images/2023_04_21_JHV2023/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild1.jpeg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild2.jpeg"
                    }]}/>

            </article>

            <article className="articleContent">
                <h4>DFB Pokal Spiel FC Bayern M&uuml;nchen ./. SC Freiburg</h4>
                <p>Am 04.04.2023 war es mal wieder soweit und unser Fanclub fuhr mit einem voll besetzten Bus nach
                    M&uuml;nchen
                    in die Allianz Arena zum DFB Pokal Spiel des FC Bayern M&uuml;nchen gegen den SC Freiburg.</p>
                <p>Fangen wir mal dem Positiven der Fahrt an: es gab mal wieder eine leckere Leberk&auml;s-Semmel. Die
                    hat
                    richtig gut gemundet, als wir p&uuml;nktlich am Nordparkplatz des Stadions angekommen waren. Der
                    weitere
                    positive Aspekt der Fahrt war, dass der &Auml;rger &uuml;ber das verlorene Spiel am Ende nicht so
                    gro&szlig; war,
                    als wenn man das Drama daheim am Bildschirm erlebt h&auml;tte.</p>
                <p>An dieser Stelle kann man nur die Worte von Joshua Kimmich wiederholen: <i>es kotzt mich brutal
                    an!</i> Der FC Bayern verspielt aktuell bald Titel um Titel. Wenn die Jungs so weiter spielen, dann
                    wird es
                    weder mit dem Meistertitel noch mit der Champions League klappen. Und eine Weisheit ist auch klar:
                    auf dem Platz
                    spielen 11 Spieler und der Trainer steht am Rand. Der Trainer hat bestenfalls eine Idee, vielleicht
                    auch eine super gute Idee vom Spiel. Aber diese Idee m&uuml;ssen nun einmal 11 Spieler auf dem Platz
                    umsetzen. Mit der Entlassung von Julian Nagelsmann hat der FC Bayern sich jedenfalls keinen Gefallen
                    getan.</p>
                <p>Basti Schweinsteiger hatte es im Interview so formuliert: <i>das mia san mia scheint auf dem Platz
                    verloren gegangen zu sein</i> - vielleicht auch in der F&uuml;hrungsetage. </p>
                <p>Aber schauen wir nach vorn. Vielleicht lernen die Jungs dazu und zeigen wieder den bekannten Biss auf
                    dem Platz.</p>
                <p>Und um noch einmal auf Jushua Kimmich zur&uuml;ck zu kommen: in 8 Jahren 7 Trainerwechsel zu erleben
                    zeugt
                    nicht von Stabilit&auml;t und Kontinuit&auml;t.</p>
                <p>In diesem Sinne: auf geht's Jungs - k&auml;mpfen und siegen!</p>
                <ImageViewer path="./events/images/2023_04_04_DFB_POKAL/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild1.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild2.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild3.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild4.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild5.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild6.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild7.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild8.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild9.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild10.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild11.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild12.jpg"
                    }]}/>

            </article>
            <hr/>

            <article className="articleContent">
                <h4>Terminplanung 2023</h4>
                <p>Am 20.02.2023 kam die Vorstandschaft des FC Bayern Fanclub zusammen, um die Termine f&uuml;r das Jahr
                    2023
                    zu besprechen.</p>
                <p>So werden wir am <b>04.04.2023</b> zu der Begegnung im <i>DFB-Pokal zwischen dem FC Bayern
                    M&uuml;nchen
                    und dem SC
                    Freiburg</i> fahren. Am <b>21.04.2023</b> wird es unsere <i>Jahreshauptversammlung</i> geben und am
                    Wochenende des <b>08/09.07.2023</b> werden wir beim <i>B&uuml;rgerfest in Hohenfels</i> dabei
                    sein. <b>Im
                        Sommer</b> wollen wir mit
                    Interessenten eine gemeinsame Fahrt in die <i>Erlebniswelt des FC Bayern mit anschlie&szlig;ender
                        Stadionf&uuml;hrung</i> organisieren. Am <b>02.09.2023</b> ist
                    unser <i>Sommergrillen</i> geplant.
                </p>
                <p>Am <b>23.09.2023</b> werden wir wieder eine <i>Fahrt ins Blaue</i> veranstalten.</p>
                <p>Nachdem die erste Fahrt ins Blaue so viel positive R&uuml;ckmeldungen brachte, werden wir es
                    dieses Jahr wiederholen - nat&uuml;rlich mit einem anderen Ziel. Was es ist, wird nat&uuml;rlich
                    wieder nicht verraten - lasst euch &uuml;berraschen!</p>
                <p>Anmeldungen erfolgen hierzu &uuml;ber Juliane Spangler oder Thomas Mulzer bzw. einfach &uuml;ber das
                    Kontaktformular.</p>
                <p>Erwachsene sind mit 35 € und Kinder bis 14 Jahre mit 25 € dabei.</p>
                <p>Bitte beachtet folgenden Hinweis: wer sich anmeldet, muss bei sp&auml;terer Absage selbst f&uuml;r
                    Ersatz sorgen. Bei Mitgliedern wird der Betrag automatisch abgebucht, Nichtmitglieder m&uuml;ssen
                    entsprechend das Geld &uuml;berweisen.</p>
                <ImageViewer path="./images/termine/" items={[
                    {
                        name: "",
                        description: "Fahrt ins Blaue - 23.09.2023",
                        src: "Fahrt_ins_Blaue_20230923.jpg"
                    }]}/>
                <p><b>2024</b> wollen wir sehen, dass wir zusammen mit einem weiteren Verein
                    eine <i>Faschingsball</i> wieder
                    veranstalten.</p>
                <p>Wie ihr sehen k&ouml;nnt, haben wir uns ein paar Gedanken f&uuml;r dieses Jahr gemacht. Jetzt seid
                    ihr
                    dran,
                    dass ihr die Angebote auch nutzt - macht mit und lasst uns zusammen Spa&szlig; haben, denn auch bei
                    uns
                    gilt das Motto: <br/><b><i>mia san mia</i></b>!</p>
            </article>


            <article className="articleContent">
                <h4>Kappenabend am 11.02.2023 - 20:00 Uhr</h4>
                <p>Am 11.02.2023 l&auml;dt der FC Bayern Fanclub Forellenbachtal Hohenfels im Sportheim am Sportplatz
                    "Siedaf&uuml;r" zum Kappenabend ein. Beginn ist 20:00 Uhr - Ende: wenn der letzte geht...</p>
                <p>F&uuml;r das leibliche Wohl ist gesorgt. Musikalisch wird der Abend begleitet von Andreas Graf.</p>
                <p>Wir freuen uns auf euer Kommen.</p>
            </article>
            <hr/>
            <article className="articleContent">
                <h4>Frohe Festtage und ein gesundes Jahr 2023</h4>
                <p>2022 war ein Jahr voller &Uuml;berraschungen. Die Coronapandemie flachte weiter ab, die getroffenen
                    Ma&szlig;nahmen zur Kontaktbeschr&auml;nkung wurden zunehmend lockerer gestaltet. Zu unserer Freude
                    waren
                    Fahrten zu den Spielen in die Allianz-Arena wieder m&ouml;glich.</p>
                <p>Das traurigste Kapitel, das jedoch 2022 pr&auml;gte, war der Beginn des Krieges in der Ukraine am
                    24.02.2022. Es ist traurig, dass der Mensch nicht versteht, dass niemand das Recht hat Kriege zu
                    f&uuml;hren und Menschen zu t&ouml;ten - gleich um welche Anschauung oder um welche Anspr&uuml;che
                    es
                    dabei auch
                    geht. Die M&auml;chtigen dieser Welt sollten in der Lage sein, diesen Konflikt ein f&uuml;r alle
                    Male zu
                    beenden. Der zweite Weltkrieg hat schon genug Trauer und Elend &uuml;ber die Welt gebracht - das
                    braucht
                    die Welt nicht noch einmal.</p>
                <p>Ein weiteres trauriges Kapitel 2022 war auch der Tod der weltweit geachteten und geliebten Monarchin
                    - Queen Elisabeth II. am 08.09.2022. Mit Bedauern und tiefer Ersch&uuml;tterung hat die Welt zur
                    Kenntnis
                    nehmen m&uuml;ssen, dass die am l&auml;ngsten regierende Monarchin (70 Jahre und 214 Tage Amtszeit)
                    ihren
                    letzten Weg gegangen ist.</p>
                <p>Aber die Queen war nicht die einzige Pers&ouml;nlichkeit, die von uns gegangen ist. Am heutigen Tage
                    ist
                    auch der erste deutsche Papst, Benedikt XVI im Alter von 95 Jahren verstorben.</p>
                <p>Neben den vielen Pers&ouml;nlichkeiten aus Politik, Film und Wirtschaft, die dieses Jahr von uns
                    gegangen
                    sind, hat auch so mancher im Fanclub seinen ganz privaten Verlust zu verkraften gehabt.
                    An dieser Stelle m&ouml;chte ich allen Mitgliedern das tiefe Mitgef&uuml;hl auch im Namen des
                    gesamten
                    Vorstandes aussprechen, die dieses Jahr liebe Menschen in ihrem Umfeld verloren haben.</p>
                <p>Nach alledem hielt das Jahr aber auch noch weitere &Uuml;berraschungen bereit: Die Inflation stieg
                    auf
                    ein Rekordhoch, Sparer zahlten pl&ouml;tzlich Verwahrentgelte f&uuml;r ihre Spareinlagen, statt
                    Zinsen zu
                    bekommen. Die &Ouml;l- und Energiepreise stiegen auf ein Rekordhoch und trieben so manchen Haushalt
                    an
                    die Grenzen des Zumutbaren und Machbaren.</p>
                <p>Am Ende bleibt die Frage, was das kommende Jahr uns bringen wird. Hoffen wir das Beste und schauen
                    wir nach vorn. Freuen wir uns auf die 11. Meisterschaft unseres FC Bayern, hoffen wir, dass wir im
                    DFB Pokal nicht vorzeitig ausscheiden und den Pott 2023 holen und zu guter Letzt wir vielleicht auch
                    die Champions League mal wieder gewinnen.</p>
                <p>Zum Schluss noch ein Hinweis in eigener Sache: wie ihr alle auf der Weihnachtsfeier gesehen habt,
                    planen wir eine neue einheitliche Vereinskleidung. Hierzu haben wir Muster (Softshelljacke,
                    Poloshirt) der Firma Trigema bekommen, die ihr euch ansehen und/oder anprobieren k&ouml;nnt. Wir
                    werden
                    diese dann auch mit dem Vereinswappen/-logo und einem Schriftzug besticken lassen. Vereinbart hierzu
                    einfach einen Termin mit mir. Im Januar werden wir hierzu noch einmal ein E-Mail Aktion starten.</p>
                <ImageViewer path="./images/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Schaukasten_2022_12_31.jpg"
                    }]}/>
                <p>Ich w&uuml;nsche euch allen auch im Namen des gesamten Vorstandes alles Gute, dass all eure
                    W&uuml;nsche und
                    Hoffnungen in Erf&uuml;llung gehen und dass ihr vor allem alle gesund bleibt.</p>
                <p>Thomas Stadlmeier (1. Vorstand)</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>08.10.2022 - BVB Borussia Dortmund ./. FC Bayern M&uuml;nchen</h4>
                <p>Am Samstag den 08.10.2022 war wieder der Tag der Tage, die zwei Dinos und Dauerrivalen der
                    Bundesliga trafen aufeinander. Als Bayern-Fan freut man sich um so mehr, wenn es mit der
                    Ticketvergabe dann auch noch geklappt hat.</p>
                <p>Unsere Freunde vom Bayernfanclub RWC Dietfurt haben eine Busfahrt organisiert, so dass am Ende
                    Mitglieder vier verschiedener FC Bayern Fanclubs (RWC Dietfurt, Bayernfreunde Weinsfeld,
                    Bayernfanclub Hohenschambach und Bayernfanclub Hohenfels) gemeinsam
                    zum Spiel nach Dortmund fuhren.</p>
                <p>Die Fahrt nach Dortmund war sehr entspannt und unterhaltsam zugleich. In Dortmund angekommen, war es
                    ein kleines Unterfangen, den rechten Weg vom Parkplatz zum Stadion zu finden. Im Stadion selbst hat
                    sich
                    dann ein sch&ouml;nes Wiedersehen mit einem alten Arbeitskollegen (und nat&uuml;rlich BVB Fan)
                    ergeben.</p>
                <p>Die S&uuml;dkurve M&uuml;nchen hatte f&uuml;r den Spieltag eine Choreographie vorbereitet, die
                    zugleich auch
                    Ausdruck der starken Unterst&uuml;tzung aller mitgereisten Bayernfans war.</p>
                <p>Das Stadion selbst - wie sollte es auch anders bei einem solchen Topspiel auch sein - war pickepacke
                    voll und damit mit &uuml;ber 81.000 Zuschauern ausverkauft. Entt&auml;uschend war, dass trotz der
                    riesigen
                    Werbung mit 1&1 im Stadion selbst kein Netz zur Verf&uuml;gung stand. Auch das BVB WLan war nicht
                    nutzbar. Das hat schon ein wenig Steinzeitcharakter. Was ebenso sehr peinlich wirkte, war das
                    Umweltkonzept, das hier vom Stadion selbst verfolgt wird - n&auml;mlich, es gibt keines. Und so sah
                    es im
                    und um dem Stadion herum dann auch aus: &uuml;berall Abfall, wo man auch hinschaute.</p>
                <ReactPlayer
                    url='./events/images/2022_10_08_BVB_FCB/video/video1.mp4' controls={true}
                    width={"100%"}
                    loop={true}/>
                <p>Unsere Mannschaft zeigte nach Beginn der zweiten Halbzeit endlich, wer der Chef im Haus ist. Erst
                    0:1,
                    dann 0:2 und alles sah nach einem Ausw&auml;rtssieg aus. Die Bayern hatten mehr Ballbesitz, die
                    Passgenauigkeit war besser.</p>
                <p>Eines haben sie am Ende dann aber doch verpennt - n&auml;mlich den Sack rechtzeitig zuzumachen. Und
                    so
                    hatte man unn&ouml;tig den Gegner wieder in's Spiel kommen lassen, mit dem Ergebnis, dass sich beide
                    Mannschaften mit einem f&uuml;r den FC Bayern eher schmeichelhaften 2:2 zu Ende ging.</p>
                <p>Der Fahrt nach Dortmund und der R&uuml;ckfahrt hatte es dennoch nicht geschadet, die Stimmung im Bus
                    war
                    trotzdem super, alle
                    haben die "Niederlage" gelassen gesehen - Unentschieden ist halt nicht verloren. Und machen
                    wir uns nix vor - haben sich nicht alle beschwert, wenn unser Verein nach dem 3. Spieltag die
                    Tabellenf&uuml;hrung nicht mehr hergegeben hatte? Von wegen, Bundesliga ist nicht mehr spannend und
                    so weiter...</p>
                <p>Naja, Meister werden wir ja sehr wahrscheinlich trotzdem werden und unsere Jungs legen demn&auml;chst
                    wieder eine Schippe zu, dass es
                    mit den Toren dann wieder besser klappt. </p>
                <p>Getreu dem Motto: <b>Auf geht's Jungs, k&auml;mpfen und siegen!</b></p>
                <ImageViewer path="./events/images/2022_10_08_BVB_FCB/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild2.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild4.JPG"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild4a.JPG"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild10.JPG"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild12.JPG"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild21.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild30.JPG"
                    },
                ]}/>
                <p>Weitere Bilder findet ihr nat&uuml;rlich in der Bilder-Veranstaltungs&uuml;bersicht.</p>
            </article>
            <hr/>
            <article className="articleContent">
                <h4>01.10.2022 - Jahreshauptversammlung mit Neuwahlen und Grillfest</h4>
                <p>Am 01.10.2022 war es endlich wieder soweit und der FC Bayern Fanclub Forellenbachtal Hohenfels lud zu
                    seiner Jahreshauptversammlung ein.</p>
                <p>Viele Mitglieder sind der Einladung zu Jahreshauptversammlung gefolgt, gleichwohl die Anmeldung zur
                    Jahreshauptversammlung f&uuml;r viele Mitglieder noch gew&ouml;hnungsbed&uuml;rftig erscheint. Der
                    Fanclub hatte
                    seit Jahresbeginn vom klassischen Postversand auf elektronische Einladungen per E-Mail umgestellt.
                    Gut 80% der Mitglieder sind aktuell per E-Mail zu erreichen - f&uuml;r den Fanclub ein Fakt mit
                    hohem
                    Einsparpotential an Kosten. </p>
                <p>Um 16:00 Uhr konnte es dann los gehen. Nach der Begr&uuml;&szlig;ung durch den ersten Vorstand Thomas
                    Stadlmeier, wurde von ihm der Jahresbericht verlesen. Im Anschluss gab der
                    erste Kassierer, Lukas Ring, in seinem Kassenbericht einen &Uuml;berblick &uuml;ber die Finanzen des
                    Fanclubs. </p>
                <p>Im weiteren Verlauf richtete auch der 2. B&uuml;rgermeister von Hohenfels und Fanclubmitglied, Volker
                    Kotzbauer, Gru&szlig;worte des B&uuml;rgermeisters an den Fanclub. Hierbei unterstrich er erneut,
                    wie wichtig
                    die lebendige Vereinsarbeit ist und ermutigte den Vorstand und die anwesenden Fanclubmitglieder
                    weiterzumachen, erst Recht, wo der Fanclub doch dieses Jahr sein 20-j&auml;hriges Bestehen feiern
                    konnte - und das bei konstanten Mitgliederzahlen.</p>
                <p>Bevor dann die Neuwahlen durchgef&uuml;hrt werden konnten, wurde der alte Vorstand entlassen und
                    f&uuml;r seine
                    geleistete Arbeit gew&uuml;rdigt. Mit Ausnahme von Josef Eichenseer, der nicht wieder zur Wahl
                    stand,
                    wurde der alte Vorstand auch im neuen Amt best&auml;tigt. Die Aufgaben des 2.
                    Kassierer &uuml;bernahm
                    Albert
                    Vogel. Thomas Stadlmeier dankte stellvertretend Josef Eichenseer f&uuml;r seine langj&auml;hrige
                    T&auml;tigkeit im
                    Vorstand des Fanclubs.</p>
                <p>Da einige Mitglieder bei der 20 Jahr-Feier im Juli 2022 verhindert waren, wurden im Rahmen der
                    Jahreshauptversammlung noch langj&auml;hrige Fanclubmitglieder f&uuml;r ihre Treue und Verbundenheit
                    zum
                    Fanclub geehrt. Als kleines Dankesch&ouml;n gab es u.a. eine pers&ouml;nliche Fanclub-Tasse mit
                    Fanclubwappen
                    und Namen. Mitglieder k&ouml;nnen die Tasse f&uuml;r einen Unkostenbeitrag von 12,00 € auch
                    nachbestellen.</p>
                <p>Besonders stolz ist der Fanclub darauf, dass die Stadionbesuche wieder stattfinden k&ouml;nnen. So
                    waren
                    Mitglieder schon bei den Begegnungen gegen den VfB Stuttgart, den FC Barcelona dabei und werden auch
                    bei den Spielen gegen den FSV Mainz und FC Internazionale Milano (Inter Mailand) dabei sein
                    k&ouml;nnen.
                    Die Termine zu den
                    anstehenden Begegnungen und der Kontakt zum Kartenbeauftragten findet man auf der Homepage des
                    Fanclubs (https://www.fcb-hohenfels.de) unter Termine/Fussballfahrten. Ebenso finden sich nach den
                    Fahrten die Spiel- und Erlebnisberichte auf der Fanclubhomepage.</p>
                <p>Zum Abschluss der Jahreshauptversammlung hatten die Mitglieder auch Gelegenheit, ihre W&uuml;nsche
                    und
                    Anregungen vorzubringen.</p>
                <p>Nach der Veranstaltung ist zum gemeinsamen Essen eingeladen worden. Hierbei hatte sich der
                    Vorstand etwas Besonderes ausgedacht und ein Spanferkelgrillen geordert. Unter den anwesenden
                    Mitgliedern ist dies sehr gut aufgenommen worden, alle waren recht begeistert.</p>
                <p>Der gemeinsame Abend klang dann aus mit einem gem&uuml;tlichen Beisammensein und einigen
                    Gesellschaftsspielen.</p>
                <p>Der Fanclub hat aktuell&nbsp;{userData.Count}&nbsp;Mitglieder. Der Jahresbeitrag betr&auml;gt
                    f&uuml;r
                    Erwachsene aktuell 15,00 €, f&uuml;r
                    Kinder (6-18 Jahre) 9,00 €, f&uuml;r Kinder (bis 6 Jahre) 1,00 €. Der Aufnahmeantrag findet sich
                    ebenfalls auf der Webseite des Fanclubs im Bereich Verein/Mitglieder.</p>
                <ImageViewer path="./events/images/2022_10_01_JHV2022/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild1.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild2.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild3.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild4.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild5.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild6.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild6a.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild7.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild8.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild9.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild9a.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild9b.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild9c.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild9d.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild10.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild10a.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild10b.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild10c.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild10d.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild11.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild12.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild13.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild14.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild15.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild16.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild17.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild18.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild19.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild20.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild21.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild22.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild23.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild24.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild25.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild26.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild27.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild28.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild29.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild30.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild31.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild32.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild33.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild34.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild35.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild36.jpg"
                    }
                ]}/>

            </article>
            <hr/>
            <article className="articleContent">
                <h4>24.09.2022 - Carina und Johann Brummer geben sich vor Gott das JA-Wort</h4>
                <p>Hohenfels, 24.09.2022 - nach zweimaligem Verschieben (nat&uuml;rlich wegen Corona) war es endlich
                    soweit:
                    Carina und Johann Brummer gaben sich in der Kirche St. Ulrich in Hohenfels da JA-Wort.</p>
                <p>Pfarrer Markus Lettner nahm den beiden Brautleuten in einem wunderbaren Gottesdienst das Versprechen
                    vor Gott ab.</p>
                <p>Nat&uuml;rlich waren wir auch wieder mit Vertretern vom Vorstand vor Ort, um ca. 13:00 Uhr f&uuml;r
                    das
                    Brautpaar beim Verlassen der Kirche Spalier zu stehen.</p>
                <p>Am Abend waren dann noch Mitglieder vom Vorstand bei der Feier dabei.</p>
                <ImageViewer path="./events/images/2022_09_24_Hochzeit_Carina_Hans/" items={[
                    {
                        description: "Carina und Hans mit Vertretern vom Fanclub nach der Trauung vor der Kirche Hohenfels",
                        name: "",
                        src: "Bild1.jpg"
                    },
                    {
                        description: "Das Brautpaar",
                        name: "",
                        src: "Bild2.jpg"
                    },
                    {
                        description: "Das Brautpaar",
                        name: "",
                        src: "Bild3.jpg"
                    },
                    {
                        description: "Die Braut und der erste Vorstand Thomas Stadlmeier",
                        name: "",
                        src: "Bild4.jpg"
                    },
                    {
                        description: "Die Braut",
                        name: "",
                        src: "Bild5.jpg"
                    }]}/>
                <p>Liebe Carina, lieber Hans, der FC Bayern Fanclub Forellenbachtal Hohenfels begl&uuml;ckw&uuml;nscht
                    euch recht
                    herzlich zu eurer Hochzeit heute und w&uuml;nscht euch viel Freude, Spa&szlig; und Gl&uuml;ck im
                    gemeinsamen
                    Eheleben.</p>

            </article>
            <hr/>
            <article className="articleContent">
                <h4>13.09.2022 21:00 Uhr - FC Bayern M&uuml;nchen ./. Barcelona</h4>
                <p>
                    Hohenfels, 16:40 Uhr - p&uuml;nktlich kommt der Bus zur Fahrt nach M&uuml;nchen zum Champions League
                    Spiel
                    gegen den FC Barcelona und dem damit verbundenen Wiedersehen mit den Torgaranten Robert Lewandowski.
                    16:45 Uhr waren die Getr&auml;nke und die Brotzeit verstaut und es konnte endlich los gehen.</p>
                <ImageViewer path="./events/images/2022_09_13_FCB_Barcelona_CL/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild1.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild2.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild3.jpg"
                    }]}/>
                <p> Um 18:20 Uhr dann noch einen kleine Pause, um die &uuml;bersch&uuml;ssigen Getr&auml;nkereste wieder
                    los
                    zu werden
                    und f&uuml;r den einen oder anderen Gelegenheit noch mal eine durchzuziehen.</p>
                <ImageViewer path="./events/images/2022_09_13_FCB_Barcelona_CL/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild4.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild5.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild6.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild7.jpg"
                    }]}/>
                <p>19:00 Uhr waren wir dann an der Allianz-Arena angekommen.</p>
                <ImageViewer path="./events/images/2022_09_13_FCB_Barcelona_CL/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild8.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild9.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild10.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild11.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild12.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild13.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild14.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild15.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild16.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild17.jpg"
                    }
                ]}/>

                <p>
                    Da wir sehr p&uuml;nktlich da waren, blieb noch Zeit f&uuml;r ein Besuch im Fanshop. Allerdings
                    hatten gef&uuml;hlt
                    20.000 andere wohl die gleiche Idee, so dass es ziemlich sinnlos war, sich an eine der zahlreichen
                    Kassen anzustellen. Aber reinschauen lohnt sich immer.</p>
                <ImageViewer path="./events/images/2022_09_13_FCB_Barcelona_CL/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild18.jpg"
                    }]}/>
                <p>
                    Und dann hie&szlig; es warten auf den Anpfiff. Erwartungsgem&auml;&szlig; wurden die Spieler von
                    Barcelona
                    ausgepfiffen, als diese den Platz zum Warm-Up betraten.
                    Daneben konnte man zuschauen, wie Julian Nagelsmann und sp&auml;ter
                    Hasan Salihamidžić den Reportern vor dem Spiel Rede und Antwort stehen mussten.
                    20:20 Uhr kamen dann Manuel Neuer und Ersatzkeeper Sven Ulreich zum Aufw&auml;rmen auf den Platz.
                </p><ImageViewer path="./events/images/2022_09_13_FCB_Barcelona_CL/" items={[
                {
                    name: "",
                    description: "",
                    src: "Bild19.jpg"
                }, {
                    name: "",
                    description: "",
                    src: "Bild20.jpg"
                }, {
                    name: "",
                    description: "",
                    src: "Bild21.jpg"
                }, {
                    name: "",
                    description: "",
                    src: "Bild22.jpg"
                }, {
                    name: "",
                    description: "",
                    src: "Bild23.jpg"
                }, {
                    name: "",
                    description: "",
                    src: "Bild24.jpg"
                }, {
                    name: "",
                    description: "",
                    src: "Bild25.jpg"
                }, {
                    name: "",
                    description: "",
                    src: "Bild26.jpg"
                }, {
                    name: "",
                    description: "",
                    src: "Bild27.jpg"
                }, {
                    name: "",
                    description: "",
                    src: "Bild28.jpg"
                }, {
                    name: "",
                    description: "",
                    src: "Bild29.jpg"
                }, {
                    name: "",
                    description: "",
                    src: "Bild30.jpg"
                }]}/>
                <p> Und unsere Freunde von Fanclub aus Dietfurt wurden auch auf dem Platz gesichtet, Thomas Himmler
                    hat wieder mit vollem Einsatz die Fahne geschwungen.</p>
                <ImageViewer path="./events/images/2022_09_13_FCB_Barcelona_CL/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild31.jpg"
                    }]}/>
                <p> Kurz drauf folgte die Mannschaft zum Aufw&auml;rmen auf den Platz.</p>
                <ReactPlayer
                    url='./events/images/2022_09_13_FCB_Barcelona_CL/video/video1.mp4' controls={true}
                    width={"100%"}
                    loop={true}/>
                <p> Dann war es endlich soweit, der Stadionsprecher Stephan Lehmann verk&uuml;ndete die Spielaufstellung
                    des
                    FC Bayern M&uuml;nchen.</p>
                <ReactPlayer
                    url='./events/images/2022_09_13_FCB_Barcelona_CL/video/video2.mp4' controls={true}
                    width={"100%"}
                    loop={true}/>

                <p> Das Spiel hatte alles gehalten, was man sich erhofft hatte. Die Mannschaften haben von der
                    ersten
                    Minute an mit Vollgas gegeben und jeweils dem Gegner zu verstehen gegeben, dass sie hier siegen
                    wollen.</p>
                <p>
                    Leider hatte es keine der beiden Mannschaften vermocht, in der ersten Halbzeit die zahlreichen
                    Torchancen und verwertbare Z&auml;hler umzusetzen. Robert Lewandowski, der beim Warm-Up der
                    Mannschaft von Barcelona von den Zuschauern herzlichst mit Beifall begr&uuml;&szlig;t wurde, wollte
                    das Tor
                    einfach
                    nicht treffen. Das sind die Tage, die sich kein Spieler w&uuml;nscht - wenn es nicht l&auml;uft,
                    dann
                    l&auml;uft es
                    eben nicht. Uns konnte es nur Recht sein. Aber auch Manuel Neuer hatte mehrfach beweisen
                    k&ouml;nnen,
                    warum er die Nummer Eins in unserem Kasten ist.</p>
                <ImageViewer path="./events/images/2022_09_13_FCB_Barcelona_CL/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild32.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild33.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild34.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild35.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild36.jpg"
                    }]}/>
                <p>
                    Auch wenn Stephan Lehmann vor dem Spiel noch die Zahl des Tages verk&uuml;ndete - 33, so wollte auch
                    dem Geburtstagskind auf dem Platz, dem Spieler mit der R&uuml;ckennummer 25, unser Thomas
                    M&uuml;ller, einfach
                    kein gewinnbringender Schuss auf des Tor des FC Barcelona gelingen. So sehr sich seine
                    Mitspieler mit all ihren Ballvorlagen bem&uuml;hten, an diesem Spieltag wollte es einfach nicht
                    "m&uuml;llern".</p>
                <p>
                    In der 50. Minute erl&ouml;ste Lucas Hernandez mit einem Kopfball nach einer Ecke von Joshua Kimmich
                    die Zuschauer und erzielte so das 1:0 f&uuml;r den FC Bayern M&uuml;nchen.
                    Keine 4 Minuten sp&auml;ter erh&ouml;hte Leroy Sané nach einem Zuspiel von Jamal Musiala zum
                    2:0.</p>
                <ImageViewer path="./events/images/2022_09_13_FCB_Barcelona_CL/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild37.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild38.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild39.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild40.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild41.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild42.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild43.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild44.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild45.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild46.jpg"
                    }]}/>
                <p>
                    Im weiteren Spielverlauf ergaben sich f&uuml;r beide Mannschaften noch weitere Torchancen, welche
                    aber keine wirklich umsetzen konnte. Es blieb beim 2:0 Endstand.</p>

                <ImageViewer path="./events/images/2022_09_13_FCB_Barcelona_CL/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild42.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild47.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild48.jpg"
                    },]}/>
                <p>
                    Um 23:00 Uhr ging es dann wieder auf den Heimweg. Leider dauerte es am Ende ein gute Stunde, bis wir
                    vom Parkplatz wegkamen - um 01:40 Uhr waren wir wieder in Hohenfels.
                </p>

                <ImageViewer path="./events/images/2022_09_13_FCB_Barcelona_CL/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild49.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild50.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild51.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild52.jpg"
                    }]}/>
                <p>
                    Fazit am Abend: es war ein tolles Spiel - es hat sich wieder einmal gelohnt.

                </p>
            </article>
            <hr/>
            <article className="articleContent">
                <h4>10.09.2022 - 15:30 Uhr FC Bayern M&uuml;nchen - VfB Stuttgart</h4>
                <p>
                    Trotz regnerischem Wetter aber gut gelaunt machten sich 40 Mitglieder und
                    Freunde mit dem Bus auf den Weg nach M&uuml;nchen.
                </p><p>
                Das erste Mal seit der Corona Pandemie durften wir wieder ein
                Spiel unserer Mannschaft vor Ort erleben.
                Nach einer kurzen Pause, dass sich alle mit einer Brotzeit st&auml;rken konnten, ging es
                weiter.
                Angekommen auf dem Parkplatz vor der S&uuml;dkurve machten wir uns auf ins Stadion.
            </p><p>
                Gespannt beobachten wir die erste Halbzeit. Kurz vor der Pause machte unser 17 j&auml;hriger Neuzugang
                Tel nach Vorarbeit von Davies das 1:0.
                Nach der Pause kassierten wir den 1:1 Ausgleich. Im Anschluss erzielte Jamal
                Musiala das 2:1.
            </p><p>
                Das ganze Stadion rechnete schon mit einem 2:1 Sieg f&uuml;r unsere Mannschaft, leider bekamen wir noch
                einen Foul-Elfmeter gegen uns, so dass das Spiel 2:2 endete.
            </p><p>
                Getr&uuml;bt vom Ergebnis machten wir uns auf dem Weg zum Bus, raus aus dem Stadion, fing es am zu
                regnen, am
                Bus angekommen waren wir patsch nass.
                Trotz alledem war es ein sch&ouml;ner Nachmittag in der Allianz Arena.
                Endlich wieder mal beim Fu&szlig;ball live dabei - es wurde langsam auch Zeit.
                Den Punkteverlust werden wir verkraften, es war ja erst der 6. Spieltag.
            </p>
                <ImageViewer path="./events/images/2022_09_10_FCB_vs_VfbStuttgart/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild1.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild2.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild3.jpg"
                    }, {
                        name: "",
                        description: "",
                        src: "Bild4.jpg"
                    }
                ]}/>

            </article>


            <article className="articleContent">
                <h4>03.09.2022 - Festumzug <span style={{textDecoration: "line-through"}}>150</span> 152 Jahre
                    Freiwillige Feuerwehr Hohenfels</h4>
                <p> Am 03.09.2022 hat die Freiwillige Feuerwehr Hohenfels ihre Feierlichkeiten anl&auml;&szlig;lich des
                    150-j&auml;hrigen Bestehens nun endlich "nachholen" k&ouml;nnen, nachdem Corona die letzten zwei
                    Jahre
                    s&auml;mtliche Planung auch bei der Feuerwehr zu nichte gemacht hatte.
                </p>
                <p>
                    Vertreter des FC Bayern Fanclub Forellenbachtal Hohenfels wurden von der Freiwilligen Feuerwehr
                    Hohenfels zu den Feierlichkeiten, die nach dem Festgottesdienst im Keltensaal statt fanden,
                    eingeladen. Der Vorstand des FC Bayern Fanclub bedankt sich recht herzlich f&uuml;r die Einladung,
                    der wir gerne nachgekommen sind. Als Vertreter vom Vorstand waren Thomas Stadlmeier, Dietmar
                    Walter und René Drescher-Hackel mit dabei. Unser Kassierer war in der Funktion des Vorstands des
                    Burschenvereins dabei. Unser 2. Vorstand Andreas Graf sorgte sp&auml;ter f&uuml;r die musikalische
                    Begleitung am Abend.</p>
                <ReactPlayer
                    url='./events/images/2022_09_03_150JahreFFWHohenfels/150FFWHohenfels.mp4' controls={true}
                    width={"100%"}
                    loop={true}/>
                <p>Nach einem Festumzug durch die Gemeinde, welcher von der Hohenfelser Blaskapelle angef&uuml;hrt
                    wurde,
                    gefolgt von den Festdamen und den Mitgliedern der Freiwilligen Feuerwehr Hohenfels, sowie den
                    Vertretern der Hohenfelser Vereinen ging es 18:00 Uhr zum Festgottesdienst in die Kirche. Der
                    neue Hohenfelser Pfarrer, Markus Lettner, wurde in diesem Zusammenhang auf das Herzlichste in
                    der Gemeinde willkommen gehei&szlig;en. Unter seiner Leitung und der musikalischen Begleitung durch
                    den Happy Day Chor wurde der gut eine Stunde andauernde Festgottesdienst zu Ehren der
                    Freiwilligen Feuerwehr Hohenfels anl&auml;&szlig;lich des Jubil&auml;ums abgehalten.</p>
                <ImageViewer path="./events/images/2022_09_03_150JahreFFWHohenfels/" items={[
                    {
                        name: "",
                        description: "Festumzug - 150 Jahre Freiwillige Feuerwehr Hohenfels",
                        src: "Bild1.jpg"
                    },
                    {
                        name: "",
                        description: "Festumzug - 150 Jahre Freiwillige Feuerwehr Hohenfels",
                        src: "Bild2.jpg"
                    },
                    {
                        name: "",
                        description: "Festumzug - 150 Jahre Freiwillige Feuerwehr Hohenfels",
                        src: "Bild3.jpg"
                    },
                    {
                        name: "",
                        description: "Festumzug - 150 Jahre Freiwillige Feuerwehr Hohenfels",
                        src: "Bild4.jpg"
                    },
                    {
                        name: "",
                        description: "Festumzug - 150 Jahre Freiwillige Feuerwehr Hohenfels",
                        src: "Bild5.jpg"
                    },
                    {
                        name: "",
                        description: "Festumzug - 150 Jahre Freiwillige Feuerwehr Hohenfels",
                        src: "Bild6.jpg"
                    },
                    {
                        name: "",
                        description: "Festumzug - 150 Jahre Freiwillige Feuerwehr Hohenfels",
                        src: "Bild7.jpg"
                    },
                    {
                        name: "",
                        description: "Festumzug - 150 Jahre Freiwillige Feuerwehr Hohenfels",
                        src: "Bild8.jpg"
                    },
                    {
                        name: "",
                        description: "Festumzug - 150 Jahre Freiwillige Feuerwehr Hohenfels",
                        src: "Bild9.jpg"
                    },
                    {
                        name: "",
                        description: "Festumzug - 150 Jahre Freiwillige Feuerwehr Hohenfels",
                        src: "Bild10.jpg"
                    }

                ]} untertitel="8px" maxWidth={800}/>
                <p>Anschlie&szlig;end ging es gemeinsam zum Keltensaal, wo die Freiwillige Feuerwehr zu einer kleinen
                    Feier zusammen kam. Neben den zahlreichen G&auml;sten, wie dem Landrat Willibald Gailer, dem
                    B&uuml;rgermeister Christian Graf, Vertretern der Feuerwehr vom Truppen&uuml;bungsplatz Hohenfels
                    war auch
                    der neue Gemeindepfarrer, Markus Lettner, der jeden einzelnen mit pers&ouml;nlichem Handschlag
                    begr&uuml;&szlig;te, bei der Feier mit anwesend.</p>
                <div style={{display: "none"}}>
                    <ImageViewer items={[
                        {
                            name: "",
                            description: "Festumzug - 150 Jahre Freiwillige Feuerwehr Hohenfels",
                            src: "Bild11.jpg"
                        }
                    ]} untertitel="8px" maxWidth={800}/>
                </div>
                <p>Bei einem gemeinsamen Abendessen und ausreichend L&ouml;schwasser wurden so zahlreiche Gespr&auml;che
                    gef&uuml;hrt. Einzelne G&auml;ste sollen erst morgens um 03:00 Uhr zum Heimweg aufgebrochen
                    sein.</p>
                <p>F&uuml;r die musikalische Untermahlung sorgte der weltbeste Quetschnspieler, unser 2. Vorstand und
                    Ehrenmitglied, Andreas Graf.</p>


            </article>
            <hr/>

            <article className="articleContent">
                <h4>01.09.2022 - Ballolympiade im Rahmen des Ferienprogramms</h4>
                <p>
                    Am Donnerstag, den 01.09.2022 richtete erstmals der FC Bayern Fanclub Forellenbachtal Hohenfels bei
                    idealen Wetterbedingungen am Sportplatz Haarziegelh&uuml;tte im Rahmen des Ferienprogramms die
                    Ballolympiade aus.
                    Viele Kinder sind der Einladung gefolgt und hatten am Ende viel Spa&szlig;.
                </p>
                <ImageViewer path="./events/images/2022_09_01_Ballolympiade/" items={[
                    {
                        name: "",
                        description: "Ferienprogramm - Ballolympiade",
                        src: "Bild1.jpg"
                    },
                    {
                        name: "",
                        description: "Ferienprogramm - Ballolympiade",
                        src: "Bild1a.jpg"
                    }, {
                        name: "",
                        description: "Ferienprogramm - Ballolympiade",
                        src: "Bild2.jpg"
                    }, {
                        name: "",
                        description: "Ferienprogramm - Ballolympiade",
                        src: "Bild3.jpg"
                    }, {
                        name: "",
                        description: "Ferienprogramm - Ballolympiade",
                        src: "Bild4.jpg"
                    }, {
                        name: "",
                        description: "Ferienprogramm - Ballolympiade",
                        src: "Bild5.jpg"
                    }, {
                        name: "",
                        description: "Ferienprogramm - Ballolympiade",
                        src: "Bild6.jpg"
                    }, {
                        name: "",
                        description: "Ferienprogramm - Ballolympiade",
                        src: "Bild7.jpg"
                    }, {
                        name: "",
                        description: "Ferienprogramm - Ballolympiade",
                        src: "Bild8.jpg"
                    }, {
                        name: "",
                        description: "Ferienprogramm - Ballolympiade",
                        src: "Bild9.jpg"
                    }, {
                        name: "",
                        description: "Ferienprogramm - Ballolympiade",
                        src: "Bild10.jpg"
                    }, {
                        name: "",
                        description: "Ferienprogramm - Ballolympiade",
                        src: "Bild11.jpg"
                    }, {
                        name: "",
                        description: "Ferienprogramm - Ballolympiade",
                        src: "Bild12.jpg"
                    }
                ]}/>
                <p>
                    Es wurden 7 Stationen aufgebaut, angefangen beim Eierlauf mit Hindernissen, "Basketball",
                    Torwandschie&szlig;en, Weitwurf, Dosenschie&szlig;en, Minigolf in Fu&szlig;ballformat,
                    Fu&szlig;balltennis, Mondball und
                    Mondball auf Strecke. Hierbei konnten sich die Kinder ausgiebig austoben, was sie auch taten.
                    Nat&uuml;rlich gab es auch etwas Leckereien f&uuml;r die kleinen Sportler, die der
                    B&uuml;rgermeister Christian
                    Graf pers&ouml;nlich vorbei brachte.
                    Nach einem leckeren Eis und einem kalten Getr&auml;nk, konnten die Kinder nach einer kleinen Pause
                    zwischendurch wieder gest&auml;rkt weiter machen.
                    Zum Abschluss hatte noch jedes Kind ein &Uuml;berraschungsgeschenk bekommen.
                </p>
                <p>
                    Im Ergebnis war es ein sch&ouml;ner unterhaltsamer Nachmittag, der bei Gro&szlig; und Klein sehr
                    positiv
                    an
                    kam.
                </p>
                <p>F&uuml;r die tatkr&auml;ftige Unterst&uuml;tzung bedankt sich der Vorstand des Fanclubs bei seien
                    Helfern
                    (Juliane
                    Spangler, Josef Eichenseer, Jasmin & Thomas Stadlmeier, Alina M&uuml;nchsmeier und Margit Walter)
                    ohne
                    die
                    es sicher nicht so gut gelaufen w&auml;re.</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>30.08.2022 - Einladung zur Jahreshauptversammlung</h4>
                <p> Am 01.10.2022 findet unsere Jahreshauptversammlung statt. Die Anmeldung und weitere Informationen
                    findet ihr <a href="#/Termine">&uuml;ber das Hauptmen&uuml; unter <i>Termine</i></a>.
                    <p>
                        Am 13.09.2022 fahren wir nach M&uuml;nchen zum Spiel Champions League Spiel gegen Barcelona,
                        Ansto&szlig;
                        21:00 Uhr.
                        Anmeldungen &uuml;ber den Kartenbeauftragten Stephan Walter (karten@fcb-hohenfels.de).</p>
                </p>

            </article>
            <hr/>

            <article className="articleContent">
                <h4>27.08.2022 - FC Bayern M&uuml;nchen ./. Borussia M&ouml;nchengladbach</h4>
                <p>Am 27.08.2022 empfing der FC Bayern M&uuml;nchen die Mannschaft von Borussia M&ouml;nchengladbach.
                    Bisweilen
                    waren diese Begegnungen f&uuml;r den FC Bayern M&uuml;nchen nicht gerade von Erfolg gekr&ouml;nt,
                    geht
                    doch der
                    letzte Sieg auf den 08.05.2021 zur&uuml;ck, wo das Endergebnis ein repektables 6:0 war.</p>
                <p>
                    F&uuml;r die Fans der S&uuml;dkurve war es aber auch ein Grund zum Feiern, unabh&auml;ngig vom
                    Ergebnis
                    des Spiels.
                    Die S&uuml;dkurve trumpfte zum 50. Geburtstag der S&uuml;dkurve mit einer beeindruckenden
                    Choreographie
                    auf.</p>
                <ImageViewer path="./events/images/2022_08_27_FCB_Gladbach/" items={[
                    {
                        name: "",
                        description: "50 Jahre Südkurve",
                        src: "Bild1.jpg"
                    },
                    {
                        name: "",
                        description: "50 Jahre Südkurve",
                        src: "Bild1a.jpg"
                    },
                    {
                        name: "",
                        description: "50 Jahre Südkurve",
                        src: "Bild1b.jpg"
                    }, {
                        name: "",
                        description: "50 Jahre Südkurve",
                        src: "Bild1c.jpg"
                    }

                ]}/>
                <p>Seit mittlerweile 50 Jahren ist die S&uuml;dkurve die Heimat der M&uuml;nchner Anh&auml;nger. Alles
                    begann 1972 im
                    damals neugebauten Olympiastadion. Da wir aus der gegen&uuml;berliegenden Stadionseite unsere Jungs
                    auf
                    dem Platz anfeuerten, hatten wir auch zugleich die beste Sicht auf die Choreographie der
                    S&uuml;dkurve.</p>
                <p> In der 34. Minute fiel dann endlich das erste Tor durch Sadio Mané, doch hatten die Kollegen im
                    Videokeller etwas dagegen: der Treffer z&auml;hlte auf Grund einer Abseitsstellung von Leroy Sané.
                    <br/>
                    Das komplette Spiel fand eigentlich immer in der H&auml;lfte des Gegners statt, aber trotz der
                    Vielzahl
                    an Chancen konnten unsere Jungs dann kein Tor mehr erzielen und dann musste es kommen, wie es kommen
                    musste.
                    Nach einem Einwurf und dem Befreiungsschlag, schaute unsere Mannschaft am Ende nicht gut aus und
                    Marcus Thuram nutze die ihm einmalige Chance und er lief alleine auf Manuel Neuer zu und
                    konnte ihn &uuml;berwinden.
                    Somit gingen die G&auml;ste aus M&ouml;nchengladbach mehr oder weniger verdient 1:0 in F&uuml;hrung.
                    <ImageViewer path="./events/images/2022_08_27_FCB_Gladbach/" items={[
                        {
                            name: "",
                            description: "FC Bayern München ./. Borussia Mönchengladbach",
                            src: "Bild1c.jpg"
                        },
                        {
                            name: "",
                            description: "FC Bayern München ./. Borussia Mönchengladbach",
                            src: "Bild2.jpg"
                        },
                        {
                            name: "",
                            description: "FC Bayern München ./. Borussia Mönchengladbach",
                            src: "Bild3.jpg"
                        },
                        {
                            name: "",
                            description: "FC Bayern München ./. Borussia Mönchengladbach",
                            src: "Bild3a.jpg"
                        },
                        {
                            name: "",
                            description: "FC Bayern München ./. Borussia Mönchengladbach",
                            src: "Bild3b.jpg"
                        },
                        {
                            name: "",
                            description: "FC Bayern München ./. Borussia Mönchengladbach",
                            src: "Bild3c.jpg"
                        },
                        {
                            name: "",
                            description: "FC Bayern München ./. Borussia Mönchengladbach",
                            src: "Bild5.jpg"
                        }
                    ]}/>
                    Nach der Pause, dass gleiche Bild, unsere Mannschaft lief an und scheiterte ein ums andere mal am an
                    diesem Abend &uuml;berragenden Torwart der G&auml;ste Yann Sommer.
                    Die Stimmung im Stadion war atemberaubend und die ausverkaufte Allianz Arena feuerte und peitschte
                    unser
                    Team immer wieder nach vorne.
                    Kurz vor Schluss in der 83. Minute kamen wir wenigstens doch noch zum 1:1 Ausgleich, den unsere
                    Nummer 10 Leroy Sané mit
                    seinem linken Fu&szlig; erzielte.
                    Im Anschluss warfen unsere Jungs nochmal alles nach vorne, aber mehr als das 1:1 sollte es an diesem
                    Abend nicht werden.

                </p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>07.08.2022 - Neues vom Schaukasten</h4>
                <p>
                    Dieses Jahr ist es nun schon 20 Jahre her, als sich der Fanclub gr&uuml;ndete.
                    Grund genug den Tag geb&uuml;hrend zu begehen - im Schaukasten gibt einen Mini-R&uuml;ckblick auf
                    den Abend!
                </p>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "20 Jahre FC Bayern Fanclub Forellenbachtal Hohenfels",
                        src: "Schaukasten_2022_08_07.jpg"
                    }
                ]} untertitel="8px" maxWidth={800}/>

            </article>
            <hr/>

            <article className="articleContent">
                <h4>23.07.2022 - FC Bayern Fanclub Forellenbachtal Hohenfels feiert 20 Jahre Bestehen des Vereins</h4>
                <p> Am 23.07.2022 sind zahlreiche Mitglieder der Einladung zum Ehrenabend anl&auml;sslich dem
                    20-j&auml;hren
                    Bestehen des Fanclubs gefolgt.
                </p>
                <p>18:00 Uhr trafen sich viele Fanclubmitglieder vor dem offiziellen Beginn zu einem gemeinsamen Besuch
                    der Hohenfelser Kirche, um beim gemeinsamen Gottestdienst der verstorbenen Mitglieder zu
                    gedenken.</p>
                <p>19:00 Uhr begr&uuml;&szlig;te dann der Vorstandsvorsitzende Thomas Stadlmeier die Mitglieder und
                    G&auml;ste zum
                    Ehrenabend. Nach einer Gedenkminute konnten die anwesenden Mitglieder sich beim Abendessen
                    austauschen und
                    in Erinnerungen verweilen. Dabei wurden w&auml;hrend des Essens immer wieder Bilder aus vergangenen
                    Tagen
                    &uuml;ber den Beamer auf der Leinwand projiziert.</p>
                <p>Thomas brachte einen ern&uuml;chternden R&uuml;ckblick auf die doch recht trostlosen letzten zwei
                    Jahre, hat
                    doch die Corona-Pandemie nahezu jegliches Vereinsleben vielerorts zum Erliegen gebracht. F&uuml;r
                    dieses
                    Jahr schaut es doch schon viel besser aus. Allein das dieser Abend so stattfinden konnte, im 20.
                    Jahr des Bestehens des FC Bayern Fanclub Forellenbachtal Hohenfels, war f&uuml;r alle eine
                    gro&szlig;e
                    Freude. Auch der anwesende B&uuml;rgermeister Christian Graf richtete entsprechende Gru&szlig;worte
                    an
                    den
                    Verein.</p>
                <p>Sodann verk&uuml;ndete der Vorstandsvorsitzende die Ehrung seiner 5 Ehrenmitglieder, welche sich
                    insbesondere durch ihren langj&auml;hrigen und unerm&uuml;dlichen Einsatz f&uuml;r den Verein
                    hervorgetan
                    hatten.</p>
                <ImageViewer path="./events/images/2022_07_23_Ehrenabend/" items={[
                    {
                        name: "",
                        description: "Ehrenmitglieder",
                        src: "Bild1.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild2.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild3.JPG"
                    }

                ]} untertitel="8px" maxWidth={800}/>
                <p>Im weiteren Vorgang wurden die Gr&uuml;ndungsmitglieder, welche immer noch dabei sind mit einer
                    Urkunde
                    und einem kleinen Dankesch&ouml;n vom Verein bedacht. Nicht zu vergessen dabei auch die Mitglieder,
                    welche zwar bei der Gr&uuml;ndung selbst nicht dabei waren, aber im Gr&uuml;ndungsjahr Mitglied im
                    Fanclub
                    wurden und seitdem diesen die Treue halten. Auch sie wurden vom Vorstand entsprechend geehrt. Eine
                    besondere Ehrung wurde zudem dem B&uuml;rgermeister und unserem Pfarrer zu teil.</p>
                <ImageViewer path="./events/images/2022_07_23_Ehrenabend/" items={[
                    {
                        name: "",
                        description: "Gründungsmitglieder & langjährige Mitglieder",
                        src: "Bild11.JPG"
                    },
                    {
                        name: "",
                        description: "Bürgermeister & Pfarrer",
                        src: "Bild12.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild4.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild5.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild6.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild7.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild8.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild9.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild10.JPG"
                    }
                ]} untertitel="8px" maxWidth={800}/>
                <p>Der Ehrenabend klang dann aus bei einem gem&uuml;tlichen Beisammensein. Mancher nutze die anwesende
                    Musik, um seine Tanzk&uuml;nste zu pr&auml;sentieren, andere vergn&uuml;gten sich an der Bar oder
                    versuchten sich
                    bei Geschicklichkeitsspielen.</p>
                <ImageViewer path="./events/images/2022_07_23_Ehrenabend/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild13.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild14.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild15.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild16.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild16a.JPG"
                    },

                    {
                        name: "",
                        description: "",
                        src: "Bild18.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild19.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild20.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild21.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild22.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild22a.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild23.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild24.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild25.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild26.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild27.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild28.JPG"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild29.JPG"
                    }
                ]} untertitel="8px" maxWidth={800}/>
                <p>Im Ergebnis war es doch nach langer Zeit mal wieder ein sch&ouml;ner gemeinsamer Vereinsabend. Als
                    n&auml;chstes steht am 10.09.2022 unsere gemeinsame Busfahrt zum Spiel das FC Bayern M&uuml;nchen
                    gegen den
                    VfB Stuttgart an.</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>09.07.2022 - FC Bayern Fanclub Forellenbachtal Hohenfels zu Gast bei den Stocksch&uuml;tzen
                    Hohenfels</h4>
                <p> Die Stocksch&uuml;tzen Hohenfels haben heute bei besten Wetterbedingungen zu einem kleinen Tunier
                    eingeladen. Neben dem FC Bayern Fanclub, sind auch die Gymnastikdamen, die FFW Gro&szlig;bissendorf,
                    die
                    FFW Hohenfels,FFW Raitenbuch, der TSV Hohenfels, der Fanclub des 1. FC N&uuml;rnberg, der
                    Burschenverein
                    und der Frauenbund der Einladung
                    der Stocksch&uuml;tzen Hohenfels gefolgt.
                </p><p>

                Im ersten Spiel gegen die Gymnastikdamen haben wir einen 9:3 Vorsprung noch verspielt und somit
                stand Es am Ende 9:9.
                Die n&auml;chste Spiele gegen FFW Gro&szlig;bissendorf und FFW Hohenfels wurden souver&auml;n 19:3 und
                24:10
                gewonnen.
                Gegen den TSV Hohenfels, 1.FCN Fanclub und Frauenbund tat sich unser Fanclub schon schwerer und die
                Spiele wurden meist mit dem letzten Schub erst entschieden, womit wir unsere Nervenst&auml;rke zeigten.
                16:8, 13:11 und 14:12.
            </p><p> Nach der Pause kamen wir nicht gut wieder zur&uuml;ck, sodass wir 6:18 gegen die FFW Raitenbuch den
                k&uuml;rzeren zogen aber wir wollten uns den Sieg vor Augen nicht mehr aus der Hand nehmen lassen und im
                allerletzten Spiel gewannen wir gegen unsere Freunde vom Burschenverein mit 16:8, der Jubel kannte
                keine Grenzen.
            </p><p>
                Wie sind stolz, auf unsere Leistung und dass wir das Gemeindeturnier am Ende gewonnen haben.

                Bei einem spannenden und hochklassigen Tunier haben wir vom FC Bayern Fanclub Forellenbachtal Hohenfels
                den 1. Platz belegt.
                <ImageViewer path="./events/images/2022_07_09_Stockschuetzen/" items={[
                    {
                        name: "",
                        description: "Stockschützen Gemeindetunier Hohenfels",
                        src: "Bild1.jpg"
                    },
                    {
                        name: "",
                        description: "Stockschützen Gemeindetunier Hohenfels",
                        src: "Bild2.jpg"
                    },
                    {
                        name: "",
                        description: "Stockschützen Gemeindetunier Hohenfels",
                        src: "Bild3.jpg"
                    },
                    {
                        name: "",
                        description: "Stockschützen Gemeindetunier Hohenfels",
                        src: "Bild4.jpg"
                    }
                ]} untertitel="8px" maxWidth={800}/>
                Wie bedanken uns bei den Stocksch&uuml;tzen Hohenfels f&uuml;r die Einladung.
            </p>


            </article>
            <hr/>

            <article className="articleContent">
                <h4>20 Jahre FC Bayern Fanclub Forellenbachtal Hohenfels</h4>
                <p>
                    Dieses Jahr ist es nun schon 20 Jahre her, als sich der Fanclub gr&uuml;ndete.
                    Grund genug den Tag geb&uuml;hrend zu begehen - drum lasst uns feiern!
                </p>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "Aushang zur 20 Jahr Feier",
                        src: "Schaukasten_2022_07_09.jpg"
                    }
                ]} untertitel="8px" maxWidth={800}/>
                <p>
                    Dieses Jubil&auml;um m&ouml;chten wir am Samstag, den <b>23.07.2022</b> im Keltensaal in Hohenfels
                    im Rahmen
                    einer
                    angemessenen Veranstaltung zusammen mit allen Mitgliedern feiern.</p>
                <p>Beginn der Veranstaltung ist f&uuml;r <b>19:00 Uhr</b> geplant.</p>
                <p>Um <b>18:00 Uhr</b> findet in Hohenfels bereits eine Kirche f&uuml;r alle verstorbenen Mitglieder
                    statt.
                    N&auml;here Informationen zum weiteren Ablauf an diesem Abend erhaltet Ihr zeitnah im Rahmen einer
                    Einladung.</p>

                <p>Bitte haltet Euch den 23.07.2022 bereits in Eurem Terminkalender frei.</p>

                <p>Wir w&uuml;rden uns &uuml;ber zahlreiches Erscheinen freuen.</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>02.07.2022 - FC Bayern Fanclub Forellenbachtal Hohenfels beim Elfmeterschie&szlig;en</h4>
                <p>
                    Der Hohenfelser Fanclub vom FCN und der FC Bayern Fanclub in Kallm&uuml;nz haben zum
                    Elfmeter-Schie&szlig;en
                    eingeladen. Und so hat der Fanclub eine Gruppe der besten Sch&uuml;tzen entsandt, um uns w&uuml;rdig
                    zu
                    vertreten. Und Elfmeter muss man erst mal schie&szlig;en k&ouml;nnen. Das schaut ja immer so einfach
                    aus...
                </p>
                <ImageViewer path="./events/images/2022_07_02_Elfmeterschiessen/" items={[
                    {
                        name: "",
                        description: "Elfmetertunier Hohenfels",
                        src: "Bild1.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Hohenfels",
                        src: "Bild2.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Hohenfels",
                        src: "Bild3.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Hohenfels",
                        src: "Bild4.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Hohenfels",
                        src: "Bild5.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Hohenfels",
                        src: "Bild6.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Hohenfels",
                        src: "Bild7.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Hohenfels",
                        src: "Bild8.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Hohenfels",
                        src: "Bild9.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Hohenfels",
                        src: "Bild10.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Hohenfels",
                        src: "Bild11.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Hohenfels",
                        src: "Bild12.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Hohenfels",
                        src: "Bild13.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Kallmünz",
                        src: "Bild14.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Kallmünz",
                        src: "Bild15.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Kallmünz",
                        src: "Bild16.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Kallmünz",
                        src: "Bild17.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Kallmünz",
                        src: "Bild18.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Kallmünz",
                        src: "Bild19.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Kallmünz",
                        src: "Bild204.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Kallmünz",
                        src: "Bild21.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Kallmünz",
                        src: "Bild22.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Kallmünz",
                        src: "Bild23.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Kallmünz",
                        src: "Bild24.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Kallmünz",
                        src: "Bild25.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Kallmünz",
                        src: "Bild26.jpg"
                    }, {
                        name: "",
                        description: "Elfmetertunier Kallmünz",
                        src: "Bild27.jpg"
                    }

                ]} untertitel="8px" maxWidth={800} indicators={false}/>
                <p>... ist es aber nicht. Dennoch mussten wir uns nicht verstecken und den letzten Platz haben wir auch
                    nicht belegt.</p>

                <p>In Hohenfels haben wir einen hervorragenden 13. Platz belegt und in Kallm&uuml;nz immerhin einen
                    guten 20.
                    Platz. Und wer mit der Bundesliga vertraut ist, zwischen Meister und Absteiger kann es schon mal eng
                    zu gehen. Aber was solls. Die Platzierung ist das eine, dabei sein ist das entscheidene. Und so
                    hatte es allen beteiligten Akteuren sichtlich viel Spa&szlig; gemacht.</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>25.06.2022 - Unser Vorstandsvorsitzender ist endg&uuml;ltig weg vom Markt</h4>
                <ImageViewer path="./events/images/2022_06_25_Hochzeit_Stadlmeier/" items={[
                    {
                        name: "",
                        description: "Jasmin & Thomas Stadlmeier - Kirchliche Trauung 25.06.2022",
                        src: "Bild1.jpg"
                    }

                ]} untertitel="8px" maxWidth={800} indicators={false}/>
                <p>Unser Thomas hat heute seiner Jasmin das JA-Wort vor Gott in der Kirche Hohenfels gegeben. Und unsere
                    Fanclubmitglieder sind zum Spalier stehen gekommen. Einige Vertreter des Vorstandes sind dann
                    sp&auml;ter
                    noch bei der Hochzeitsfeier dabei und werden dem Brautpaar das Geschenk vom Verein &uuml;bergeben.
                    Wir
                    werden dann sp&auml;ter hier noch einige Eindr&uuml;cke von der Feier pr&auml;sentieren und eine
                    kleine
                    &Uuml;berraschung haben wir uns nat&uuml;rlich auch noch ausgedacht, doch dazu sp&auml;ter mehr.</p>
                <p>Liebe Jasmin, lieber Thomas, der Bayernfanclub Forellenbachtal Hohenfels begl&uuml;ckw&uuml;nscht
                    euch recht
                    herzlich zu eurer Hochzeit heute und w&uuml;nscht euch viel Freude, Spa&szlig; und Gl&uuml;ck im
                    gemeinsamen
                    Eheleben.</p>
                <p><b><i>Update - 26.06.2022</i></b></p>
                <p>P&uuml;nktlich zur Brautentf&uuml;hrung waren Vertreter des Vorstandes bei den Feierlichkeiten,
                    welche ganz im
                    Zeichen von Hollywood standen, eingetroffen. Und wir w&auml;ren schlecht beraten, h&auml;tten wir
                    nicht von
                    Anfang an vorgehabt, den begehrten Brautstrau&szlig; in unseren Besitz zu bringen, was Lukas dann
                    auch nach einer kleinen Ablenkung zuvor hervorragend gelungen war.</p>
                <ImageViewer path="./events/images/2022_07_23_Ehrenabend/" items={[
                    {
                        name: "",
                        description: "Vertreter des Vorstandes (Dietmar, René, Thomas, Stephan und Lukas - v.l.n.r.)",
                        src: "Bild2.jpg"
                    }, {
                        name: "",
                        description: "Der begehrte Brautstrauß",
                        src: "Bild2a.jpg"
                    }, {
                        name: "",
                        description: "Das Brautpaar...",
                        src: "Bild3.jpg"
                    }, {
                        name: "",
                        description: "..,und da ist das Ding, bewacht von Thomas Mulzer",
                        src: "Bild4.jpg"
                    }, {
                        name: "",
                        description: "..,und da ist das Ding, bewacht von Thomas Mulzer",
                        src: "Bild4a.jpg"
                    }, {
                        name: "",
                        description: "..,und da noch mal mit dem Brautpaar",
                        src: "Bild4b.jpg"
                    },


                ]} untertitel="8px" maxWidth={800} indicators={true}/>
                <p>Thomas, aber auch die Braut sind dann auff&auml;llig oft an unserem Tisch vorbei gekommen, wollten
                    sie
                    doch gern ihren Brautstrau&szlig; zur&uuml;ck haben. Aber wie Thomas dann zu sp&auml;ter Stunde
                    eingestand, hatte es
                    auch etwas gutes, so konnte niemand anderes den Brautstrau&szlig; kapern, so lange er in unserem
                    Besitz
                    war. </p>
                <p>Nach dem Abendessen war es dann soweit, Thomas und Jasmin konnten den Brautstrau&szlig; teuer
                    zur&uuml;ck
                    erwerben. Dazu hatten wir uns ein kleines Spiel ausgedacht. Das Brautpaar musste einen langsamen
                    Walzer tanzen und unter dem Tanzen dann versuchen, ein Tor an der Torwand zu erzielen. Unter den
                    Moderation vom Lukas Ring konnte das Brautpaar dann starten.</p>
                <p>Zuvor hatte Stephan Walter das Geschenk vom Fanclub an die frisch gebackenen
                    Eheleute &uuml;bergeben.</p>
                <ImageViewer path="./events/images/2022_06_25_Hochzeit_Stadlmeier/" items={[
                    {
                        name: "",
                        description: "Gruppenfoto vor der Kirche mit etwas Kleingeld angereichert",
                        src: "Bild5.jpg"
                    }, {
                        name: "",
                        description: "Zwei Gläser mit persönlcher Gravur und noch mehr Kleingeld...",
                        src: "Bild6.jpg"
                    }, {
                        name: "",
                        description: "Zwei Gläser mit persönlcher Gravur und noch mehr Kleingeld...",
                        src: "Bild6a.jpg"
                    },


                ]} untertitel="8px" maxWidth={800} indicators={true}/>
                <p>Nun war Lukas an der Reihe. Er erkl&auml;rte zuvor den Einsatz und die Spielregeln. Der Einsatz war
                    f&uuml;r
                    jeden Treffer eine Runde f&uuml;r den Vorstand und die Band. F&uuml;r jeden Fehlversuch oder jeden
                    Schu&szlig; auf
                    die Torwand, der nicht als Treffer gewertet werden konnte, stellt das Brautpaar f&uuml;r die
                    n&auml;chste
                    Fahrt ins Stadion einen Kasten Bier zur Verf&uuml;gung. Nun es war ein spa&szlig;iges Unterfangen
                    f&uuml;r das
                    Brautpaar und am Ende haben sie uns dursten lassen aber f&uuml;r die n&auml;chste Fahrt ordentlich
                    Bier
                    bereitgestellt, denn sie haben nur einen von 6 Versuchen verwandeln k&ouml;nnen, Zwei Fehlversuche
                    und 5
                    mal nicht getroffen hei&szlig;t nun sieben K&auml;sten Bier f&uuml;r den n&auml;chten
                    Stadionbesuch.</p>

                <ReactPlayer
                    url='./events/images/2022_06_25_Hochzeit_Stadlmeier/Torwandschiessen.mp4' controls={true}
                    width={"100%"}
                    loop={true}/>

                <p>Da sagen wir doch dem Brautpaar Danke! </p>
                <p>Jasmin, aber auch Thomas war am Ende gl&uuml;cklich, wieder ihren Brautstrau&szlig; in ihrem Besitz
                    zu
                    haben.</p>
                <p>Um 01:00 Uhr war dann der Schlu&szlig;tanz des Brautpaares gekommen. Und wie die Band zutreffend
                    zusammenfasste, es war eine Oscar-reife Hochzeit.</p>
                <p>Auf diesem Wege noch einmal Dankesch&ouml;n f&uuml;r die Einladung und wir hoffen doch sehr, dass es
                    Thomas und
                    Jasmin auch viel Freude gemacht hat, ihren Brautstrau&szlig; wieder zur&uuml;ck zu erobern.</p>
                <ImageViewer path="./events/images/2022_06_25_Hochzeit_Stadlmeier/" items={[
                    {
                        name: "",
                        description: "Thomas will seine Braut zurück",
                        src: "Bild7.jpg"
                    }, {
                        name: "",
                        description: "...und der Brautentführer muss büßen",
                        src: "Bild7a.jpg"
                    }, {
                        name: "",
                        description: "Dietmar & René",
                        src: "Bild8.jpg"
                    }, {
                        name: "",
                        description: "Bilder von der Feier",
                        src: "Bild9.jpg"
                    }, {
                        name: "",
                        description: "Bilder von der Feier",
                        src: "Bild10.jpg"
                    },


                ]} untertitel="8px" maxWidth={800} indicators={true}/>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>24.04.2022 - Deutscher Fu&szlig;ballmeister 2022</h4>
                <ImageViewer path="meister2022/" items={[
                    {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "20220423_Stadion_18.jpg"
                    },
                    {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "20220423_Stadion_2.jpg"
                    },


                ]} untertitel="8px" maxWidth={800} indicators={true}/>

                <p>
                    Was f&uuml;r ein Spiel! Zum 10. Mal in Folge wird der FC Bayern M&uuml;nchen Deutscher Meister in
                    der h&ouml;chsten
                    Klasse der Bundesliga. Auch wenn Philipp Lahm im Interview sagte, es tut der Bundesliga nicht gut,
                    wenn die Meisterschaft immer mehrere Spieltage vor Saisonende entschieden ist, so ist und bleibt es
                    auch f&uuml;r den FC Bayern immer eine gro&szlig;e Herausforderung, jedes Jahr die gejagten um den
                    Meistertitel
                    zu sein. Julian Nagelsmann, aktueller Cheftrainer des FC Bayern, brachte es treffend auf den Punkt.
                    Im Sky-Interview nach dem Spiel sagte er, es ist nicht selbstverst&auml;ndlich, Deutscher Meister zu
                    werden. Aus eigener Erfahrung (mit RB Leipzig) wei&szlig; er nur zu gut, was die anderen
                    Bundesligamannschaften alles unternehmen, um den Bayern den Kampf um das Titelrennen so schwer wie
                    m&ouml;glich zu machen. Es ist und bleibt stets eine au&szlig;ergew&ouml;hnliche Leistung der
                    Mannschaft, wenn der
                    Meistertitel perfekt ist. 4 Spieltage vor Saisonende f&uuml;hrt der FC Bayern die Tabelle mit
                    uneinholbaren 12 Punkten vor Dauerrivalen BVB Dortmund.
                </p>

                <ImageViewer items={[
                    {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_5.jpg"
                    },
                    {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_6.jpg"
                    }, {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_22.jpg"
                    }, {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_7.jpg"
                    },

                ]} untertitel="8px" maxWidth={800} indicators={true}/>

                <p>Einer unserer j&uuml;ngsten Fanclubmitglieder wurde sogar im Satdion gesichtet. Und wie sich
                    herausstellte, hatte sie sichtlich Spa&szlig; und fieberte dem Sieg der Bayern entgegen.</p>

                <p>Der Fanclub Forellenbachtal Hohenfels gratuliert der Mannschaft des FC Bayern und allen beteiligten
                    Akteuren zum Erreichen der Deutschen Fu&szlig;ballmeisterschaft 2021/22.</p>

                <p>Das Stadion war gestern picke packe voll, ausverkauft mit 75.000 Zuschauern. Und es war wieder
                    sch&ouml;n,
                    das so mitzuerleben, nach all den ganzen Geisterspielen oder den Zeiten der sp&auml;rlich besetzten
                    Stadien.</p>

                <ImageViewer items={[
                    {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_23.jpg"
                    },
                    {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_15.jpg"
                    },
                    {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_21.jpg"
                    }

                ]} untertitel="8px" maxWidth={800} indicators={true}/>

                <p>Ein paar Dortmunder Optimisten waren auch mit angereist, hatten sie doch die Hoffnung, der Mannschaft
                    des FC Bayern auf dem Weg zur 10. Meisterschaft in Folge ein Bein zu stellen. Leider haben sie sich
                    die Statistik nicht vorher genauer angesehen. Das letzte Mal, wo der BVB bei den Bayern im
                    Wohnzimmer gewonnen hatte, geht wohl bist 2018 zur&uuml;ck.</p>
                <p>
                    Zugegebenerma&szlig;en - beide Mannschaften haben sich nichts geschenkt, obwohl in der erste
                    Halbzeit der
                    BVB wenig Gegenwehr zeigte. Es dauerte 15 Minuten und schon war klar, wer Herr im Haus ist. Ecke
                    Kimmich und nach nach kurzem Verwirrspiel vor dem Dortmunder Tor landete der Ball bei Serge Gnabry
                    auf dem Fu&szlig;, der aus knapp 15 Meter eiskalt abzieht - 1:0 Bayern!
                </p>


                <ImageViewer items={[
                    {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_1.jpg"
                    },
                    {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_22.jpg"
                    }, {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_24.jpg"
                    },

                ]} untertitel="8px" maxWidth={800} indicators={true}/>

                <p>
                    Erling Haaland zeigte immer wieder, dass er schwer zu kontrollieren ist, aber an einen Robert
                    Lewandowski kommt er nicht ran. Immer wieder f&uuml;hrten seine Aktionen, die schon
                    brandgef&auml;hrlich
                    waren, nicht zum Erfolg. Und so dauerte es wieder nur 15 Minuten, bis Serge Gnabry zum 2:0
                    erh&ouml;hte.
                    W&auml;hrend sich die Fans im Stadion &uuml;ber den Fortgang des Spieles noch freuten, erkannte der
                    Stadionsprecher Stephan Lehmann, dass unsere Freunde im Videokeller in K&ouml;ln etwas dagegen
                    hatten.
                    Eine Fu&szlig;spitze weit stand Gnabry wohl dann doch im Abseits und so wurde das 2:0 wieder
                    kassiert.
                    Die Fans im Stadion antworteten lautstark mit <i>Schei&szlig; DFB</i> und machten so ihrem Unmut
                    Luft.
                </p>
                <p>Robert Lewandowski w&auml;re nicht der, der er ist und antwortete seinerseits in der 34. Minute mit
                    seinem
                    33. Saisontor. Nun stand es zu Recht 2:0 f&uuml;r die Spieler des FC Bayern M&uuml;nchen. Auf den
                    R&auml;ngen h&ouml;rte
                    man nur noch <i>Deutscher Meister wird nur der FCB, nur der FCB, nur der FCB...</i>, wissentlich,
                    dass der Meistertitel den Bayern nun nicht mehr zu nehmen ist. </p>
                <p>Halbzeitstand 2:0 - der FC Bayern den Titel voll im Blick!</p>
                <p>
                    In der 52. Minute sorgte Joshua Kimmich mit seinem Foul an Markus Reuss f&uuml;r einen Elfmeter
                    f&uuml;r die
                    G&auml;ste, so dass es noch unn&ouml;tig spannend wurde. Wird der BVB seine Chance nutzen
                    k&ouml;nnen? In der
                    zweiten Halbzeit hat der BVB mehr Gas gegeben, nicht zuletzt befl&uuml;gelt durch den
                    Anschlusstreffer
                    nach dem Elfmeter von Emre Can.
                </p>
                <p>
                    In der 63. Minute wird der Sch&uuml;tze zum 1:0 ausgewechselt. Jamal Musiala betritt das Spielfeld.
                    Viele
                    Bundesligamannschaften haben unter der Saison erfahren m&uuml;ssen, was dies bedeuten kann. In der
                    83.
                    Minute ist es dann soweit, Musiala bereitet seinen eigenen Treffer vor und versengt am Ende zum 3:1.
                </p>
                <ReactPlayer url='./events/images/meister2022/20220423_Stadion_1.mp4' controls={true} width={"100%"}
                             loop={true}/>
                <p>
                    Es gibt am Ende noch 4 Minuten Nachspielzeit, doch die Fans sind nicht mehr zu halten. Die letzten
                    Minuten hallt es nur noch durch's Stadion: <i>Deutscher Fu&szlig;ballmeister, Deutscher
                    Fu&szlig;ballmeister,
                    Deutscher Fu&szlig;ballmeister - FCB!</i>
                    Es war am Ende ein w&uuml;rdiges Meisterschaftsspiel.
                </p>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_8.jpg"
                    }, {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_9.jpg"
                    }, {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_10.jpg"
                    },

                ]} untertitel="8px" maxWidth={800} indicators={true}/>

                <p>
                    Freuen wir uns also auf die kommende Saison - denn am ersten Spieltag wird es wieder hei&szlig;en:
                    <i>Deutscher Meister wird nur der FCB, nur der FCB, nur der FCB...</i>
                </p>

                <ImageViewer items={[
                    {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_3.jpg"
                    }, {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_12.jpg"
                    }, {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_13.jpg"
                    }, {
                        name: "",
                        description: "FC Bayern München ./. BVB Dortmund",
                        src: "meister2022/20220423_Stadion_26.jpg"
                    },

                ]} untertitel="8px" maxWidth={800} indicators={true}/>


            </article>
            <hr/>

            <article className="articleContent">
                <h4>23.04.2022 - FC Bayern M&uuml;nchen ./. BVB Dortmund</h4>

                <p>
                    Seit jeher ist es das Spiel der Spiele, das Aufeinandertreffen des FC Bayern M&uuml;nchen und
                    Borussia
                    Dortmund. Die Statistik ist dabei klar auf der Seite der Bayern. Es sollte doch gelacht sein, wenn
                    sich nicht heute der 10. Meistertitel in Folge ergeben sollte.
                </p>
                <p>Leider hatte es nicht geklappt, dass wir vom Fanclub hinfahren k&ouml;nnen, doch werden sicher
                    einzelne
                    beim Spiel vor Ort dabei sein. Der Rest wird es im TV verfolgen k&ouml;nnen.</p>
                <p>Also dr&uuml;cken wir die Daumen, dass unser FC Bayern den 10. Titel heute perfekt macht, Lewandowski
                    gegen seinen Lieblingsgegner wieder trifft und wir alle wieder Grund zum feiern haben - wie jedes
                    Jahr eben. ;-)</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>03.03.2022 - Sachspenden f&uuml;r die Kriegsopfer in der Ukraine</h4>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "Sachspenden für die Ukraine",
                        src: "Ukraine2.jpg"
                    },
                    {
                        name: "",
                        description: "Sachspenden für die Ukraine",
                        src: "Ukraine1.jpg"
                    },
                    {
                        name: "",
                        description: "Sachspenden für die Ukraine",
                        src: "Ukraine3.jpg"
                    }

                ]} untertitel="8px" maxWidth={800} indicators={true}/>

                <p>
                    Am 03.03.2022 waren Vertreter vom Vorstand, Thomas Stadlmeier, Andreas Graf und Thomas Mulzer in der
                    Grundschule Hohenfels und haben Sachspenden im Wert von 160 EUR an die Helfer &uuml;bergeben.
                </p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>28.02.2022 - Neues vom Schaukasten</h4>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "Aushang vom 28.02.2022",
                        src: "Schaukasten_2022_02_28.jpg"
                    }

                ]} untertitel="8px" maxWidth={800}/>


                <p>
                    Wir haben beim FC Bayern M&uuml;nchen je 35 Karten angefragt, f&uuml;r die Spiele gegen den FC
                    Augsburg
                    und den
                    BVB Dortmund.
                </p>
                <p>Bitte meldet euch an bei unserem Kartenbeauftragten Stephan Walter per E-Mail:
                    <Link to="Kontakt">karten@fcb-hohenfels.de</Link>, &uuml;ber unsere WhatsApp&copy;-Gruppe oder
                    telefonisch unter
                    0160/95608536.</p>
                <p>Liegen mehr als 35 Anfragen/Spiel vor, muss leider das Los entscheiden.</p>
                <p>Da die Spiele noch nicht terminiert sind, wissen wir leider noch nicht, wann diese statt finden. Wir
                    gehen davon aus, dass die Begegnung mit dem BVB als Top-Spiel eingestuft wird und wahrscheinlich
                    Samstags 18.30 Uhr starten wird. Abfahrt ist wie immer am Keltensaal in Hohenfels mit
                    den &uuml;blichen
                    Zwischenstopps.
                </p>
                <p>Sollten wir keine Karten bekommen, informieren wir euch &uuml;ber eine m&ouml;gliche
                    Ersatzveranstaltung.</p>
                <p>Bitte denkt daran, dass in der Allianz-Arena ein Hygienekonzept gilt, dass zwingend einzuhalten ist.
                    Nachlesen k&ouml;nnt ihr das Hygienekonzept der Allianz-Arena hier:<a
                        href="https://fcbayern.com/de/tickets/hygienekonzept"
                        target="_blank">https://fcbayern.com/de/tickets/hygienekonzept</a></p>

            </article>
            <hr/>

            <article className="articleContent">
                <h4>31.12.2021 - Neues vom Schaukasten</h4>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "Aushang vom 31.12.2021",
                        src: "Schaukasten_2021_12_31.jpg"
                    }

                ]} untertitel="8px" maxWidth={800}/>


                <p>
                    Wir w&uuml;nschen allen Mitgliedern des Fanclubs, sowie allen Hohenfelsern ein gutes neues Jahr -
                    und
                    vor
                    allem bleibt alle gesund.
                </p>
                <p>2022 steht ganz im Zeichen des 20-j&auml;hrigem Bestehen des FC Bayern Fanclub Forellenbachtal
                    Hohenfels.</p>
                <p>Alle Mitglieder des Fanclubs sind aufgerufen, Ideen und Vorschl&auml;ge zur Jubil&auml;umsfeier mit
                    beizutragen.</p>
                <p>Wir hoffen, dass zum April die Lage rund um Corona weiter entspannen wird, so dass wir
                    voraussichtlich im
                    Sommer unser Jubil&auml;um begehen k&ouml;nnen.</p>

            </article>
            <hr/>

            <article className="articleContent">
                <h4>24.12.2021 - Neues vom Schaukasten</h4>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "Aushang vom 24.12.2021",
                        src: "Schaukasten_2021_12_24.jpg"
                    }

                ]} untertitel="8px" maxWidth={800}/>


            </article>
            <hr/>

            <article className="articleContent">
                <h4>06.12.2021 - Der Nikolaus war da...</h4>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "Der Vorstand des Fanclub als Nikolaus bei unseren jüngsten Mitgliedern",
                        src: "nikolaus_bild1.jpg"
                    },
                    {
                        name: "",
                        description: "Der Vorstand des Fanclub als Nikolaus bei unseren jüngsten Mitgliedern",
                        src: "nikolaus_bild2.jpg"
                    },
                    {
                        name: "",
                        description: "Der Vorstand des Fanclub als Nikolaus bei unseren jüngsten Mitgliedern",
                        src: "nikolaus_bild3.jpg"
                    },
                    {
                        name: "",
                        description: "Der Vorstand des Fanclub als Nikolaus bei unseren jüngsten Mitgliedern",
                        src: "nikolaus_bild4.jpg"
                    },
                    {
                        name: "",
                        description: "Der Vorstand des Fanclub als Nikolaus bei unseren jüngsten Mitgliedern",
                        src: "nikolaus_bild5.jpg"
                    },
                    {
                        name: "",
                        description: "Der Vorstand des Fanclub als Nikolaus bei unseren jüngsten Mitgliedern",
                        src: "nikolaus_bild6.jpg"
                    },
                    {
                        name: "",
                        description: "Der Vorstand des Fanclub als Nikolaus bei unseren jüngsten Mitgliedern",
                        src: "nikolaus_bild7.jpg"
                    },
                    {
                        name: "",
                        description: "Der Vorstand des Fanclub als Nikolaus bei unseren jüngsten Mitgliedern",
                        src: "nikolaus_bild8.jpg"
                    }

                ]} untertitel="8px" maxWidth={800} indicators={true}/>
                <p>
                    Heute wurden die j&uuml;ngsten Mitglieder besucht - vom Nikolaus. Der Vorstand organisierte eine
                    kleine
                    Aufmerksamkeit f&uuml;r unsere j&uuml;ngsten Mitglieder. Wie die Bilder zeigen, ist diese gut
                    angekommen.
                </p>

            </article>
            <hr/>

            <article className="articleContent">
                <h4>03.12.2021/13.12.2021 - Blut spenden - und wir vom Fanclub sind dabei</h4>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "Thomas Mulzer beim spenden",
                        src: "fcb-blutspenden1.jpg"
                    },
                    {
                        name: "",
                        description: "Andy beim Spenden",
                        src: "fcb-blutspenden2.jpg"
                    },
                    {
                        name: "",
                        description: "Thomas & Andy beim spenden",
                        src: "fcb-blutspenden3.jpg"
                    },
                    {
                        name: "",
                        description: "Thomas beim spenden",
                        src: "fcb-blutspenden4.jpg"
                    }
                ]} untertitel="8px" maxWidth={800} indicators={true}/>
                <p>
                    Heute war es wieder an der Zeit, den BRK aktiv mit einer Blutspende zu unterst&uuml;tzen.
                    Wir vom FC Bayern Fanclub Forellenbachtal Hohenfels wollen unseren Beitrag dazu leisten. Wir werden
                    hier regelm&auml;&szlig;ig die anstehenden Termine des BRK mit bekannt geben.
                </p>
                <p>Wir appellieren an all unsere Mitglieder, den BRK bei seien Bem&uuml;hungen um zahlreiche Blutspenden
                    tatkr&auml;ftig zu unterst&uuml;tzen. Vielleicht kommt ja auch mal der BRK wieder nach Hohenfels mit
                    eigenen
                    Terminen.</p>
                <p>Unser erster Schriftf&uuml;hrer Thomas, schon ein alter Hase beim Blut spenden, war dabei. F&uuml;r
                    Thomas war
                    es schon seine 12 Blutspende.</p>
                <p>Also, kommt mit zur n&auml;chsten Blutspende und macht mit. </p>
                <p>Update 13.12.2021 - Auch Thomas und Andy nutzten die Chance Gutes zu tun und waren Blut spenden.</p>
            </article>
            <hr/>

            <article className="articleContent">

                <ImageViewer items={[
                    {
                        name: "",
                        description: "sky Übertragung vom 04.12.2021",
                        src: "IMG_6124.JPG"
                    }

                ]} untertitel="8px" maxWidth={800}/>

                <h5>04.12.2021 18:30 Uhr - Der Klassiker im deutschen Fu&szlig;ball</h5>
                <p>
                    Da war es wieder soweit - der FC Bayern M&uuml;nchen reiste nach Dortmund zum Gipfeltreffen der
                    ersten
                    Bundesliga, der erste gegen den zweiten in der Tabelle, das Aufeinandertreffen der Torj&auml;ger
                    Robert Lewandowski vs Erling Harland, der FC Bayern M&uuml;nchen gegen Borussia Dortmund - das
                    Aufeinandertreffen der Dauerrivalen, Schwarz-Gelb gegen die Roten aus M&uuml;nchen.
                </p>
                <p>
                    Die Bayern haben Ansto&szlig; und das Spiel geht rasant los. Und in der 5. Minute bringt Julian
                    Brandt den
                    BVB mit 1:0 in F&uuml;hrung, die Borussia Tabellenf&uuml;hrer. Doch Bayern w&auml;re nicht Bayern,
                    w&uuml;rde nicht die
                    Antwort auf dem Fu&szlig;e folgen. In der 9. Minute profitiert Robert Lewandowski von einer
                    Auseinandersetzung zwischen Thomas M&uuml;ller und Hummels. Zack und schon stehts 1:1 - alles wieder
                    auf
                    Anfang.
                </p>
                <div className="spieltag">
                    <h4>14. Spieltag - Samstag, 04.12.2021 (18:30)</h4>
                    <p>Borussia Dortmund - FC Bayern M&uuml;nchen</p>
                    <b className="result">2 : 3</b>
                </div>
                <p>Es geht rasant weiter - beide Manschaften sind mit Vollgas unterwegs.</p>
                <p>
                    Es braucht dann bis in die 44. Minute, bis es dann wieder im Kasten der Dortmunder klingelt durch
                    das n&auml;chste Tor durch Kingsley Coman, der von der Hektik in der Dortmunder Abwehr am Ende
                    profitiert.
                    Bis zur 44. Minute liefern sich beide Manschaften einen regelrechten offenen Schlagabtausch.
                    Dortmund gewinnt mehr Zweik&auml;mpfe und auf Erling Harland d&uuml;rfen die Bayern weiter gut
                    aufpassen.
                    Am Ende geht es f&uuml;r die M&uuml;nchner verdient mit 1:2 in die Pause.
                </p>
                <p>Weiter geht's mit der zweiten Halbzeit und Dayot Upamecano schein noch gedanklich beim RB Leipzig zu
                    sein, in dem er Erling Harland einl&auml;dt zum Ausgleich in der 47. Minute.</p>
                <p>Trauriges Kapitel im Fu&szlig;ball - wenn ein Spieler das Feld auf der Trage verl&auml;sst. Dayot
                    Upamecano und
                    Julian Brandt begegnen sich in Kopfballduell in der Luft - es ist das Aus aber f&uuml;r Julian
                    Brandt,
                    der in der 66. Minute vom Platz getragen wird. Gute Besserung an Julian Brandt auf diesem Wege.</p>
                <p>69. Minute verdreht sich Lucas Hernandez den Fu&szlig;. Hernandez wird au&szlig;erhalb des
                    Spielfeldes
                    behandelt.</p>
                <p>Zwischenzeitlich erkundigt sich der Dortmunder Trainer, wie lange wohl Harland noch durchhalten
                    wird.</p>
                <p>73. Minute - Hernandez muss ausgewechselt werden.</p>
                <p>Es bleibt aufregend. Mats Hummels verursachte einen Efl-Meter, sicherlich nicht ganz unumstritten.
                    Robert Lewandowski nimmt sich den Ball und schie&szlig;t sicher zum 2:3 f&uuml;r die Bayern.</p>
                <p>Die letzten 10 Minuten laufen...</p>

                <p>In den letzten 10 Minuten ist dann viel klein, klein - also kommen noch mal 10 Minuten Nachspielzeit
                    oben drauf. Mal schauen, was da noch passiert. In der 96. Minute hat Robert Lewandowski mit einem
                    Freisto&szlig; noch mal die Chance das Ergebnis zu verbessern.</p>
                <p>2 Minuten vor Schluss - Eckball f&uuml;r Dortmund, aber sauber gekl&auml;rt, es folgt ein Konter von
                    Tolisso
                    auf das leere Dortmunder Tor - der Schuss geht aber 2 Meter rechts vorbei.</p>
                <p>Am Ende gewinnt der FC Bayern 2:3 in Dortmund in einem gro&szlig;artigen Spiel der Giganten der
                    ersten
                    Bundesliga.
                    Diskussionen wird es sicher zum Elf-Meter geben - aber das ist nun mal Fu&szlig;ball.</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>28.11.2021 - Neues vom Schaukasten</h4>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "Aushang vom 28.11.2021",
                        src: "Schaukasten_2021_11_28.jpg"
                    }

                ]} untertitel="8px" maxWidth={800}/>

                <h5>Informationen aus der letzten Vorstandsitzung</h5>
                <p>
                    Im Schaukasten findet ihr eine kurze Zusammenfassung der letzten Vorstandschaftssitzung mit einer
                    Stellungnahme zu den M&ouml;glichkeiten der Weihnachtsfeier, dem Besuch im Stadion und der Planung
                    zum Faschingsfest.
                </p>
                <p>
                    Noch einmal einen dringenden Appel an alle Mitglieder der Fanclubs.
                    Damit ihr alle schnell und kompfortable informiert werden k&ouml;nnt, solltet ihr euch auf dieser
                    Webseite
                    registrieren und so euer Benutzerkonto aktivieren.
                    Wenn Ihr Fragen zur Registrierung habt, wendet euch an unsere Schriftf&uuml;hrer per Email unter
                    mitgliedschaft@fcb-hohenfels.de.
                </p>
                <p>
                    Was war noch so los?
                    <br/>
                    Nun, unser "Vorzeigefu&szlig;baller" Joshua Kimmich hat noch Bedenken mit einer Corona Impfung.
                    Bedauerlicherweise schadet er damit mehr dem FC Bayern als er es wohl selbst gedacht hatte.
                    Daneben hat auch die Nationalmanschaft das Nachsehen, indem Kimmich ausf&auml;llt.
                    <br/>
                    Den 12. Spieltag verliert der FC Bayern das bayrische Derby gegen den SC Augsburg mit 1:2.
                    Den 13. Spieltag m&uuml;ht sich der FC Bayern M&uuml;nchen gegen die Arminia aus Bielefeld und
                    erk&auml;mpft sich
                    ein mageres 1:0 und somit in das Duell gegen den BVB als Tabellenf&uuml;hrer.
                    <br/>
                    Dr&uuml;cken wir die Daumen, dass der 04.12.2021 wieder ein Tag zum jubeln wird.
                    Die Partie gegen Borussia Dortmund startet um 18:30 Uhr und wird im sky Sport
                    Bundesliga &uuml;bertragen.
                </p>

            </article>
            <hr/>

            <article className="articleContent">
                <h4>06.11.2021 - Bundesliga 11. Spieltag</h4>
                <div className="spieltag">
                    <h4>11. Spieltag - Samstag, 06.11.2021 (15:30)</h4>
                    <p>FC Bayern M&uuml;nchen - SC Freiburg</p>
                    <b className="result">2 : 1</b>
                </div>
                <div>
                </div>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>02.11.2021 - Champions League</h4>
                <div className="spieltag">
                    <h4>Vorrunde 4. Spieltag - Dienstag, 02.11.2021 (21:00)</h4>
                    <p>FC Bayern M&uuml;nchen - Benefica Lissabon</p>
                    <b className="result">5 : 2</b>
                </div>
                <div>
                    <p>Beide Mannschaften starten druckvoll und versuchen jeweils das fr&uuml;he Tor zu machen. In der
                        15.
                        Minute ist es dann so weit, nachdem Pavard zur Ecke kl&auml;rte. Zack war das 0:1 da. Aber
                        aufgrund
                        eines knappen Abseits z&auml;hlte das Tor nicht. Die Bayern sind vorgewarnt.</p>
                    <p>Der schnelle Konter der Bayern folgte prompt. Ecke Kimmich - doch die Verwertung lie&szlig; zu
                        W&uuml;nschen
                        &uuml;brig.</p>
                    <p>Kingsley Coman flangt zu Robert Lewandowski der dann in seimem 100. Champions League Spiel zum
                        1:0
                        eink&ouml;pft. In der 32. Minute legt Robert Lewandowski f&uuml;r Serge Gnabry zum 2:0 auf.</p>
                    <p>Und weil die Aufmerksamkeit wohl wieder nachgelassen hat, kann Morato f&uuml;r Benefica Lissabon
                        zum
                        2:1 den Anschlusstreffer erzielen und den Bayern das erste Gegentor im laufenden Wettbewerb
                        verpassen.</p>
                    <p>Nach einem von Robert Lewandowski erstaunlicherweise nicht verwandelten Elf-Meter gehen beide
                        Mannschaften in die Halbzeitpause.</p>
                    <p>Die zweite Halbzeit ist keine 4 Minuten alt und da klingelt es schon wieder bei den Portogiesen:
                        Leroy San&eacute; erzielt in der 49. Minute f&uuml;r die M&uuml;nchener mit einem sehenswerten
                        Schu&szlig; zum
                        3:1</p>
                    <p>Nach einem starken Antritt von Leroy San&eacute; gibt dieser auf den heraneilenden Lewandowski
                        r&uuml;ber und dieser verwandelt einkalt zum 4:1 in der 61. Minute. </p>
                    <p>So sch&ouml;n das Ergebnis bisher ausschaut, so hat doch auch das Spiel der Bayern seine
                        Defizite. In
                        der 74. Minute verk&uuml;rzen die Portogiesen durch ein Tor von Darwin auf 4:2.</p>
                    <p>In der 84. Minute hatte dann Manuel Neuer seinen Auftritt: durch einen weiten Abschlag erreicht
                        der Ball Robert Lewandowski, der seinen dritten Treffer zum 5:2 an diesem Abend macht.</p>


                    <p>Der FC Bayern M&uuml;nchen gewinnt am Ende gegen Benefica Lissabon
                        verdient stets das Spiel bestimmend mit 5:2 durch die Tore
                        von Robert Lewandowski (26./61./84. Minute), Serge Gnabry (32. Minute)
                        Leroy San&eacute; (49. Minute). Die Tore f&uuml;r Benefica Lissabon schossen Morato (38. Minute)
                        und
                        Darwin (74. Minute).</p>
                    <p>Am Samstag den 6. November trifft dann der FC Bayern M&uuml;nchen auf die Mannschaft des SC
                        Freiburg um 15:30
                        Uhr
                        an.</p>

                </div>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>Arbeitseinsatz am Container</h4>
                <p>Am 23.10.2021 trafen sich Georg und Stefan Spangler, Stephan Walter, Dietmar Walter und Thomas
                    Stadlmeier, um gemeinsam das Dach am Container zu reparieren. Stefan Spangler war sichtlich froh,
                    mal wieder bei der Gelegenheit den Bulldog bewegen zu k&ouml;nnen.</p>
                <ImageViewer path="./events/images/2021_10_23_Arbeitseinsatz_am_Container/" items={[
                    {
                        name: "",
                        description: "Arbeitseinsatz am Container",
                        src: "Bild2.jpg"
                    }

                ]} untertitel="8px" maxWidth={800}/>

                <h5>Dachinstandsetzung am Container</h5>
                <p>
                    Mit Dietmar Walter war auch die fachkundige Unterst&uuml;tzung vor Ort gesichert, w&auml;hrend
                    Thomas
                    Stadlmeier f&uuml;r das leibliche Wohl der flei&szlig;igen Handwerker sorgte.
                    Nach 3 Stunden intensiver Arbeit war es dann vollbracht und so bleibt zu hoffen, dass nun wieder
                    unsere Utensilien wieder trocken und sicher gelagert sind.
                    Vielen Dank an alle Beteiligten f&uuml;r ihre tatkr&auml;ftige Unterst&uuml;tzung.
                </p>
                <p>Weitere Bilder findet ihr im Bilder-/Veranstaltungsarchiv.</p>

            </article>
            <hr/>

            <article className="articleContent">
                <h4>30.10.2021 - Bundesliga 10. Spieltag</h4>
                <div className="spieltag">
                    <h4>10. Spieltag - Samstag, 30.10.2021 (15:30)</h4>
                    <p>1. FC Union Berlin - FC Bayern M&uuml;nchen</p>
                    <b className="result">2 : 5</b>
                </div>
                <div>
                    <p>Nach dem Pokal-Aus am 27.10. gegen Borussia M&ouml;nchengladbach findet der FC Bayern
                        M&uuml;nchen die
                        richtige Antwort im Ligaspiel gegen den 1. FC Union Berlin. <i>Kopf hoch und wieder spielen und
                            Tore schie&szlig;en</i> - so haben sich die M&uuml;nchener gegen die doch recht starken
                        "Eisernen"
                        behaupten k&ouml;nnen.</p>

                    <p>Der FC Bayern M&uuml;nchen gewinnt am Ende trotz der 2 Gegentore gegen den 1. FC Union Berlin
                        verdient mit 2:5 durch die Tore
                        von Robert Lewandowski (15./23. Minute),
                        Leroy San&eacute; (35. Minute), Kingsley Coman (61. Minute) und Thomas M&uuml;ller (79.
                        Minute)</p>
                    <p>Am Samstag den 6. November trifft dann der FC Bayern M&uuml;nchen auf die Mannschaft des SC
                        Freiburg um 15:30
                        Uhr
                        an.</p>
                </div>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>27.10.2021 - DFB Pokal 2. Runde</h4>
                <div className="spieltag">
                    <h4>2. Runde DFB Pokal - Samstag, 27.10.2021 (20:45)</h4>
                    <p>Borussia M&ouml;nchengladbach - FC Bayern M&uuml;nchen</p>
                    <b className="result">5 : 0</b>
                </div>
                <div>

                    <p>Der FC Bayern M&uuml;nchen verliert gegen Borussia M&ouml;nchengladbach mit 5:0 und scheidet
                        damit zum
                        zweiten Mal in Folge in der 2. Runde aus dem Wettbewerb um den DFB Pokal aus.</p>
                    <p>Im &Uuml;brigen bleibt anzumerken, dass nahezu alle bayrischen Vereine im aktuellen DFB Pokal
                        Wettbewerb ausgeschieden sind. Lediglich die M&uuml;nchener L&ouml;wen halten noch das
                        Bayernwappen
                        oben.</p>
                    <p>Tragisch auch das Ausscheiden des SSV Jahn Regensburg, die in einem packenden Pokalkrimi einen
                        0:2 R&uuml;ckstand zu einem 3:2 drehen und in der Nachspielzeit der Verl&auml;ngerung doch noch
                        zum
                        Elf-Meter-Schie&szlig;en "gezwungen" werden. Hier unterliegt der Jahn dann allerdings durch zwei
                        verschossene Elf-Meter mit 5:7 gegen den FC Hansa Rostock.</p>
                    <p>30. Oktober tritt dann der FC Bayern M&uuml;nchen gegen den 1. FC Union Berlin in der Bundesliga
                        an.</p>
                </div>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>23.10.2021 - Bundesliga 9. Spieltag</h4>
                <div className="spieltag">
                    <h4>9. Spieltag - Samstag, 23.10.2021 (15:30)</h4>
                    <p>FC Bayern M&uuml;nchen - TSG Hoffenheim</p>
                    <b className="result">4 : 0</b>
                </div>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>17.10.2021 - Bundesliga 8. Spieltag</h4>
                <div className="spieltag">
                    <h4>8. Spieltag - Samstag, 17.10.2021 (15:30)</h4>
                    <p>Bayer 04 Leverkusen - FC Bayern M&uuml;nchen</p>
                    <b className="result">1 : 5</b>
                </div>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>Neues vom Schaukasten</h4>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "Aushang vom 16.10.2021",
                        src: "Schaukasten_2021_10_16.jpg"
                    }

                ]} untertitel="8px" maxWidth={800}/>

                <h5>Offlinebericht zur Jahreshauptversammlung und zum Grillfest</h5>
                <p>
                    Im Schaukasten findet ihr den "Offline"-Bericht zur unserer Jahreshauptversammlung und zum
                    Grillfest.
                    Schaut es euch mal an - Feedback und Anregungen sind immer Willkommen.
                </p>

            </article>
            <hr/>

            <article className="articleContent">
                <h4>03.10.2021 - Bundesliga 7. Spieltag</h4>
                <div className="spieltag">
                    <h4>7. Spieltag - Sonntag, 03.10.2021 (17:30)</h4>
                    <p>FC Bayern M&uuml;nchen - Eintracht Frankfurt</p>
                    <b className="result">1 : 2</b>
                </div>
                <div>
                    <ReactPlayer url='./events/images/2021_10_03_FCB_SGE/video01.mp4' controls={true} loop={true}/>
                    <p>Der FC Bayern M&uuml;nchen empfing heute am 7. Spieltag der Bundesliga vor 25000 Zuschauer
                        die Mannschaft Eintracht Frankfurt. </p>
                    <p>Die Rahmenbedingungen konnten heute nicht besser sein - sonnige 22°C, freie Fahrt auf der
                        Autobahn, optimaler Parkplatz im P1, ein guter Platz im Block 116 - Fu&szlig;ballherz, was
                        willst du
                        mehr.</p>
                    <p>Also man k&ouml;nnte sagen, von den bayrischen Vereinen hatte wohl der SSV Jahn Regensburg das
                        beste
                        Ergebnis erziehlt, in dem sie am Samstag gegen den Karlsruher SC einen 0:2 R&uuml;ckstand am
                        Ende
                        legalisierten und das Spiel mit 2:2 beendeten.
                        Sicher haben sich die Jahn-Fans hier auch ordentlich die Augen gerieben, hatten doch viele davon
                        getr&auml;umt, dass der Jahn die 20 Punkte vor der L&auml;nderspielpause voll macht. Leider
                        wurde nichts
                        daraus.
                    </p>
                    <p>Um so mehr durfte man sich heute aber auch in M&uuml;nchen die Augen gewaltig reiben, als es nach
                        95
                        Minuten nur 1:2 f&uuml;r den FC Bayern M&uuml;nchen hie&szlig;.
                        Ich habe mich im Spiel immer wieder gefragt, wo die Mannschaft geblieben ist, die gegen Dynamo
                        Kiev 5:0 gewonnen hatte?
                    </p>
                    <ImageViewer path="./events/images/2021_10_03_FCB_SGE/" items={[{
                        name: "",
                        description: "",
                        src: "bild01.jpg"
                    },
                        // {
                        //     name: "",
                        //     description: "",
                        //     src: "video01.mp4"
                        // },
                        {
                            name: "",
                            description: "",
                            src: "bild02.jpg"
                        },
                        {
                            name: "",
                            description: "",
                            src: "bild03.jpg"
                        },
                    ]} untertitel="8px" maxWidth={800} indicators={true}/>
                    <p>
                        Die Bayern haben gut begonnen, es schien, als ob das erste Tor fr&uuml;h fiele, nur eine Frage
                        der
                        Zeit w&auml;re, doch halten die
                        Frankfurter ordentlich dagegen.
                        In der 29. Minute ist Leon Goretzka zur Stelle nach einem Zuspiel von Robert Lewandowski - 1:0
                        f&uuml;r die Bayern.
                        2 Minuten sp&auml;ter h&auml;tte es 2:0 hei&szlig;en k&ouml;nnen, h&auml;tte der Frankfurter
                        Keeper den Schu&szlig; von Leroy
                        Sané nicht zur Ecke gekl&auml;rt.
                    </p>
                    <p>In der 32. Minute machten die Frankfurter mit Martin Hinteregger klar, dass sie sich hier nicht
                        verstecken wollen, 1:1 - der Ausgleich ist da.
                        In der ersten Halbzeit passierte dann nicht mehr viel.</p>
                    <p>Die zweite Halbzeit beginnt und es sollte noch schlimmer werden. Die Bayern schaffen es nicht,
                        den Ball im generischen Tor zu versenken.
                        Was auff&auml;llt, der Druck fehlte, der Zug zum Tor, der wirkliche Wille - irgendwie schien bei
                        den
                        Bayern die Luft raus zu sein. Immer wieder versuchen die Bayern direkt durch die Mitte, fast mit
                        der Brechstange den Sieg zu erzwingen, aber der Spielwitz fehlte.</p>
                    <p>In der 83. Minute passiert, was passieren musste -Filip Kostic bringt die Eintracht mit 1:2 in
                        F&uuml;hrung.</p>
                    <p>Die Bayern schaffen es nicht mehr, mindestens einen Ausgleich zu erziehlen und so wenigstens
                        einen Punkt zu retten.</p>
                    <p>Der FC Bayern M&uuml;nchen verlieren am Ende mit 1:2 gegen die Manschaft aus Frankfurt.
                        Dem Statement vom Trainer Julian Nagesmann ist nichts hinzuzuf&uuml;gen: diese Niederlage war
                        vermeidbar gewesen.
                        Aber auch eine andere Tatsache besteht: zum Spielen geh&ouml;rt Verlieren dazu. Es ist die erste
                        Niederlage - davon geht die Welt nicht unter.
                        Und Deutscher Meister wird am Ende eh...
                    </p>
                    <p>Am Sonntag den 17. Oktober tritt dann der FC Bayern M&uuml;nchen in Leverkusen gegen die Werkself
                        Bayer 04 um 15:30
                        Uhr
                        an.</p>

                </div>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>29.09.2021 - Champions League Vorrunde 2. Spieltag</h4>
                <div className="spieltag">
                    <h4>Champions League - Mittwoch, 29.09.2021 (21:00)</h4>
                    <p>FC Bayern M&uuml;nchen - Dynamo Kiev</p>
                    <b className="result">5 : 0</b>
                </div>
                <div>
                    <p>Der FC Bayern M&uuml;nchen empfing heute im zweiten Vorrundenspiel Dynamo Kiev. 25000 Zuschauer
                        waren in die Allianzarena gekommen. </p>
                    <p>&Auml;rgerlich, dass man bald zig-Abo's haben muss, um jedes Spiel Daheim mit verfolgen zu
                        k&ouml;nnen.
                        Hoffentlich ist das mal bald wieder in einer Hand. Aber der Gratis-Monat bei DAZN hat es denn
                        doch erm&ouml;glicht "live" dabei zu sein.</p>
                    <p>Die Bayern starten gut in das Spiel und ehe 12 Minuten um waren, hat kein geringerer als Robert
                        Lewandowski einen Hand-Elfmeter souver&auml;n zum 1:0 f&uuml;r den FC Bayern M&uuml;nchen
                        verwandelt.
                    </p>
                    <p>Mit 62% Ballbesitz und 65% der gewonnen Zweik&auml;mpfe machen die Bayern weiter massiv
                        Druck.
                        Und so dauerte es nicht lange bis dann wieder Robert Lewandowski in der 27. Minute zum 2:0
                        einschenkt.
                    </p>
                    <p>
                        In der 35. Minute zieht Leroy San&eacute; ab und verpasste das Tor nur knapp, denn der Ball
                        prallte am Pfosten der Kiever ab. Es war eine Sache von Millimetern und vor allem ein Klasse
                        Torschu&szlig; von San&eacute;.
                    </p>
                    <p>Zwar hat Kiev in der 41. Minute noch eine Riesenchance, verpasst es aber, diese in ein Tor
                        umzuwandeln. 2:0 ist dann das Halbzeitergebnis.</p>
                    <p>Die zweite Halbzeit beginnt und die Bayern sind weiter auf Sieg aus. Wer noch das Spiel vom SSV
                        Jahn Regensburg gegen Wismut Aue in Erinnerung hat, der wei&szlig; zu gut, dass ein 2:0 zwar ein
                        sch&ouml;nes Ergebnis ist, aber eben auch, dass das Blatt sich sehr schnell drehen kann.</p>
                    <p>Und dessen sind sich auch die Spieler des FC Bayern bewu&szlig;t und machen nach einem enorm
                        starken
                        Antritt von Serge Gnabry in der 68. Minute das 3:0.</p>
                    <p>Und dann dauert es keine 6 Minuten, bis dann Leroy San&eacute; wieder mit einem sehr sehenswerten
                        Schu&szlig; den Torwart von Kiev sauber verl&auml;dt und das 4:0 macht. Nach dem Spiel befragt
                        sagte
                        Leroy San&eacute;, dass der Schu&szlig; so gar nicht beabsichtigt war - am Ende ein
                        lohnenswerter verkorkster Schu&szlig;.
                        Doch die Bayern lassen nicht nach und machen weiter Druck. Nach einer Herreingabe von rechts
                        k&ouml;pft Eric Maxim Choupo-Moting in der 87. Minute zum 5:0.</p>
                    <p>Die Spielzeit ist abgelaufen - 2 Minuten Nachspielzeit werden angezeigt.</p>
                    <p>Der FC Bayern M&uuml;nchen gewinnt mehr als verdient mit 5:0 gegen die Manschaft von Dynamo Kiev
                        durch die Tore
                        von Robert Lewandowski (12./27. Minute), Serge Gnabry (68. Minute)
                        Leroy San&eacute; (74. Minute) und Eric Maxim Choupo-Moting (87. Minute)</p>
                    <p>Am Mittwoch den 20. Oktober tritt dann der FC Bayern M&uuml;nchen gegen Benfica Lissabon um 21:00
                        Uhr
                        an.</p>
                </div>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>25.09.2021 - Jahreshauptversammlung und Grillfest</h4>
                <p>Bei sp&auml;tsommerlichem Wetter hatte der erste
                    Vorstand Thomas Stadlmeier zur Jahreshauptversammlung in's
                    Sportheim an der Haarziegelh&uuml;tte eingeladen und viele Fanclubmitglieder sind der Einladung
                    gerne
                    gefolgt. Als besonderen Gast durfte Thomas den ersten B&uuml;rgermeister von Hohenfels, Christian
                    Graf
                    begr&uuml;&szlig;en.</p>
                <p>Nach der obligatorischen
                    Corona-Verhaltensunterweisung gedachten alle anwesenden Mitglieder mit
                    einer Schweigeminute den verstorbenen Mitgliedern. Sodann zog Thomas Stadlmeier ein
                    Resum&eacute; auf die
                    vergangene Zeit und musste feststellen, dass nahezu alle geplanten Veranstaltungen, wie auch die
                    gemeinsamen Fanclubfahrten zu Spielen des FC Bayern M&uuml;nchen Corona bedingt ausgefallen
                    sind.</p>
                <p>Ebenso berichtete Thomas, dass der Fanclub zu zwei
                    runden Geburtstagen und zu einer Hochzeit
                    gratulieren durfte.</p>
                <p>Im Anschluss gab der erste Kassierer, Lukas Ring,
                    einen &Uuml;berblick zur finanziellen Situation des
                    Vereins. Im Ergebnis lie&szlig; sich feststellen, dass gut gewirtschaftet wurde und die Kasse
                    ordnungsgem&auml;&szlig; gef&uuml;hrt wurde, wie durch die Kassenpr&uuml;fung von Irene Walter
                    best&auml;tigt wurde. </p>
                <p>Im weiteren berichtete Thomas Stadlmeier, dass die
                    Situation mit den gemeinsamen Fu&szlig;ballfahrten sich
                    in naher Zukunft wohl bald weiter entspannen wird. So wird es bald m&ouml;glich sein, dass Fanclubs
                    wieder mehr Karten bestellen werden k&ouml;nnen und dass wohl in diesem Zusammenhang die
                    Personalisierung der Stadionbesuche mehr in den Zust&auml;ndigkeitsbereich der Fanclubs sich
                    verlagern
                    wird.</p>
                <ImageViewer path="./events/images/2021_21_09_25_JHV_Grillfest/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild02.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild06.jpg"
                    }

                ]} untertitel="8px" maxWidth={400} indicators={true}/>
                <p>Thomas Stadlmeier stellte noch eimal die Entwicklung
                    der Vereinshomepage vor und machte in diesem
                    Zusammenhang noch einmal deutlich, wie wichtig es ist, dass der Verein mehr auf die digitalen
                    Angebote in Zukunft setzen wird, da es allein mit der aktuellen Einladung zu einer logistischen und
                    auch finanziellen Herausforderung kam, wollte die Deutsche Post die Einladungen nicht mehr als
                    Infopost zustellen. Alle Fanclubmitglieder sind aufgerufen, sich im Mitgliederbereich der
                    Fanclubwebseite zu registrieren und das Onlineangebot des Vereins aktiv zu nutzen. Thomas wies auch
                    darauf hin, dass der Verein und seine Angebote sich ma&szlig;geblich nach den Bed&uuml;rfnissen und
                    W&uuml;nschen
                    der Mitglieder richten wird und jeder aufgefordert ist, seine Ideen und Vorstellungen aktiv mit
                    einzubringen - z.B. &uuml;ber das Kontaktformular der Webseite.</p>
                <p>Am Ende gab es einen kurzen Ausblick auf das
                    kommende Jahr und die Aufforderungen, Ideen zu sammeln,
                    anl&auml;&szlig;lich des 20-j&auml;hrigen Vereinsjubil&auml;ums, welches im kommenden Jahr
                    geb&uuml;hrend gefeiert werden soll.</p>
                <p>Auch Christian Graf, erster B&uuml;rgermeister von
                    Hohenfels, richtete noch ein paar Gru&szlig;worte an den Verein
                    und die anwesenden Mitglieder. Er stellte heraus, wie wichtig das aktive Vereinsleben auch f&uuml;r
                    die
                    Gemeinde ist. Auch er dr&uuml;ckte sein Bedauern dar&uuml;ber aus, dass Corona bedingt zahlreiche
                    Vereinsfeste
                    nicht stattfinden durften, zeigte sich jedoch zuversichtlich, dass 2022 sicher wieder mehr
                    m&ouml;glich
                    sein wird.</p>
                <p>Im Anschluss an die Jahreshauptversammlung trafen
                    sich die anwesenden Mitglieder zum gemeinsammen
                    Grillfest am Sportplatz des TSV Hohenfels.</p>
                <p>Georg Spangler und Stephan Walter k&uuml;mmerten
                    sich darum, dass das Grillgut (Halsfleich, Bauch und
                    W&uuml;rst'l) gut durchgegart wurden. Insbesondere Georg Spangler kam dabei sichtlich ins Schwitzen,
                    was
                    an der Kopfoberfl&auml;che mehr oder weniger sichtbar wurde.</p>
                <ImageViewer path="./events/images/2021_21_09_25_JHV_Grillfest/" items={[
                    {
                        name: "",
                        description: "",
                        src: "Bild07.jpg"
                    },
                    {
                        name: "",
                        description: "",
                        src: "Bild24.jpg"
                    }

                ]} untertitel="8px" maxWidth={400} indicators={true}/>
                <p>Zu fortgeschrittener Stunde versuchten Stephan
                    Walter, Andreas Graf, Thomas Mulzer und Stefan
                    Spangler an Bundesligarekorden im Elfmeterschie&szlig;en unter den kritischen Blicken des ersten
                    Vorstandes Thomas Stadlmeier anzukn&uuml;pfen.</p>
                <p>Abschlie&szlig;end bleibt allen Beteiligten zu
                    Danken, f&uuml;r die tatkr&auml;ftige Unterst&uuml;tzung bei der Planung
                    und Realisierung der Veranstalltung. Besonderen Dank gilt allen Mitgliedern, die durch ihre
                    zahlreichen Salate und Kuchen f&uuml;r das leibliche Wohl mit sorgten.</p>
                <p>Weitere Bilder der Veranstaltung findet ihr in der Bildergalerie.</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>24.09.2021 - 6. Spieltag</h4>
                <div className="spieltag">
                    <h4>6. Spieltag - Samstag, 24.09.2021 (20:30)</h4>
                    <p>SpVgg Greuther F&uuml;rth - FC Bayern M&uuml;nchen</p>
                    <b className="result">1 : 3</b>
                </div>

            </article>
            <hr/>

            <article className="articleContent">
                <h4>18.09.2021 - 5. Spieltag</h4>
                <div className="spieltag">
                    <h4>5. Spieltag - Samstag, 18.09.2021 (15:30)</h4>
                    <p>FC Bayern M&uuml;nchen - VfL Bochum</p>
                    <b className="result">7 : 0</b>
                </div>
                <div>
                    <p>Der FC Bayern M&uuml;nchen empfing heute die Mannschaft des VfL Bochum. Die Bayern
                        pr&auml;sentierten sich
                        bei sonnigen 20° C im Wies'n-Outfit. Das Spielflott an, wobei Robert Lewandowski sein schnelles
                        Tor nicht wie geplant realisieren konnte. Der VfL hat indes fr&uuml;h zu tun, um sich dem Druck
                        der
                        Bayern zu entziehen. </p>
                    <p>Dann kommt es durch ein Foul an Gnabry zu einem Freisto&szlig;, der von Leroy
                        San&eacute; ausgef&uuml;hrt wurde und
                        in der 17. Minute zur 1:0 F&uuml;hrung f&uuml;hrte. Der FC Bayern l&auml;sst nicht locker. In
                        der 21. Minute
                        ist Lewandowski noch etwas zu ungenau. Auch Gnabry schafft es in der 24. Minute nicht, das Tor
                        zu realisieren.</p>
                    <p>Der einst so gescholtene Leroy San&eacute; nimmt immer mehr Fahrt auf und kommt im Spiel immer
                        mehr an.
                        So ist es dann nur noch logisch, dass es in der 27. Minute durch Joshua Kimmich zum 2:0 nach
                        Zuspiel von Leroy San&eacute; kam. In der 29. Minute verpasste Robert Lewandowski wieder nur
                        knapp das
                        Tor, ebenso wie kurz drauf San&eacute; auch das Nachsehen noch hatte.</p>
                    <p>In der 32. Minute platziert Serge Gnabry den Ball im Tor der Bochumer zum 3:0 nach Zuspiel von
                        Thomas M&uuml;ller ab.</p>
                    <p>Und als ob es nicht schon genug Tore w&auml;ren, hilft der VfL Bochum mit einem Eigentor durch
                        Lampropoulos noch einmal nach, um die Torebilanz der Bayern zu verbessern - Eigentor in der 43.
                        Minute zur Halbzeitf&uuml;hrung des FC Bayern M&uuml;nchens.</p>
                    <p>In der zweiten Halbzeit schafft es dann Robert Lewandowski dann doch noch, den Ball im Tor der
                        Bochumer in der 61. Minute zum sagenhaften 5:0 zu versenken. Nicht, dass es nicht zum Sieg
                        reichen w&uuml;rde, nein, 4 Minuten sp&auml;ter verwandelte Joshua Kimmich seinen Treffer zum
                        6:0.
                        Zugleich ist es Kimmichs erster Doppelpack in einem Bundesligaspiel.</p>
                    <p>In der 68. Minute wurde dann Robert Lewandowski geschont und Eric Maxim Choupo-Moting betrat den
                        gr&uuml;nen Rasen zum Tore schie&szlig;en. Dieser lies sich nicht lange bitten und schoss das
                        7:0 f&uuml;r die
                        Bayern in der 79. Minute. So souver&auml;n wie es klingt, war es dann aller dings doch nicht,
                        denn
                        der Bochumer Keeper hatte zuvor ordentlich gegen gehalten: erst Schuss von Thomas M&uuml;ller
                        aus
                        sage und schreibe 7 Metern Entfernung und dann zwei aufeinander folgende Sch&uuml;sse von
                        Choupo-Moting. Erst beim dritten Schuss war das Ergebnis unausweichlich.</p>
                    <p>Wer jetzt meinte, in den letzten 10 Minuten passiert hier nix mehr, der irrte gewaltig. Thomas
                        M&uuml;ller legte in der 87. Minute noch einmal ab zum 8:0, doch stand dieser leider im Abseits,
                        so
                        dass den Bochumern dieser Treffer erspart blieb.</p>
                    <p>F&uuml;r den VfL Bochum die h&ouml;chte Vereinsniederlage - f&uuml;r den FC Bayern M&uuml;nchen
                        ein weiterer Schritt
                        zur 10. Meisterschaft in Folge.</p>
                    <p>Der FC Bayern M&uuml;nchen gewinnt mehr als verdient mit 7:0 gegen den VfL Bochum durch die Tore
                        von
                        Leroy San&eacute; (17. Minute), Josuha Kimmich (27./65. Minute), Serge Gnabry (17. Minute),
                        Vasilios
                        Lampropoulos (43. Minute, ET), Robert Lewandowski (61. Minute) und Eric Maxim Choupo-Moting (79.
                        Minute)</p>
                    <p>Am 24.09.2021 um 20:30 Uhr trifft dann der FC Bayern M&uuml;nchen auf die Spielvereinigung
                        Greuther
                        F&uuml;rth.</p>
                </div>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>18. Auftaktsieg in Folge f&uuml;r die M&uuml;nchener</h4>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "Bildquelle: amazon.prime Übertragung vom 14.09.2021",
                        src: "CL_Barca_FCB_2021_09_14.png"
                    }

                ]} untertitel="8px" maxWidth={800}/>

                <p>
                    Der FC Bayern M&uuml;nchen gewinnt sein Auftaktspiel gegen den FC Barcelona durrch die Tore von
                    Thomas
                    M&uuml;ller (34. Minute) und Robert Lewandowski (56. und 85. Minute).
                    Etwas ungewohnt aber durchaus interessant war auch diesmal, dass amazon offenbar in das
                    Gesch&auml;ft um
                    die &Uuml;bertragungsrechte der Fussballspiel mit eingestiegen ist. So wurde das Spiel im
                    amazon.prime
                    &uuml;betragen.
                </p>
                <p>Ob der verdiente Sieg der Bayern allein daher m&ouml;glich war, dass Messi nicht mehr bei Barcelona
                    spielt, darf man wohl bezweifeln. Barcelona fing hoch konzentriert an, am Ende war aber der Respekt
                    vor den M&uuml;nchnern doch zu gro&szlig;.</p>
                <p>Die Bayern hattet ihrerseits ebenfalls Respekt vor dem Gegner, so dass sie erst etwas Zeit
                    ben&ouml;tigten, um richtig ins Spiel zu finden. Der permanente Drang zum gegnerischen Tor brachte
                    dann
                    aber auch die Ergebnisse und am Ende den verdienten Sieg.</p>
                <p>Am 08.12.2021 um 21:00 Uhr gibt es dann das Wiedersehen - bleibt zu hoffen, dass der FC Bayern
                    M&uuml;nchen gleichsam siegreich dann ist.
                </p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>11.09.2021 - 4. Spieltag</h4>
                <div className="spieltag">
                    <h4>4. Spieltag - Samstag, 11.09.2021 (18:30)</h4>
                    <p>RB Leipzig - FC Bayern M&uuml;nchen</p>
                    <b className="result">1 : 4</b>
                </div>

                <p>Der FC Bayern M&uuml;nchen war beim Mitkonkurrenten RB Leipzig zu Gast.</p>
                <p>Das erste Tor war wieder mal souver&auml;n von Robert Lewandowski verwandelt, gleichwohl der Elfmeter
                    sicher nicht ganz unumstritten sein d&uuml;rfte. Doch wenn der Pole vom Punkt antritt, haben die
                    wenigsten Torh&uuml;ter am Ende den Vorteil auf ihrer Seite. Und so steht es schon nach 12 Minuten
                    1:0
                    f&uuml;r die Bayern.</p>
                <p>Die Bayern sind gewohnt bissig, doch reicht es in der ersten Halbzeit nicht mehr f&uuml;r ein
                    weiteres
                    Tor. So hatte Robert Lewandowski das Nachsehen, bei einem recht unplatzierten Schu&szlig; am Ende
                    eines
                    bis dahin schnell gespielten Gegenangriff der Bayern.</p>
                <p>Das aber auch RB Leipzig sich nicht durchsetzen konnte, daran haben sicher auch Lucas Hernández und
                    Benjamin Pavard ihren Anteil, waren sie am Ende des Spiels die Bayern-Spieler mit den meisten
                    gewonnen Zweik&auml;mpfen wo die Zahlen f&uuml;r sich sprechen (Pavard 75% und Hernándes 71%).</p>
                <p>Mit seiner Einwechselung zum Ende der ersten Halbeit offerierte wieder Jamal Musiala zahlreiche
                    Tricks am und mit dem Ball und
                    belohnte am Ende sich und die Manschaft letztlich recht schnell (47. Minute) mit einem Tor und der
                    zwischezeitlichen 2:0 F&uuml;hrung f&uuml;r den
                    FC Baern M&uuml;nchen.</p>

                <p>Fast h&auml;tten der RB Leipzig den Anschluss geschafft, aber wegen einer Abseitsposition wurde der
                    Treffer den Leipzigern nicht gegeben.</p>
                <p>In der 54. Minute steht Leroy San&eacute; zu einem Zuspiel von Jamal Musiala genau richtig. Er
                    braucht nur
                    noch in die Luft zu springen und quasi den Fu&szlig; hinzuhalten - 3:0 f&uuml;r den Deutschen
                    Meister.</p>
                <p>4 Minuten sp&auml;ter bekommt dann Leipzig seinen Treffer aber es &auml;ndert nichts mehr am nie
                    wirklich
                    gef&auml;hrdetem Sieg der Bayern. In der 92. Minute kr&ouml;hnt sich die Manschaft des FC Bayern
                    durch das Tor
                    zum 4:1 durch Choupo Moting.</p>

                <p>Am Ende gewinnt der FC Bayern M&uuml;nchen mit 1:4 durch die Tore von Robert
                    Lewandowski (12. Minute) , Jamal Musiala (47. Minute), Leroy San&eacute; (54.
                    Minute) und Choupo Moting (92. Minute). Das Tor f&uuml;r den RB Leipzig schoss Konrad Laimer (58.
                    Minute).</p>
                <p>Am 5. Spieltag (18.09.2021 15:30 Uhr) empf&auml;ngt der FC Bayern daheim den VfL Bochum.</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>Marion &amp; Peter Ferstl geben sich vor Gott das JA-Wort</h4>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "Der FC Bayern Fanclub Hohenfels gratulieren Marion & Peter Ferstl",
                        src: "Marion_und_Peter_Ferstl_2021_09_11.jpg"
                    }

                ]} untertitel="8px" maxWidth={800}/>

                <p>
                    Nach zweimailgen Verschieben der kirchlichen Trauung war es heute nun endlich soweit.
                    Marion &amp; Peter Ferstl aus Hohenfels gaben sich in der Kirche St. Ulrich in Hohenfels das JA
                    -Wort.
                    Ganz klar, dass auch Vertreter des Fanclubs zum Spalierstehen vor Ort waren und pers&ouml;nlich dem
                    Brautpaar gratulierten.
                    <br/>
                    Gekommen waren Andreas Graf, Stefan Spangler, Christiane Richly, Thomas Mulzer und Karin Drake.
                    <br/>
                    <p>An dieser Stelle noch einmal die herzlichsten Gl&uuml;ckw&uuml;nsche an Marion &amp; Peter vom FC
                        Bayern
                        Fanclub Forellenbachtal Hohenfels.</p>
                    <p>{vorstand.ErsterVorstand.Name}</p>
                    <p>1. Vorstand</p>
                </p>


            </article>
            <hr/>

            <article className="articleTrauerContent">
                <h4>Gr&uuml;ndungsmitglied verstorben</h4>
                <p>Soeben erreicht uns die traurige Nachricht, dass unser Gr&uuml;ndungsmitglied Erasmus S&ouml;llner in
                    der Nacht zum 29.08.2021 verstorben ist.</p>
                <p>Unser tiefes Mitgef&uuml;hl und aufrichtiges Beileid gilt den Familienangeh&ouml;rigen.</p>
                <p>{vorstand.ErsterVorstand.Name}</p>
                <p>1. Vorstand</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>Neues vom Schaukasten</h4>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "Aushang vom 29.08.2021",
                        src: "Schaukasten_2021_08_29.jpg"
                    }

                ]} untertitel="8px" maxWidth={800}/>

                <h5>Infos von der Vorstandschaftssitzung am 20.08.2021</h5>
                <p>
                    Liebe Mitglieder,<br/>
                    am 20.08.2021 traf sich unser Vorstand wieder in gewohnter Weise pers&ouml;nlich zu einer
                    Vorstandschaftssitzung. Damit ihr auf dem Laufenden seid, was im Fanclub FC Bayern-Fanclub
                    Forellenbachtal Hohenfels vor sich geht, findet ihr nachfolgend eine kurze Zusammenfassung unserer
                    letzten Vorstandschaftssitzung.

                </p>
                <p>
                    <ul>
                        <li>
                            - F&uuml;r den 25.09.2021 ist die Jahreshauptversammlung mit anschlie&szlig;endem Grillfest
                            f&uuml;r alle
                            Mitglieder geplant. Die Einladung erfolgt hierzu in den n&auml;chsten Wochen postalisch.
                            Zutritt zur Veranstaltung erhalten jedoch nur Mitglieder gem&auml;&szlig; der aktuell
                            geltenden
                            Infektionsschutzverordnung. Aktuell ist dies die „3G-Regel“ (geimpft, genesen, getestet).
                            N&auml;here Informationen erhaltet ihr hierzu mit der Einladung.
                            Die Anmeldung zur Veranstaltung erfolgt &uuml;ber Zettelr&uuml;ckmeldung oder &uuml;ber die
                            Fanclubhomepage <b><i>https://www.fcb-hohenfels.de</i></b>
                            unter <b><i>Termine &rarr; Sommergrillen</i></b> Anmeldeschluss wird
                            der <b>19.09.2021</b> sein.

                        </li>
                        <li>
                            - N&auml;chstes Jahr steht das 20-j&auml;hrigen Jubil&auml;um des FC Bayern Fanclub
                            Hohenfels an. Wir
                            sammeln hierzu auf der Jahreshauptversammlung eure Ideen und W&uuml;nsche, wie wir das
                            Jubil&auml;um
                            begehen wollen.
                        </li>
                        <li> - Voraussichtlich am 18.09.2021 wird vom FC Bayern Fanclub Hohenfels eine Kirche f&uuml;r
                            alle
                            verstorbenen Mitglieder gelesen. Ihr seid herzlich eingeladen, am Gottesdienst teilzunehmen.
                        </li>
                    </ul>
                </p>
                <p>
                    Zu guter Letzt m&ouml;chten wir von der Vorstandschaft des FC Bayern-Fanclub Forellenbachtal
                    Hohenfels
                    dem FC Bayern M&uuml;nchen noch eine erfolgreiche Saison 2021/22 w&uuml;nschen und hoffen mit euch,
                    dass die
                    Saison wieder m&ouml;glichst titelreich wird.
                </p><p>
                Mit freundlichen Gr&uuml;&szlig;en <br/>Eure Vorstandschaft

            </p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>28.08.2021 - 3. Spieltag</h4>
                <div className="spieltag">
                    <h4>3. Spieltag - Samstag, 28.08.2021 (18:30)</h4>
                    <p>FC Bayern M&uuml;nchen - Hertha BSC</p>
                    <b className="result">5 : 0</b>
                </div>

                <p>Der FC Bayern M&uuml;nchen empf&auml;ngt heute die alte Dame Hertha aus Berlin.</p>
                <p>Die Bayern haben sichtlich Spa&szlig; am Spiel, was besonders deutlich wird am Ergebnis
                    - einem klaren und nie gef&auml;hrdeten 5:0. Die Berliner finden absolut keinen Zugriff, das Spiel
                    nachhaltig zu beeinflussen, um es doch noch zu ihren Gunsten zu drehen. </p>
                <p>Herausragender Spieler war wieder einmal mehr Robert Lewandowski, der nach eigenen Aussagen in der
                    Form seines Lebens ist. Sein Beitrag in diesem Spiel: wieder 3 Tore. Hervorzuheben ist hier auch die
                    starke Leistung von Leroy San&eacute;, der das 4:0 durch Robert Lewandowski erst
                    erm&ouml;glichte.</p>
                <p>Ein weiterer Spieler, der eine beachtenswerte Leistung auf dem Platz zeigte, war Jamal Musiala. Das
                    3:0 wurde durch Jamal Musiala verwandelt. Im Spiel h&auml;tte man fast meinen k&ouml;nnen, dass wird
                    nichts
                    mehr. Aber dann sah es so aus, als ob das alles h&auml;tte so sein m&uuml;ssen. Einmal kurz
                    vertendelt und
                    dann voll abgezogen. Bleibt abzuwarten, was Jamal Musiala noch bringt, wenn er denn richtig Fahrt
                    auf
                    nimmt. Allen Respekt f&uuml;r seine spielerische Leistung hat er sich heute mehr als verdient.</p>
                <p>Zu guter Letzt darf einer nicht fehlen: zum 1:0 <i><b>m&uuml;llerte</b></i> es wieder im Kasten der
                    Hertha, nach einer
                    Vorlage von Robert Lewandowski. Thomas M&uuml;ller erziehlte in der 6. Minute das 1:0 f&uuml;r die
                    Bayern.</p>
                <p>Am Ende gewinnt der FC Bayern M&uuml;nchen aber verdient mit 5:0 durch die Tore von Robert
                    Lewandowski (35.,70.,84. Minute) , Jamal Musiala (49. Minute) und Thomas M&uuml;ller (6.
                    Minute).</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>22.08.2021 - 2. Spieltag</h4>
                <div className="spieltag">
                    <h4>2. Spieltag - Sonntag, 22.08.2021 (17:30)</h4>
                    <p>FC Bayern M&uuml;nchen - 1. FC K&ouml;ln</p>
                    <b className="result">3 : 2</b>
                </div>
                <p>Das erste Heimspiel der Saison - und das vor einer (wenn auch kleinen) Zuschauerkulisse -
                    Fu&szlig;ballerherz, was willst du mehr...
                </p>
                <p>Der FC Bayern M&uuml;nchen empf&auml;ngt in seinem ersten Heimspiel der Saison in seim Wohnzimmer den
                    1.FC K&ouml;ln.</p>
                <p>In einem packenden Spiel schenkten sich beide Manschaften nichts. Aber ein Blick in die
                    Spielstatistik zeigt, da&szlig; der 1. FC K&ouml;ln seine Chancen wohl effektiver verwertete,
                    ben&ouml;tigte er doch nur 3 Torsch&uuml;sse, um den FC Bayern M&uuml;nchen mit 2 Treffern
                    ordentlich unter Druck zu setzen.</p>
                <p>Am Ende gewinnt der FC Bayern M&uuml;nchen aber verdient mit 3:2 durch die Tore von Robert
                    Lewandowski (50. Minute) und Serge Gnabry (59. und 71. Minute).</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>17.08.2021 - Supercup 2021/2022</h4>
                <div className="spieltag">
                    <h4>Supercup - Dienstag, 17.08.2021 (20:30)</h4>
                    <p>Borussia Dortmund - FC Bayern M&uuml;nchen</p>
                    <b className="result">1 : 3</b>
                </div>
                <p>Der Deutsche Fu&szlig;ballmeister (FC Bayern M&uuml;nchen) trifft auf den DFB-Pokal-Sieger (Borussia
                    Dortmund). Es ist der Klassiker schlechthin, wenn diese beiden Manschaften aufeinander treffen. Der
                    FC Bayern M&uuml;nchen hat eindrucksvoll bewiesen, wer die bessere Mannschaft ist.
                </p>
                <p>
                    Robert Lewandowski bringt die M&uuml;nchener in der 41. Minute in F&uuml;hrung. In der 49. Minute
                    ist
                    Thomas M&uuml;ller zur Stelle und baut so die F&uuml;hrung zum 2:0 aus. Leider gelingt des den
                    Schwarz-Gelben mit Marco Reus in der 64. Minute den Anschlu&szlig;treffer zu erzielen. Der FC Bayern
                    M&uuml;nchen setzt mit dem Torschu&szlig; von Robert Lewandowski in der 74. Minute ein klares
                    Ausrufezeichen, wer hier nur als Sieger vom Platz gehen kann.
                </p>
                <p>Am Ende gewinnt die Mannschaft des FC Bayern M&uuml;nchen verdient den Supercup 2021/2022. Der erste
                    Titel ist damit eingefahren.</p>
                <p>Wir vom <b><i>Fanclub Forellenbachtal Hohenfels</i></b> gratulieren dem FC Bayern M&uuml;nchen zum
                    Gewinn des Supercups.</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>13.08.2021 - 1. Spieltag der Saison 2021/2022</h4>
                <div className="spieltag">
                    <h4>1. Spieltag - Freitag, 13.08.2021 (20:30)</h4>
                    <p>Borussia M&ouml;chengladbach - FC Bayern M&uuml;nchen</p>
                    <b className="result">1 : 1</b>
                </div>
                <p>Mit einem mageren 1:1 startete der Deutsche Fu&szlig;ballmeister die Saison. Im ersten
                    Spiel der Saison kommt der FC Bayern bei Borussia M&ouml;nchengladbach &uuml;ber ein 1:1 nicht
                    hinaus, wobei
                    Robert Lewandowski in der 42. Minute f&uuml;r den Ausgleich und gleichzeitigen Spielendstand sorgte.
                </p>

            </article>
            <hr/>

            <article className="articleContent">
                <h4>Einsatz am Container</h4>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "Containereinsatz am 29.05.2021",
                        src: "Containereinsatz_1_2021_05_29.jpg"
                    }

                ]} untertitel="8px" maxWidth={800}/>
                <p>
                    Mitglieder des Vorstandes trafen sich am 29.05.2021 am Container und haben ordentlich entstaubt. Die
                    K&uuml;hlschr&auml;nke und der Grill wurden gr&uuml;ndlich sauber gemacht. Daneben wurde
                    gleichzeitig eine kleine
                    Bestandsaufnahme vorgenommen. Nach 2 &frac12; Stunden Arbeit war dann auch schon alles erledigt.
                </p>
                <p>Alle Mitglieder haben die M&ouml;glichkeit Teile der Vereinsausstattung f&uuml;r private Feiern
                    auszuleihen.</p>
                <p>Hierzu wird es demn&auml;chst eine entsprechende M&ouml;glichkeit auf der Webseite geben.</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>Neues vom Schaukasten</h4>
                <ImageViewer items={[
                    {
                        name: "",
                        description: "Aushang vom 27.05.2021",
                        src: "Schaukasten_2021_05_28.jpg"
                    }

                ]} untertitel="8px" maxWidth={800}/>

                <h5>Infos von der Vorstandschaftssitzung am 19.05.2021</h5>
                <p>
                    Liebe Mitglieder, am 19.05.2021 traf sich unser Vorstand zu seiner ersten Online-Sitzung, da Corona
                    uns immer noch stark einschr&auml;nkt. Damit ihr auf dem Laufenden seid, was im Fanclub FC
                    Bayern-Fanclub
                    Forellenbachtal Hohenfels vor sich geht, findet Ihr nachfolgend die f&uuml;r euch relevanten
                    Themen
                    aus unserer letzten Vorstandschaftssitzung.
                </p>
                <p>
                    <ul>
                        <li>
                            - Der f&uuml;r den 18.01.2021 geplante Fanclub Faschingsball musste aus bekannten
                            Gr&uuml;nden
                            abgesagt
                            werden. Hierbei entstanden dem Verein keine Stornokosten.
                        </li>
                        <li>
                            - Unser Homepagebeauftragter Rene&eacute; Drescher-Hackel hat in den letzten Wochen unsere
                            Fanclubhomepage entstaubt und ein neues Gesicht verpasst.
                            Gerne mal vorbeischauen unter <b><i>https://www.fcb-hohenfels.de</i></b>
                        </li>
                        <li> - Anfang Juni 2021 werden die Mitgliedbeitr&auml;ge von unserem Kassier Lukas Ring
                            eingezogen.
                        </li>
                        <li> - Der FC Bayern M&uuml;nchen stellt aus Umweltschutzgr&uuml;nden auf Digitale Tickets um.
                            Die Tickets
                            m&uuml;ssen
                            somit zuk&uuml;nftig mit dem Smartphone oder in ausgedruckter Form bei den Einlasskontrollen
                            vorgezeigt
                            werden.
                        </li>
                        <li> - Die Vorstandschaftsmitglieder entr&uuml;mpeln und r&auml;umen am 29. Mai 2021 den
                            vereinseigenen
                            Container
                            auf.
                        </li>
                        <li> - Sobald es die aktuelle Corona-Situation wieder erm&ouml;glicht, ist ein Vereinsfest, als
                            Ersatz f&uuml;r die
                            letztes Jahr entfallene Weihnachtsfeier geplant. N&auml;here Infos hierzu folgen. 😊
                        </li>
                    </ul>
                </p>
                <p>
                    Zu guter Letzt m&ouml;chten wir von der Vorstandschaft des FC Bayern-Fanclub Forellenbachtal
                    Hohenfels
                    dem FC Bayern M&uuml;nchen noch zum neunten Meistergewinn in Folge gratulieren. Au&szlig;erdem
                    m&ouml;chten wir
                    Robert Lewandowski auf diesem Weg nochmals Respekt f&uuml;r seine 41 Bundesligatore zollen und uns
                    bei
                    David Alaba, J&eacute;rôme Boateng und Javi Martinez f&uuml;r Ihre Verdienste gegen&uuml;ber unserem
                    FC
                    Bayern M&uuml;nchen
                    bedanken.
                </p><p>
                Mit freundlichen Gr&uuml;&szlig;en <br/>Eure Vorstandschaft

            </p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>22.05.2021 - Letzter Spieltag der Saison</h4>
                <div className="spieltag">
                    <h4>34. Spieltag - Samstag, 22.05.2021</h4>
                    <p>FC Bayern M&uuml;nchen - FC Augsburg (15:30 Uhr)</p>
                    <b className="result">5 : 2</b>
                </div>
                <p>Mit einem ordentlichen Ergebnis beendete der Deutsche Fu&szlig;ballmeister die Saison. Im letzten
                    Heimspiel
                    besiegte der FC Bayern den FC Augsburg mit 5:2, wobei Robert Lewandowski buchst&auml;blich in
                    letzter
                    Minute den Rekord, der einst vom Bomber der Nation aufgestellt wurde, mit seinem 41. Saisontor
                    einstellte.
                </p>
                <p>Wir vom <b><i>Fanclub Forellenbachtal Hohenfels</i></b> gratulieren nochmals Robert Lewandowski zu
                    dieser
                    gro&szlig;artigen Saison. Hoffen wir, dass er dem FC Bayern noch lange erhalten bleibt.</p>
                <p>Nach der Saison ist vor der Saison: auf geht's zu neuen alten Zielen: das Triple Nr. 3 - also das
                    Triple Triple...</p>
                <p>Bleibt noch abschlie&szlig;end zu erw&auml;hnen: <b>Danke</b> <i>David Alaba</i>, <b>Danke</b> <i>Jèrôme
                    Boateng</i>, <b>Danke</b> <i>Javi Martinez</i>.
                    Ihr habt eine hervorragende Leistung beim FC Bayern erbracht. Sicher w&uuml;rde man sich dann und
                    wann eine andere Personalpolitik w&uuml;nschen. Auch hat es sicher kein Spieler verdient <i>"vom Hof
                        gejagt zu werden"</i> - ganz der Devise: <i>der Mohr hat seine Schuldigkeit getan, nun kan er
                        gehen</i>.
                    Hier fragt man
                    sich bisweilen: wo bleibt die Anerkennung und der Respekt dem Spieler gegen&uuml;ber?</p>
                <p>Bekannterma&szlig;en kehren neue Besen gut: so hoffen wir, dass der Verein aus den Fehlern der
                    Vergangenheit gelernt hat und in der kommenden Saison es besser macht - in jeder Hinsicht, so
                    dass das Triple wieder Realit&auml;t wird. </p>
            </article>
            <hr/>

            <article className="articleContent">

                <h4>19.05.2021 - Vorstandssitzung online</h4>
                <p>Am 19.05.21 traf sich unser Vorstand zu seiner ersten Online-Sitzung, da Corona uns immer noch stark
                    einschr&auml;nkt.
                    Nach anf&auml;nglichen Schwierigkeiten haben es aber alle geschafft, eine Onlineverbindung
                    herzustellen.
                    Sicher ist das auch f&uuml;r Nach-Corona-Zeiten dann und wann eine Option, kommen doch nicht alle
                    Vorstandsmitglieder aus Hohenfels.</p>

                <ImageViewer items={[
                    {
                        name: "",
                        description: "",
                        src: "zoomMeeting_2021_05_19.jpg"
                    }

                ]} untertitel="8px" maxWidth={800}/>
            </article>
            <hr/>

            <article className="articleContent">
                <div className="spieltag">
                    <h4>33. Spieltag - Samstag, 15.05.2021</h4>
                    <p>SC Freiburg - FC Bayern M&uuml;nchen (15:30 Uhr)</p>
                    <b className="result">2 : 2</b>
                    <ul style={{fontSize: 10}}>
                        <li>26' - 0:1 Lewandowski (Elfm.)</li>
                        <li>29' - 1:1 Gulde</li>
                        <li>53' - 1:2 L. San&eacute;</li>
                        <li>81' - 2:2 C. G&uuml;nter</li>
                    </ul>
                </div>
                <p>Der FC Bayern kam heute gegen gut aufgestellte Freiburger nicht &uuml;ber ein 2:2 hinaus. Dennoch
                    schoss
                    Robert Lewandowski das lang erwartete 40. Tor und stellte damit den Rekord von Gerd "Bomber"
                    M&uuml;ller
                    ein.</p>
                <p>Wir vom <b><i>Fanclub Forellenbachtal Hohenfels</i></b> gratulieren Robert Lewandowski zu dieser
                    Meisterleistung.</p>
            </article>
            <hr/>

            <article className="articleContent">
                <h4>Zum 9. Mal in Folge Deutscher Fu&szlig;ballmeister</h4>
                <p>
                    Sie haben es wieder gemacht. <br/>Zum 9. Mal in Folge holt die erste Fu&szlig;ballmanschaft des FC
                    Bayern
                    M&uuml;nchen den Titel <i>Deutscher Fu&szlig;ballmeister</i>.
                    Wir vom <b><i>Fanclub Forellenbachtal Hohenfels</i></b> gratulieren der Manschaft, dem Trainer und
                    allen
                    Beteiligten des FC Bayern M&uuml;nchen zum erneuten Titelgewinn.
                    <br/>
                    <ImageViewer items={[
                        {
                            name: "Deutscher Fußballmeister 2021",
                            description: "Bildquelle: Sky Sport1 Liveübertragung Spiel FC Bayern München - Borussia Mönchengladbach 08.05.2021",
                            src: "meister09.png"
                        }

                    ]} untertitel="8px" maxWidth={800}/>
                </p>


            </article>
            <hr/>

            <article className="articleContent">
                <h4>06.05.2021 - Homepage ist in Arbeit</h4>
                <p>
                    Die ersten &Auml;nderungen an unserer Webseite gehen online. Die Webseite ist
                    grundlegend &uuml;berarbeitet
                    worden.
                    Ziel ist es, euch eine Anlaufstelle zu bieten, wo ihr neben dem <i>Hohenfelser Wochenblatt</i> und
                    unserem Schaukasten <i>Am Markt</i> schnell Informationen rund um den Fanclub finden k&ouml;nnt.
                    <br/>Geduldigt euch noch etwas..., denn hier wird noch bissl gebaut.</p>


            </article>
        </div>
    )
}

export default withRouter(Aktuelles);
