import axios from "axios";
import * as config from "./config";
import {CurrentUser, EventData, LoginData} from "./dto/userData";
import {ContactFormData} from "./dto/appData";




let apiOptions: any = {

    baseURL: config.api.url(),
    withCredentials: false,
    validateStatus: (status: any) => {
        return true;
    }
};

apiOptions.headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
};

const url: string = "/ajax.php?call=";

const api: any = axios.create(apiOptions);

const post = (method: string, data: any) => {
    let formData = new FormData();
    for (let key in data) {
        if (key === "Recipient") {
            for (let n in data[key]) {
                formData.append(n, data[key][n]);
            }
        } else
            formData.append(key, data[key]);
    }
    // console.log("formData: " + JSON.stringify(formData));
    return api.post(url + method, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: JSON.stringify(formData)
    });
};

const get = (method: string) => {
    return api.get(url + method);
};

/* ************************ Public Service Methods ************************** */

export const userLogin: any = (loginData: LoginData) => () => post("UserLogin", loginData);

export const sendNewUserRegistration: any = (loginData: LoginData) => () => post("SendNewRegistration", loginData);

export const sendNewUserPassword: any = (loginData: LoginData) => () => post("SendNewPassword", loginData);

export const sendContactData: any = (contactFormularData: ContactFormData) => () => post("AddContact", contactFormularData);

export const getRegistrationData: any = (key: string) => () => get("GetRegistrationData&Id=" + key);

export const confirmRegistration: any = (key: string, pwd: string) => () => post("ConfirmUserRegistration", {
    Key: key,
    PWD: pwd
});

export const setNewPassword: any = (key: string, pwd: string) => () => post("SetNewPassword", {
    Key: key,
    PWD: pwd
});

export const getUser: any = (id: any) => () => get("GetUser&Id=" + id);


export const GetUserBaseDataById: any = (key: string) => () => post("Base", {
    Key: key
});

export const saveUserBaseData: any = (currentUser: CurrentUser) => () => post("SaveBase", currentUser);

export const saveUserContactData: any = (currentUser: CurrentUser) => () => post("SaveContact", currentUser);

export const saveUserPaymentData: any = (currentUser: CurrentUser) => () => post("SavePayment", currentUser);

export const getQrCodeUrlValue: any = (currentUser: CurrentUser) => () => post("GetQrCode", {
    email: currentUser.Email,
    token: currentUser.Login.twoFactoryAuthentication.token,
    id: currentUser.Id
});

export const checkSecureCodeData: any = (currentUser: CurrentUser) => () => post("ValidateSecurecode", {
    secretCode: currentUser.Login.twoFactoryAuthentication.token,
    authCode: currentUser.Login.twoFactoryAuthentication.code,
    id: currentUser.Id
});

export const resetUseTokenData: any = (currentUser: CurrentUser) => () => post("ResetUseToken", {
    Key: currentUser.Id
});

export const importUserData: any = (importFile: File)  => () => post("ImportUserData", {
    FileData: importFile
});

export const getEventByTypeData: any = (current: number) => () => post("GetEventByType", { eventTyp: current});

export const getEventData: any = (id: string) => () => post("GetEventData", { eventId: id});

export const addUserLoginToken: any = (user: CurrentUser) => () => post("AddUserLoginToken", {
    Id: user.Id,
    Token: user.Login.twoFactoryAuthentication.token,
    UseToken : user.Login.twoFactoryAuthentication.useToken || user.Login.twoFactoryAuthentication.token.length > 0
});

export const getAllEventsData : any = () => () => get("GetAllEvents");

export const getActiveUsersData : any = () => () => get("GetActiveUsers");

export const getActiveUsersDataCount : any = () => () => get("GetActiveUsersCount");

export const registerUserDataToEvent : any = (eventData: EventData) => () => post("RegisterToEvent",   eventData);

export const getUserEvents: any = (id: string) => () => post("GetUserEvents", { Id: id});

export const removeUserDataFromEvent: any = (id1:string, id2:string)  => () => post("RemoveFromEvent", { EventId: id1, UserId: id2});

export const sendNewsletter: any = (bodyText: string) => () => post("SendNewsletter", { MailBodyText: bodyText});
