import MainMenu from "./menu";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getActiveUsers, getActiveUsersCount} from "../reducers/userReducer";
import {AppState} from "../app/appState";



function SiteHeader() {
    const dispatch = useDispatch();
    const [constructorHasRun, setConstructorHasRun] = useState(false);
    const stateData = useSelector((state: AppState) => state);
    // const appData = stateData.appData;
    const userData = stateData.userData;
    // const user = userData.currentUser;

    useEffect(() => {

    }, []);

    const constructor = () => {
        if (constructorHasRun) return;
        dispatch(getActiveUsersCount());
        setConstructorHasRun(true);
    };

    constructor();
    return (
        <header>
            <MainMenu/>
        </header>
    )
}

export default SiteHeader;
