import React, {useEffect} from "react";
import {AppBar, Tab, Tabs} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import {useTheme} from "@material-ui/core/styles";
import {TabPanel} from "../App";
import {withRouter} from "react-router-dom";
import AppEditor from "../components/editor";
import AppGridView from "../components/gridview";


function AdminSeite() {

    useEffect(() => {
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            bg.className = 'hohenfels';
    }, []);

    const [value, setValue] = React.useState(0);


    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            switch (newValue) {
                default:
                    bg.className = 'hohenfels';
                    break;

            }
    };

    const theme = useTheme();

    return (
        <div className="content">
            <AppBar color="transparent" className="appBarSubMenu">
                <Tabs
                    variant="scrollable"
                    className="menuTabs"
                    value={value}
                    indicatorColor="primary"
                    onChange={handleChange}
                >
                    <Tab label="Startseite"/>
                    <Tab label="Termine"/>
                    <Tab label="Benutzer"/>
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <article>
                        <h4>Startseite bearbeiten</h4>
                        <AppEditor/>
                    </article>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <article>
                        <h4>Termine bearbeiten</h4>
                    </article>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <article>
                        <h4>Benutzer bearbeiten</h4>
                        <AppGridView columns={
                            [
                                { text: 'Nr.',  datafield: 'Nr', width: 250 },
                                { text: 'Nachname',  datafield: 'LastName', width: 250 },
                                { text: 'Vorname',  datafield: 'FirstName', width: 250 },
                                { text: 'Adresse',  datafield: 'Address', width: 250 },
                                { text: 'PLZ',  datafield: 'ZIPCode', width: 250 },
                                { text: 'Ort',  datafield: 'City', width: 250 },
                                { text: 'Anmeldenummer',  datafield: 'RegisterNumber', width: 250 },
                                { text: 'Telefon',  datafield: 'PhoneNumber', width: 250 },
                                { text: 'Mobil',  datafield: 'MobileNumber', width: 250 },
                                { text: 'E-Mail',  datafield: 'Email', width: 250 },
                                { text: 'bei FCB Online',  datafield: 'FCBOnline', width: 250 },
                                { text: 'Newsletter',  datafield: 'Newsletter', width: 250 },
                                { text: 'Facebook',  datafield: 'Facebook', width: 250 },
                                { text: 'Bilder Online',  datafield: 'PublicImages', width: 250 },
                                { text: 'Geb. Gruß',  datafield: 'PublicBirthday', width: 250 },
                                { text: 'Beitritt',  datafield: 'AddMember', width: 250 },
                                { text: 'Mitgliedschaft',  datafield: 'MemberTime', width: 250 },
                                { text: 'Mitgliedsstatus',  datafield: 'MemberState', width: 250 },
                                { text: 'Mitgliedsrolle',  datafield: 'MemberRole', width: 250 },
                                { text: 'Kontoinhaber',  datafield: 'BankAccountOwner', width: 250 },
                                { text: 'IBAN',  datafield: 'IBAN', width: 250 },
                                { text: 'BIC',  datafield: 'BIC', width: 250 },
                                { text: 'Bank',  datafield: 'BankName', width: 250 },
                                { text: 'Geburtsdatum',  datafield: 'Birthday', width: 250 },
                                { text: 'Alter',  datafield: 'Age', width: 250 },
                                { text: 'Beitrag',  datafield: 'MembershipFee', width: 250 }
                            ]
                        } />
                    </article>
                </TabPanel>
            </SwipeableViews>
        </div>
    )
}

export default withRouter(AdminSeite);
