import React from 'react';
import Carousel from 'react-material-ui-carousel'
import {Paper} from '@material-ui/core'


function ImageViewer(props: any) {
    let ap = props.autoPlay;
    if (ap === undefined)
        ap = false;

    let id = props.indicators;
    if (id === undefined)
        id = false;

let imagePath = props.path !== undefined ? props.path: "./images/";
    return (
        <div style={{maxWidth: props.maxWidth}}>
            <Carousel autoPlay={ap} indicators={id} stopAutoPlayOnHover={true} timeout={1500}>
                {
                    props.items.map((item: any, i: number) => <Item path={imagePath} key={i} item={item} css={props.css}
                                                                    fontSize={props.untertitel}
                                                                    linkTo={props.linkToDescription}/>)
                }
            </Carousel>
        </div>
    )
}

function Item(props: any) {
    let imgUrl = `${props.path}${props.item.src}`;
    // if (!imgUrl.startsWith("./"))
    //     imgUrl = `./images/${imgUrl}`;
    let fs = props.fontSize || "small";
    return (
        props.linkTo !== undefined ?
            <a style={{textDecoration: "none"}}
               href={props.item.description} target="_blank" rel="noreferrer">
                <Paper elevation={3}>
                    <div className={props.className}>
                        <h4>{props.item.name}</h4>
                        <img alt={props.item.name} src={imgUrl}
                             style={{width: '100%', height: '100%', alignItems: "center"}}/>
                        <p style={{fontSize: fs}}>{props.item.description}</p>
                    </div>
                </Paper>
            </a> :
            <Paper elevation={3}>
                <div className={props.className}>
                    <h4>{props.item.name}</h4>
                    <img alt={props.item.name} src={imgUrl}
                         style={{width: '100%', height: '100%', alignItems: "center"}}/>
                    <p style={{fontSize: fs}}>{props.item.description}</p>
                </div>
            </Paper>
    )
}

export default ImageViewer;
