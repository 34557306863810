import React, {useEffect} from 'react';

import {Snackbar} from "@material-ui/core";
import MuiAlert, {AlertProps} from "@material-ui/lab/Alert";
import {useDispatch} from "react-redux";
import {hideSnackbar} from "../reducers/appReducer";

const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const AppSnackbar = (props: any) => {

    const dispatch = useDispatch()

    const hideAppSnackbar = () => {
        dispatch(hideSnackbar());
        if(props.onClose !== undefined)
            props.onClose();
    };

    useEffect(() => {
    }, []);

    return (
        <Snackbar open={props.showSnackbar}
                  anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                  autoHideDuration={6000} onClose={hideAppSnackbar}
        >
            <Alert onClose={hideAppSnackbar} severity={props.severity}>
                {props.message}
            </Alert>
        </Snackbar>
    )

}

export default AppSnackbar;
