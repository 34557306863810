import React, {useEffect, useState} from 'react';
import "./sass/content.sass"
import SiteHeader from "./components/header";
import SiteFooter from "./components/footer";
import AppRouter from "./components/router";
import {Box, createStyles, makeStyles, Theme} from "@material-ui/core";
import {Provider, useDispatch} from "react-redux";
import appStore from "./app/appStore";
import {getActiveUsersCount} from "./reducers/userReducer";


export const isValidEmail = (email: string) => {
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,})*$/;
    return (email.match(mailformat));
};

export interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        multiline: {
            minHeight: "210px !important",
            maxHeight: "210px !important",
            overflow: "overlay !important"
        },
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
            flexGrow: 1,
            backgroundColor: "transparent",
            display: 'flex',
            height: "100%",
            padding: "5px"
        },
        tabs: {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        aside1024: {
        },
        aside: {
        },
        maxSize:{
            maxWidth: 800
        }
    }),
);


export const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
};

export const axiosOptions = {
    withCredentials: true,
    headers: {
        crossdomain: true,
        "Cache-Control": "no-cache, no-store, must-revalidate, max-age=-1, private",
        "Pragma": "no-cache",
        "Expires": 0,
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Expose-Headers": "Access-Control-*",
        "Access-Control-Allow-Headers": "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept, Authorization",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, HEAD",
        "Allow": "GET, POST, PUT, DELETE, OPTIONS, HEAD"
    }
};

export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function App() {
    //   const dispatch = useDispatch();
    //   const [constructorHasRun, setConstructorHasRun] = useState(false);
    //
    useEffect(() => {

    }, []);
    //
    // const constructor = () => {
    //     if (constructorHasRun) return;
    //     dispatch(getActiveUsersCount());
    //     setConstructorHasRun(true);
    // };
    //
    // constructor();

    return (
        <Provider store={appStore}>
            <div className="main">
                <SiteHeader/>
                <AppRouter/>
                <SiteFooter/>
            </div>
        </Provider>
    );
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof appStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof appStore.dispatch

export default App;
