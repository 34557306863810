import {createStyles, FormControl, InputLabel, makeStyles, Select, Theme} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import axios from "axios";
import ImageViewer from "../components/imageviewer";
import {axiosOptions} from "../App";
import {bilder} from "stammdaten";
import {withRouter} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

function BilderArchiv() {

    useEffect(() => {
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            bg.className = 'hohenfels';
    }, []);

    const classes = useStyles();
    const [state, setState] = useState<{ directory: string; title: string, items: Array<string> }>({
        directory: '',
        title: '',
        items: new Array<string>()
    });
    const [textNr, steTextNr] = useState(0);

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const name = event.target.name as keyof typeof state;
        setState({
            ...state,
            [name]: String(event.target.value)
        });
        steTextNr(0);
        if("2021_21_09_25_JHV_Grillfest" == event.target.value){
            steTextNr(1);
        }
        setTimeout(() => {
            let postData = new FormData();
            // @ts-ignore
            postData.append('directory', event.target.value);
            axios.post("./php/ajax.php?call=getImages", postData, axiosOptions)
                .then((response: any) => {
                    let ImagesPath = new Array<any>();
                    let data = response.data;
                    for (let i = 0; i < data.images.length; i++) {
                        let path = data.directory + "/" + data.images[i];
                        ImagesPath.push({
                            name: "",
                            description: "",
                            src: path
                        });
                    }
                    setState({
                        ...state,
                        items: ImagesPath
                    });
                    console.log(response.data);
                    console.log(ImagesPath);
                })
                .then((error: any) => {
                    if (error !== undefined) {
                        console.log("Error");
                        console.log(error);
                    }
                });
        }, 100);
    };

    let sortedImages = bilder.sort((a: any, b: any) => {
        let date1 = new Date(a.date);
        let date2 = new Date(b.date);
        if (date1 > date2) {
            return -1;
        }

        if (date1 < date2) {
            return 1;
        }

        return 0;
    });

    let groupedImages = sortedImages.reduce(function (r, a) {
        let _a = new Date(a.date).getFullYear();
        r[_a] = r[_a] || [];
        r[_a].push(a);
        return r;
    }, Object.create(null));

    let groupedImagesData = new Array<any>();
    for (const key in groupedImages) {
        groupedImagesData.push({
            group: key,
            items: groupedImages[key]
        })
    }

    const items = () => {
        groupedImagesData.sort((a: any, b: any) => {
            console.log(a);
            if (a.group > b.group) {
                return -1;
            }

            if (a.group < b.group) {
                return 1;
            }

            return 0;
        });
        return groupedImagesData.map((item: any) => {
            return (<optgroup key={item.group} label={item.group}>
                {item.items.map((d: any) => {
                    return <option key={d.date} value={d.directory}>{d.title}</option>
                })}
            </optgroup>);
        });
    };


    return (
        <div className="content">
            <aside>
                <section>
                    <p>W&auml;hle eine Veranstaltung aus, um die Bilder zu sehen. Die Bilder wechseln dann
                        selbst&auml;ndig. Sind Bilder dabei, wo du nicht m&ouml;chtest, dass sie ver&ouml;ffentlicht
                        sind, dann kontaktiere uns bitte. Wir pr&uuml;fen dein Anliegen dann umgehend.</p>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="directory">Veranstaltung</InputLabel>
                        <Select defaultValue=""
                                native
                                onChange={handleChange}
                                inputProps={{
                                    name: 'directory',
                                    id: 'directory',
                                }}
                        >
                            <option aria-label="None" value=""></option>
                            {
                                items()
                            }

                        </Select>
                    </FormControl>
                </section>
            </aside>
            <aside>
                <div style={{height: 600}}>
                    <ImageViewer items={state.items} untertitel="8px" maxWidth={800}/>
                </div>
                {
                    textNr === 1 &&
                    <article className="articleContent">
                        <h4>25.09.2021 - Jahreshauptversammlung und Grillfest</h4>
                        <p >Bei sp&auml;tsommerlichem Wetter hatte der erste
                            Vorstand Thomas Stadlmeier zur Jahreshauptversammlung in's
                            Sportheim an der Haarziegelh&uuml;tte eingeladen und viele Fanclubmitglieder sind der Einladung
                            gerne
                            gefolgt. Als besonderen Gast durfte Thomas den ersten B&uuml;rgermeister von Hohenfels, Christian
                            Graf
                            begr&uuml;&szlig;en.</p>
                        <p >Nach der obligatorischen
                            Corona-Verhaltensunterweisung gedachten alle anwesenden Mitglieder mit
                            einer Schweigeminute den verstorbenen Mitgliedern. Sodann zog Thomas Stadlmeier ein Resum&eacute; auf die
                            vergangene Zeit und musste feststellen, dass nahezu alle geplanten Veranstaltungen, wie auch die
                            gemeinsamen Fanclubfahrten zu Spielen des FC Bayern M&uuml;nchen coronabedingt ausgefallen sind.</p>
                        <p >Ebenso berichtete Thomas, dass der Fanclub zu zwei
                            runden Geburtstagen und zu einer Hochzeit
                            gratulieren durfte.</p>
                        <p >Im Anschluss gab der erste Kassierer, Lukas Ring,
                            einen Überblick zur finanziellen Situation des
                            Verein's. Im Ergebnis lies sich feststellen, dass gut gewirtschaftet wurde und die Kasse
                            ordnungsgem&auml;&szlig; gef&uuml;hrt wurde, wie durch die Kassenpr&uuml;fung von Irene Walter
                            best&auml;tigt wurde. </p>
                        <p >Im weiteren berichtete Thomas Stadlmeier, dass die
                            Situation mit den gemeinsamen Fu&szlig;ballfahrten sich
                            in naher Zukunft wohl bald weiter entspannen wird. So wird es bald m&ouml;glich sein, dass Fanclubs
                            wieder mehr Karten bestellen werden k&ouml;nnen und dass wohl in diesem Zusammenhang die
                            Personalisierung der Stadionbesuche mehr in den Zust&auml;ndigkeitsbereich der Fanclubs sich
                            verlagern
                            wird.</p>
                        <p >Thomas Stadlmeier stellte noch eimal die Entwicklung
                            der Vereinshomepage vor und machte in diesem
                            Zusammenhang noch einmal deutlich, wie wichtig es ist, dass der Verein mehr auf die digitalen
                            Angebote in Zukunft setzen wird, da es allein mit der aktuellen Einladung zu einer logistischen und
                            auch finanziellen Herausforderung kam, wollte die Deutsche Post die Einladungen nicht mehr als
                            Infopost zustellen. Alle Fanclubmitglieder sind aufgerufen, sich im Mitgliederbereich der
                            Fanclubwebseite zu registrieren und das Onlineangebot des Vereins aktiv zu nutzen. Thomas wies auch
                            darauf hin, dass der Verein und seine Angebote sich ma&szlig;geblich nach den Bed&uuml;rfnissen und
                            W&uuml;nschen
                            der Mitglieder richten wird und jeder aufgefordert ist, seine Ideen und Vorstellungen aktiv mit
                            einzubringen - z.B. &uuml;ber das Kontaktformular der Webseite.</p>
                        <p >Am Ende gab es einen kurzen Ausblick auf das
                            kommende Jahr und die Aufforderungen, Ideen zu sammeln,
                            anl&auml;&szlig;lich des 20-j&auml;hrigen Vereinsjubil&auml;ums, welches im kommenden Jahr geb&uuml;hrend gefeiert werden soll.</p>
                        <p >Auch Christian Graf, erster B&uuml;rgermeister von
                            Hohenfels, richtete noch ein paar Gru&szlig;worte an den Verein
                            und die anwesenden Mitglieder. Er stellte heraus, wie wichtig das aktive Vereinsleben auch f&uuml;r
                            die
                            Gemeinde ist. Auch er dr&uuml;ckte sein Bedauern dar&uuml;ber aus, dass coronabedingt zahlreiche
                            Vereinsfeste
                            nicht stattfinden durften, zeigte sich jedoch zuversichtlich, dass 2022 sicher wieder mehr
                            m&ouml;glich
                            sein wird.</p>
                        <p >Im Anschluss an die Jahreshauptversammlung trafen
                            sich die anwesenden Mitglieder zum gemeinsammen
                            Grillfest am Sportplatz des TSV Hohenfels.</p>
                        <p >Georg Spangler und Stephan Walter k&uuml;mmerten
                            sich darum, dass das Grillgut (Halsfleich, Bauch und
                            W&uuml;rst'l) gut durchgegart wurden. Insbesondere Georg Spangler kam dabei sichtlich ins Schwitzen,
                            was
                            an der Kopfoberfl&auml;che mehr oder weniger sichtbar wurde.</p>

                        <p >Zu fortgeschrittener Stunde versuchten Stephan
                            Walter, Andreas Graf, Thomas Mulzer und Stefan
                            Spangler an Bundesligarekorden im Elfmeterschie&szlig;en unter den kritischen Blicken des ersten
                            Vorstandes Thomas Stadlmeier anzukn&uuml;pfen.</p>
                        <p >Abschlie&szlig;end bleibt allen Beteiligten zu
                            Danken, f&uuml;r die tatkr&auml;ftige Unterst&uuml;tzung bei der Planung
                            und Realisierung der Veranstalltung. Besonderen Dank gilt allen Mitgliedern, die durch ihren
                            zahlreichen Salate und Kuchen f&uuml;r das leibliche Wohl mit sorgten.</p>
                    </article>
                }
            </aside>

        </div>
    )
}

export default withRouter(BilderArchiv);
