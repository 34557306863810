import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {vorstand} from "../stammdaten";


function DatenverarbeitungSeite() {

    useEffect(() => {
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            bg.className = '';
    }, []);
    return (
        <div className="content">
            <h2>Merkblatt Datenschutz (Informationspflichten nach Artikel 13 und 14 DS-GVO)</h2>

            <h3>1. Name und Kontaktdaten des Verantwortlichen und seines Vertreters</h3>

            <ul>
                <li>{vorstand.ErsterVorstand.Name}</li>
                <li>FC Bayern Fanclub Forellenbachtal Hohenfels</li>
                <li>{vorstand.ErsterVorstand.Strasse}</li>
                <li>{vorstand.ErsterVorstand.Ort}</li>
                <li>Deutschland</li>
                <li>Tel.:{vorstand.ErsterVorstand.Tel}</li>
                <li>E-Mail:{vorstand.ErsterVorstand.Mail}</li>
            </ul>

            <h3>2. Kontaktdaten des Datenschutzbeauftragten</h3>
            [Name, Vorname, Postanschrift, ggf. E-Mail-Adresse]

            <h3>3. Zwecke der Verarbeitung personenbezogener Daten</h3>
            <ul>
                <li> Verwaltung der Vereinstätigkeiten bzw. Durchführung des Mitgliedschaftsverhältnisses</li>
                <li> Anmeldung zur Teilnahme Veranstaltungen des Fanclubs</li>
                <li> Beitragseinzug</li>
                <li> Berichterstattung (ggf. auch mit Fotos und Videos) über das Vereins- und Fanclubleben</li>
            </ul>
            <h3>4. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h3>

            <h4><i>a) Erfüllung eines Vertrages (Art. 6 Abs. 1 b) DS-GVO)</i></h4>
            <p>
                Die Verarbeitung Ihrer personenbezogenen Daten (Pflichtangaben laut Mitgliedsantrag) sind erforderlich,
                um unseren Pflichten aus dem Mitgliedschaftsverhältnis nachkommen zu können.

                Die Verarbeitung folgender Daten ist erforderlich, um Sie beim FC Bayern München in der
                Fanclubmitgliedverwaltung zu melden.
            </p>
            <ul>
                <li> Name</li>
                <li> Vorname</li>
                <li> Anschrift (Straße/Hausnummer)</li>
                <li> PLZ</li>
                <li> Ort</li>
                <li> Land</li>
                <li> Geburtsdatum</li>
                <li> Telefonnummer</li>
                <li> Handynummer</li>
                <li> E-Mail-Adresse</li>
            </ul>

            <h4><i>b) Einwilligung (Art. 6 Abs. 1 a) DS-GVO)</i></h4>

            <p> Für personenbezogene Daten, deren Verarbeitung nicht zur Erfüllung eines Vertrages erforderlich ist,
                holen wir
                grundsätzlich Ihre Einwilligung ein – bei Aufnahme also die Angaben im Aufnahmeantrag zu den Punkten</p>
            <ul>
                <li> Freiwillige Angaben zur Kontaktaufnahme</li>
                <li> Einwilligung zur Weitergabe der Personen- und Kontaktdaten an den FC Bayern München</li>
                <li> Einwilligung in die Veröffentlichung von Personenbildnissen</li>
                <li> Einwilligung in die Veröffentlichung von Personendaten</li>
                <li> Einwilligung zur Teilnahme am Newsletter-Empfang/Marketingmaßnahmen</li>
                <li> SEPA-Lastschriftmandat für SEPA-Basislastschrift (SDD)</li>
            </ul>
            <h4><i>c) Wahrung berechtigter Interessen des Vereins (Art. 6 Abs. 1 f) DS-GVO)</i></h4>

            Zur Wahrung der berechtigten Interessen des Fanclubs werden Ihre personenbezogenen Daten in folgenden Fällen
            verarbeitet:
            <ul>
                <li> Fertigung von Foto- und Videoaufnahmen von zur Person im Rahmen der Teilnahme an öffentlichen
                    Veranstaltungen des
                    Vereins/Fanclubs und der Veröffentlichung im Rahmen der Öffentlichkeitsarbeit.
                </li>
            </ul>
            <p className="underline">Das berechtigte Interesse des Fanclubs besteht</p>
            <ul>
                <li> in der Information der Öffentlichkeit durch Berichterstattung über die Aktivitäten des Vereins/des
                    Fanclubs
                </li>
                <li> in der Erleichterung der Kommunikation zwischen Ihnen und dem Verein (E-Mail-Adresse/Telefon)</li>
            </ul>
            <h3>5. Die Empfänger oder Kategorien von Empfängern der personenbezogenen Daten</h3>
            <ul>
                <li> Verwaltung der Vereinstätigkeiten bzw. Durchführung des Mitgliedschaftsverhältnisses</li>
                <li> Mitglieder des Vorstandes</li>
                <li> Schriftführer (Mitgliederverwaltung)</li>
                <li> Telefonnummer ggf. an andere Vereinsmitglieder</li>
                <li> Teilnahme an Veranstaltungen</li>
                <li>Kartenbeauftragter (Kontaktdaten: E-Mail/Telefon)</li>
                <li>Kassierer</li>
                <li> Beitragseinzug</li>
                <li>Volks- und Raiffeisenbank Hohenfels (Bankverbindung)</li>
                <li>Kassierer</li>
                <li>Schriftführer (Mitgliederverwaltung)</li>
            </ul>

            <h3>6. Dauer der Speicherung / Kriterien für die Festlegung der Dauer</h3>
            <p>
                Die personenbezogenen Daten werden grundsätzlich für die Dauer der Mitgliedschaft gespeichert.
                Nach Beendigung der Vereinsmitgliedschaft werden die Telefonnummer sowie die Bankdaten und die
                E-Mail-Adresse/Telefonnummer/Login-Daten unverzüglich (spätestens 1 Monat) nach Beendigung der
                Vereinsmitgliedschaft gelöscht.
                Die Postanschrift wird grundsätzlich 3 Jahre nach Beendigung der Fanclubmitgliedschaft (Ende des
                Kalenderjahres) gelöscht.
                Name, Vorname, Geschlecht und Geburtsdatum werden grundsätzlich 10 Jahre nach Beendigung der
                Vereinsmitgliedschaft gelöscht (gesetzliche Aufbewahrungsfristen zu steuerlichen Zwecken).
                Bilder werden über die Beendigung der Mitgliedschaft zu Archivzwecken (vereinsintern, Vereinschronik)
                weiter vorgehalten.
            </p>
            <h3>7. Soweit die in den jeweiligen Vorschriften beschriebenen Voraussetzungen
                vorliegen, hat jedes Vereinsmitglied insbesondere die folgenden Rechte</h3>
            <ul>
                <li> das Recht auf Auskunft nach Artikel 15 DS-GVO;</li>
                <li> das Recht auf Berechtigung nach Artikel 16 DS-GVO;</li>
                <li> das Recht auf Löschung nach Artikel 17 DS-GVO;</li>
                <li> das Recht auf Einschränkung der Verarbeitung nach Artikel 18 DS-GVO;</li>
                <li> das Recht auf Datenübertragbarkeit nach Artikel 20 DS-GVO;</li>
                <li> das Widerspruchsrecht nach Artikel 21 DS-GVO;</li>
                <li> das Recht auf Beschwerde bei einer Aufsichtsbehörde nach Artikel 77 DS-GVO;</li>
                <li> das Recht, eine erteilte Einwilligung jederzeit widerrufen zu können, ohne dass die Rechtmäßigkeit,
                    der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung hierdurch berührt wird.
                </li>
            </ul>
            <h3>8. Die Quelle, aus der Ihre personenbezogenen Daten stammen</h3>
            <p>
                Wir verarbeiten grundsätzlich nur personenbezogene Daten, die wir im Rahmen (des
                Erwerbs) der Mitgliedschaft direkt bei Ihnen erheben.</p>

        </div>
    )
}

export default withRouter(DatenverarbeitungSeite);
