export default class PDFJs {


    public init = (source: string, element: any) => {
        element.innerHTML = "";
        const iframe = document.createElement('iframe');
        const url = window.location.origin;

        iframe.src = `/pdfjs-2.7.570-dist/web/viewer.html?file=${url}${source}#zoom=page-width&pagemode=none`;
        iframe.width = '100%';
        iframe.height = '880px';

        element.appendChild(iframe);

    }
}