import {useEffect} from "react";
import {withRouter} from "react-router-dom";

function WebArchive() {

    useEffect(() => {
        let bg = document.getElementById("bg");
        if (bg !==   undefined && bg !== null )
            bg.className = 'hohenfels';
        document.location.href = "https://archive.fcb-hohenfels.de";
    }, []);
    return (
        <div className="content">
            Seite wird geladen...
        </div>
    )
}

export default withRouter(WebArchive);
