import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.material-purple.css';
import JqxGrid, {jqx} from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';
import {render} from "react-dom";
import JqxButton from "jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons";
import {DropzoneDialog} from "material-ui-dropzone";
import {getWindowDimensions, TabPanel, useStyles} from "../App";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs} from "@material-ui/core";
import {setEditGridDialogOpen} from "../reducers/userReducer";
import {useDispatch} from "react-redux";

function a11yProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
        tabIndex: index
    };
}

const AppGridView = (props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [bankExportDialog, setBankExportDialog] = React.useState(false);
    const [constructorHasRun, setConstructorHasRun] = useState(false);
    // const [columns, setColumns] = useState();
    // const [dataSource, setDataSource] = useState();
    const [value3, setValue3] = React.useState(0);



    const getLocalization = (): any => {
        const localizationobj: any = {
            firstDay: 1,
            emptydatastring: "Keine Daten vorhanden",
            percentsymbol: "%",
            currencysymbol: "€",
            currencysymbolposition: "after",
            decimalseparator: ',',
            thousandsseparator: '.',
            pagergotopagestring: "Gehe zu:",
            pagershowrowsstring: "Zeige Zeile:",
            pagerrangestring: " von ",
            pagerpreviousbuttonstring: "voriger",
            pagernextbuttonstring: "n&auml;chster",
            groupsheaderstring: "Ziehen Sie eine Spalte hierher um eine Gruppe zu erstellen",
            sortascendingstring: "Sortiere aufsteigend",
            sortdescendingstring: "Sortiere absteigend",
            sortremovestring: "Entferne Sortierung",
            groupbystring: "Gruppiere nach dieser Spalte",
            groupremovestring: "Entferne Gruppierung",
            filterclearstring: "Aufheben",
            filterstring: "Filter",
            filtershowrowstring: "Zeige Zeilen wo:",
            filterorconditionstring: "Oder",
            filterandconditionstring: "Und",
            filterstringcomparisonoperators: ['leer', 'nicht leer', 'enth&auml;lt', 'enth&auml;lt(Klein-/Großschreibung beachten)',
                'enth&auml;lt nicht', 'enth&auml;lt nicht(Klein-/Großschreibung beachten)', 'startet mit', 'startet mit(Klein-/Großschreibung beachten)',
                'endet mit', 'endet mit(Klein-/Gro&szlig;schreibung beachten)', 'gleich', 'gleich(Klein-/Gro&szlig;schreibung beachten)', 'undefiniert', 'nicht undefiniert'],
            filternumericcomparisonoperators: ['gleich', 'ungleich', 'kleiner als', 'kleiner gleich', 'gr&ouml;&szlig;er als', 'gr&ouml;&szlig;er gleich', 'undefiniert', 'nicht undefiniert'],
            filterdatecomparisonoperators: ['gleich', 'ungleich', 'früher als', 'früher gleich', 'sp&auml;ter als', 'sp&auml;ter gleich', 'undefiniert', 'nicht undefiniert'],
            validationstring: "Der eingegebene Wert ist nicht gültig",
            AM: ["Vormittag", "vormittag", "Vormittag"],
            PM: ["Nachmittag", "nachmittag", "Nachmittag"],
            eras: [
                // eras in reverse chronological order.
                // name: the name of the era in this culture (e.g. A.D., C.E.)
                // start: when the era starts in ticks (gregorian, gmt), null if it is the earliest supported era.
                // offset: offset in years from gregorian calendar
                {"name": "n. Chr.", "start": null, "offset": 0}
            ],
            twoDigitYearMax: 2100,
        };
        const days = {
            // full day names
            names: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            // abbreviated day names
            namesAbbr: ['Sonn', 'Mon', 'Dien', 'Mitt', 'Donn', 'Fre', 'Sams'],
            // shortest day names
            namesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
        };
        localizationobj.days = days;
        const months = {
            // full month names (13 months for lunar calendards -- 13th month should be '' if not lunar)
            names: ['Januar', 'Februar', 'M&auml;rz', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember', ''],
            // abbreviated month names
            namesAbbr: ['Jan', 'Feb', 'M&auml;r', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez', '']
        };
        const patterns = {
            // short date pattern
            d: "dd.MM.yyyy",
            // long date pattern
            D: "dddd, am dd.MM.yyyy",
            // short time pattern
            t: "hh:mm tt",
            // long time pattern
            T: "hh:mm:ss tt",
            // long date, short time pattern
            f: "dddd, MMMM dd, yyyy h:mm tt",
            // long date, long time pattern
            F: "dddd, MMMM dd, yyyy h:mm:ss tt",
            // month/day pattern
            M: "MMMM dd",
            // month/year pattern
            Y: "yyyy MMMM",
            // S is a sortable format that does not vary by culture
            S: "yyyy\u0027-\u0027MM\u0027-\u0027dd\u0027T\u0027HH\u0027:\u0027mm\u0027:\u0027ss"
        };
        localizationobj.patterns = patterns;
        localizationobj.months = months;
        return localizationobj;
    };

    const toolbarButtonClick = (key: string) => {
        switch (key) {
            case "add":
                    // addRowData(true, {});
                break;
            case "edit":
                    // const selected = gridView.current!.getselectedrowindex();
                    // let rowData = gridView.current!.getrowdata(selected);
                    // editRowData(true, rowData);

                break;
            case "import":
                if (props.setImportDialog !== undefined)
                    props.setImportDialog(true);
                break;
            case "export":
                alert("export");
                break;
            case "bankExport":
                setBankExportDialog(true);
                break;
        }
    };

    const importUserData = (files: File[], event: React.SyntheticEvent) => {
        if (props.onImportData !== undefined) {
            props.onImportData(files);
        }
    };

    const closeImportDialog = () => {
        if (props.setImportDialog !== undefined)
            props.setImportDialog(false);
    };

    const rendertoolbar = (toolbar: any): void => {
        const style: CSSProperties = {float: 'left', marginLeft: '5px'};
        const buttonsContainer = (

            <div style={{overflow: 'hidden', position: 'relative', margin: '5px'}}>
                {props.hasAddNewRight && <div id={'addButton'} style={style}>
                    <JqxButton onClick={() => toolbarButtonClick('add')}
                               width={80} height={25} value={'Neu'} imgSrc={'./../images/addUser.png'}
                               imgPosition={'center'} textPosition={'center'} textImageRelation={'imageBeforeText'}/>
                </div>}
                {props.hasEditRight && <div id={'editButton'} style={style}>
                    <JqxButton onClick={() => toolbarButtonClick('edit')}
                               width={110} height={25} value={'Bearbeiten'} imgSrc={'./../images/editData.png'}
                               imgPosition={'center'} textPosition={'center'} textImageRelation={'imageBeforeText'}/>
                </div>}
                {props.hasImportRight && <div id={'importButton'} style={style}>
                    <JqxButton onClick={() => toolbarButtonClick('import')}
                               width={110} height={25} value={'Importieren'} imgSrc={'./../images/importData.png'}
                               imgPosition={'center'} textPosition={'center'} textImageRelation={'imageBeforeText'}/>
                </div>}
                {props.hasExportRight && <div id={'exportButton'} style={style}>
                    <JqxButton onClick={() => toolbarButtonClick('export')}
                               width={110} height={25} value={'Exportieren'} imgSrc={'./../images/exportData.png'}
                               imgPosition={'center'} textPosition={'center'} textImageRelation={'imageBeforeText'}/>
                </div>}
                {props.hasExportBankDataRight && <div id={'bankExportButton'} style={style}>
                    <JqxButton onClick={() => toolbarButtonClick('bankExport')}
                               width={190} height={25} value={'Kontodaten exportieren'}
                               imgSrc={'./../images/exportBankData.png'}
                               imgPosition={'center'} textPosition={'center'} textImageRelation={'imageBeforeText'}/>
                </div>}
            </div>
        );
        render(buttonsContainer, toolbar[0]);
    };

    useEffect(() => {
        //  createButtons();
    }, []);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const editUserTabIndexChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue3(newValue);
    };

    // const addRowData = (openDialog: boolean, gridView: JqxGrid, rowData: any) => {
    //     dispatch(setEditGridDialogOpen(openDialog));
    // }
    //
    // const editRowData = (openDialog: boolean, gridView: JqxGrid, rowData: any) => {
    //     dispatch(setEditGridDialogOpen(openDialog));
    // }

    let source = {
        datatype: 'array',
        localdata: props.data
    };
    let data = new jqx.dataAdapter(source);

    // const constructor = () => {
    //     if (constructorHasRun) return;
    //    // setColumns(props.columns);
    //     let source = {
    //         datatype: 'array',
    //         localdata: props.data
    //     };
    //     let data = new jqx.dataAdapter(source);
    //   //  setDataSource(data);
    //     setConstructorHasRun(true);
    // };
    //
    // constructor();
    return (
        <aside className={classes.aside}>
            <JqxGrid
                width={windowDimensions.width - 285} height={"700px"}
                columns={props.columns}
                source={data}
                pageable={true}
                pagesize={500}
                pagesizeoptions={[10,25,50,100,250,500]}
                autoheight={false}
                filterable={true}
                sortable={true}
                columnsresize={true}
                showfilterrow= {true}
                altrows={true}
                enabletooltips={true}
                groupable={true}
                editable={false}
                showtoolbar={true}
                rendertoolbar={rendertoolbar}
                selectionmode="singlerow"
                localization={getLocalization()}
            />
            {props.openImportDialog &&
            <DropzoneDialog
                cancelButtonText="Abbrechen"
                submitButtonText="Import starten"
                dialogTitle="Benutzerdaten importieren"
                previewText="Dateiübersicht"
                filesLimit={1}
                showFileNames={true}
                dropzoneText="Klicken oder Datei hier ablegen"
                open={props.openImportDialog}
                onSave={importUserData.bind(this)}
                acceptedFiles={['.xlsx, .xls']}
                showPreviews={true}
                showAlerts={false}
                maxFileSize={5000000}
                onClose={closeImportDialog.bind(this)}
            />
            }
            {props.openDialog && <Dialog
                disableBackdropClick
                fullWidth={true}
                aria-labelledby="user-edit-dialog"
                open={props.openDialog}
            >
                <DialogTitle id="user-edit-dialog">Benutzerdaten bearbeiten</DialogTitle>
                <DialogContent>
                    <div>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            indicatorColor="secondary"
                            value={value3}
                            onChange={editUserTabIndexChange}
                            aria-label="Vertical tabs example"
                            className={classes.tabs}
                        >
                            {['Stammdaten',
                                'Zustimmungen',
                                'Bankdaten',
                                'Adresse',
                                'Kontaktdaten'].map((value: string, index: number) => {
                                return <Tab
                                    label={value} {...a11yProps({index})} />
                            })
                            }
                        </Tabs>
                        <TabPanel index={value3} value={0}>
                            <h3>Stammdaten</h3>
                        </TabPanel>
                        <TabPanel index={value3} value={1}>
                            <h3>Zustimmungen</h3>
                        </TabPanel>
                        <TabPanel index={value3} value={2}>
                            <h3>Bankdaten</h3>
                        </TabPanel>
                        <TabPanel index={value3} value={3}>
                            <h3>Adresse</h3>
                        </TabPanel>
                        <TabPanel index={value3} value={4}>
                            <h3>Kontaktdaten</h3>
                        </TabPanel>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {

                    }} color="primary">
                        Benutzerdaten speichern
                    </Button>
                    <Button onClick={() => {
                        dispatch(setEditGridDialogOpen(false));
                    }} color="primary">
                        Abbrechen
                    </Button>
                </DialogActions>
            </Dialog>}
        </aside>
    )

}


export default AppGridView;
