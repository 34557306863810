import React, {createRef, useEffect} from 'react';

import PDFJSBackend from '../components/pdfjs';
import "../sass/pdfViewer.sass";

const PDFViewer = (props: any) => {

    const viewerRef = createRef<HTMLDivElement>();


    useEffect(() => {
        console.log(viewerRef.current);
        const b = new PDFJSBackend();
        b.init(props.source, viewerRef.current);

    }, [viewerRef, props.source]);

        return (
            <div ref={viewerRef} id='viewer' style={{maxWidth: '800px', maxHeight: '880px'}}>

            </div>
        )

}

export default PDFViewer;
