import {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logout} from "../reducers/userReducer";

function AbmeldeSeite() {

    const dispatch = useDispatch();

    dispatch(logout());

    useEffect(() => {
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            bg.className = 'hohenfels';
        window.location.href = "#/aktuelles";
    }, []);
    return (
        <div className="content">
            Du wirst abgemeldet und zur Startseite weitergeleitet...
        </div>
    )
}

export default withRouter(AbmeldeSeite);
