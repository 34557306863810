import * as data from "./device.json";

export  const api: any = {
    server: {
        url: 'https://www.fcb-hohenfels.de/php'
    },
    local: {
        url: 'http://localhost:8888/htdocs/fcb-hohenfels/php'
    },
    user: () : any => {

        switch (data.Name) {
            case "local":
                return {
                    userName: '',
                    password: ''
                };
            default:
                return null;
        }
    },
    url: (): string => {

        switch (data.Name) {
            case "server":
                return api.server.url;
            default:
                return api.local.url;
        }
    },
    pdfPath:(): string => {

        switch (data.Name) {
            case "server":
                return api.server.url.replace("https://www.fcb-hohenfels.de/php","/pdf/");
            default:
                return api.local.url.replace("http://localhost:8888/htdocs/fcb-hohenfels/php","/htdocs/fcb-hohenfels/pdf/");
        }
    }
};
