import {call, fork, put, select, takeLatest} from "redux-saga/effects";
import {
    checkRegistrationData,
    checkSecureCode,
    confirmRegistration,
    confirmRegistrationData,
    currentUserData,
    currentUserDataSucceded,
    getActiveUsers, getActiveUsersCount,
    getQrCodeUrlValue,
    getUserEvents,
    getUserEventsSucceded,
    login,
    loginFailed,
    loginSucceded,
    logout,
    newPassword,
    newRegistration,
    resetUseTokenData,
    resetUseTokenSucceded,
    saveEditUserData,
    saveUserAuthData,
    saveUserBaseData,
    saveUserContactData,
    saveUserPaymentData,
    setActiveUsersData, setActiveUsersDataCount,
    setLoginData,
    setNewPasswordData,
    setQrCodeUrlAndToken,
    showTokenDialog,
    startImportUserData
} from "../reducers/userReducer";
import {AppState} from "../app/appState";
import {CurrentUser, EditUser, EventData, LoginData, UserData} from "../app/dto/userData";
import {AxiosResponse} from "axios";
import * as service from "../app/axiosService";
import {
    addUserToEvent, getAllEvents,
    getEventByType,
    registerToEvent, setAllEvents,
    setAppData,
    showFailedSnackbar,
    showSuccededSnackbar
} from "../reducers/appReducer";
import {AppData, RegisterEvent} from "../app/dto/appData";
import {addUserLoginToken} from "../app/axiosService";

function* userLogin() {
    try {
        const loginData: LoginData = yield select((state: AppState) => state.userData.currentUser.Login);
        const response: AxiosResponse = yield call(service.userLogin(loginData));
        let data = response.data;
        if (data.GetUserByLoginDataError !== undefined && data.GetUserByLoginDataError.length > 0 && data.Result.Id === null) {
            yield put(showFailedSnackbar({message: "Deine Anmeldung konnte nicht abgeschlossen werden, bitte überprüfe deine Angaben noch einmal oder wende dich an die Mitgliederverwaltung unter mitgliedschaft@fcb-hohenfels.de!"}));
            yield put(loginFailed(data.GetUserByLoginDataError));
        } else if (data.UserRegistration) {
            if (data.Result.IsMember === true) {
                yield put(showSuccededSnackbar({message: `Wir haben dir eine E-Mail zugesandt. Bitte schließe die Registrierung nun ab, indem du den Link in der Email-Nachricht aufrufst/anklickst.`}));
            } else {
                yield put(showFailedSnackbar({message: "Wir konnten deine Fanclubmitgliedschaft nicht bestätigen. Bitte wende dich an die Mitgliederverwaltung unter mitgliedschaft@fcb-hohenfels.de!"}));
            }
        } else if (data.SendNewPassword) {
            if (data.Result.IsMember === true) {
                yield put(showSuccededSnackbar({message: `Wir haben dir eine E-Mail zugesandt. Bitte setze dein Passwort neu, indem du den Link in der Email-Nachricht aufrufst/anklickst.`}));
            } else {
                yield put(showFailedSnackbar({message: "Wir konnten deine Fanclubmitgliedschaft nicht bestätigen. Bitte wende dich an die Mitgliederverwaltung unter mitgliedschaft@fcb-hohenfels.de!"}));
            }
        } else if (data.Result.UseToken === true) {
            yield put(loginSucceded(data.Result));
            yield put(showTokenDialog(data.Result));
        } else if (data.Result.Id !== null && data.Result.Id !== "" && data.GetUserByLoginData === true) {
            yield put(showSuccededSnackbar({
                message: `Hallo, ${data.Result.FirstName} ${data.Result.LastName}, Willkommen auf deiner Fanclub-Seite! Du kannst jetzt den Mitgliederbereich nutzen.`
            }));
            yield put(loginSucceded(data.Result));
            yield put(getUserEvents());
            yield put(setLoginData({key: "loginName", value: ""}));
            yield put(setLoginData({key: "registrationPassword", value: ""}));
            yield put(setLoginData({key: "confirmRegistration", value: false}));
        } else if ((data.UserRegistration !== undefined && data.UserRegistration === false) ||
            (data.SendNewPassword !== undefined && data.SendNewPassword === false)) {
            yield put(showFailedSnackbar({message: "Wir konnten deine Fanclubmitgliedschaft nicht bestätigen. Bitte wende dich an die Mitgliederverwaltung unter mitgliedschaft@fcb-hohenfels.de!"}));
        }
    } catch (e) {
        console.log(e);
    }
}

function* watchUserLogin() {
    yield takeLatest(login.type, userLogin)
}

function* setNewPassword() {
    const userData: UserData = yield select((state: AppState) => state.userData);
    const currentUser: CurrentUser = userData.currentUser;
    let key = currentUser.Id;
    const response: AxiosResponse = yield call(service.setNewPassword(key, currentUser.Login.newLoginPassword));
    let data = response.data;
    console.log(data);
    if (data.SetNewPassword) {
        yield put(showSuccededSnackbar({message: "Dein Kennwort wurde neu gesetzt. Du kannst dich nun ab sofort mit deinem neuen Kennwort im Mitgliederbereich anmelden."}));
        yield put(setLoginData({key: "newLoginPassword", value: ""}));
        yield put(setLoginData({key: "newLoginPasswordConfirmed", value: ""}));
        yield put(setLoginData({key: "loginName", value: ""}));
        yield put(setLoginData({key: "setNewPassword", value: false}));
    } else {
        yield put(showFailedSnackbar({message: "Deine Kennwortänderung konnte nicht übernommen werden. Wende dich bitte an mitgliedschaft@fcb-hohenfels.de."}));
    }
}

function* watchSetNewPassword() {
    yield takeLatest(setNewPasswordData.type, setNewPassword)
}

function* confirmRegistrations() {
    const userData: UserData = yield select((state: AppState) => state.userData);
    const currentUser: CurrentUser = userData.currentUser;
    let $key = currentUser.Id;
    let $pwd = currentUser.Login.registrationPassword;
    const response: AxiosResponse = yield call(service.confirmRegistration($key, $pwd));
    let data = response.data;
    // console.log(data);
    if (data.ConfirmUserRegistration && data.Result.Id !== null) {
        yield put(showSuccededSnackbar({message: "Deine Benutzerregistrierung ist nun abgeschlossen. Du kannst dich nun ab sofort im Mitgliederbereich anmelden."}));
    } else if (data.ConfirmUserRegistration && data.Result.Id === null) {
        yield put(showFailedSnackbar({message: "Deine Benutzerregistrierung konnte nicht abgeschlossen werden. Wende dich bitte an mitgliedschaft@fcb-hohenfels.de."}));
    }
}

function* watchConfirmRegistrations() {
    yield takeLatest(confirmRegistrationData.type, confirmRegistrations)
}

function* isRegistrationValid() {
    const userData: UserData = yield select((state: AppState) => state.userData);
    const currentUser: CurrentUser = userData.currentUser;
    const response: AxiosResponse = yield call(service.getRegistrationData(currentUser.Id));
    let data = response.data;
    // console.log(data);
    if (data.UserRegistration && data.Result.Id === null) {
        yield put(showFailedSnackbar({message: "Deine Benutzerregistrierung ist leider abgelaufen. Du musst die Registrierung erneut vornehmen. Denke bitte daran, das der Bestätigungslink nur 24 Stunden gilt."}));
    } else if (data.UserRegistration && data.Result.Id !== null) {
        yield put(confirmRegistration({confirmRegistration: true, key: currentUser.Id}));
    }

}

function* watchIsRegistrationValid() {
    yield takeLatest(checkRegistrationData.type, isRegistrationValid)
}

function* loadCurrentUserData() {
    const userData: UserData = yield select((state: AppState) => state.userData);
    const currentUser: CurrentUser = userData.currentUser;
    //  console.log(currentUser);

    const response: AxiosResponse = yield call(service.GetUserBaseDataById(currentUser.Id));
    let data = response.data;
    console.log(data);
    let user = response.data.Result;
    console.log(user);
    for (let name in currentUser) {
        if (user[name] === undefined) {
            // @ts-ignore
            user[name] = currentUser[name];
        }
    }

    // console.log(data);

    if (data.GetUserBaseDataById && user.Id === null) {
        yield put(showFailedSnackbar({message: "Deine Benutzerdaten konnten nicht geladen werden."}));
    } else if (data.GetUserBaseDataById && user.Id !== null) {
        yield put(currentUserDataSucceded(user));
    } else if (data.GetUserBaseDataByIdError.length > 0) {
        yield put(showFailedSnackbar({message: data.GetUserBaseDataByIdError}));
    }

}

function* watchLoadCurrentUserData() {
    yield takeLatest(currentUserData.type, loadCurrentUserData)
}

function* saveUserBaseDataChanged() {
    const userData: UserData = yield select((state: AppState) => state.userData);
    const currentUser: CurrentUser = userData.currentUser;

    //   console.log(currentUser);

    const response: AxiosResponse = yield call(service.saveUserBaseData(currentUser));
    let data = response.data;

    // console.log(data);

    if (data.SaveUserBaseData === true) {
        yield put(showSuccededSnackbar({message: "Deine Benutzerstammdaten wurden gespeichert."}));
    } else {
        yield put(showFailedSnackbar({message: "Deiene Benutzerdaten konnten nicht gespeichert wern. Wende dich bitte an mitgliedschaft@fcb-hohenfels.de."}));
    }
}

function* watchSaveUserBaseDataChanged() {
    yield takeLatest(saveUserBaseData.type, saveUserBaseDataChanged)
}

function* saveUserContactDataChanged() {
    const userData: UserData = yield select((state: AppState) => state.userData);
    const currentUser: CurrentUser = userData.currentUser;

    //  console.log(currentUser);

    const response: AxiosResponse = yield call(service.saveUserContactData(currentUser));
    let data = response.data;

    // console.log(data);

    if (data.SaveUserContactData === true) {
        yield put(showSuccededSnackbar({message: "Deine Kontaktdaten wurden gespeichert."}));
    } else {
        yield put(showFailedSnackbar({message: "Deiene Kontaktdaten konnten nicht gespeichert werden. Wende dich bitte an mitgliedschaft@fcb-hohenfels.de."}));
    }
}

function* watchSaveUserContactDataChanged() {
    yield takeLatest(saveUserContactData.type, saveUserContactDataChanged)
}

function* getQrCodeUrlValueData() {
    const userData: UserData = yield select((state: AppState) => state.userData);
    const currentUser: CurrentUser = userData.currentUser;
    //  console.log(currentUser);
    const response: AxiosResponse = yield call(service.getQrCodeUrlValue(currentUser));
    let data = response.data;
    console.log(data);
    if (data.GetQrCodeUrlValueData === true) {
        data.Result.UseToken = currentUser.Login.twoFactoryAuthentication.useToken;
        yield put(setQrCodeUrlAndToken(data.Result));
    } else {
        yield put(setQrCodeUrlAndToken({Url: "", Token: ""}));
    }
}

function* watchGetQrCodeUrlValueData() {
    yield takeLatest(getQrCodeUrlValue.type, getQrCodeUrlValueData)
}

function* saveUserPaymentDataChanged() {
    const userData: UserData = yield select((state: AppState) => state.userData);
    const currentUser: CurrentUser = userData.currentUser;

    //  console.log(currentUser);

    const response: AxiosResponse = yield call(service.saveUserPaymentData(currentUser));
    let data = response.data;

    //  console.log(data);

    if (data.SaveUserPaymentData === true) {
        yield put(showSuccededSnackbar({message: "Deine Bankverbindungsdaten wurden gespeichert."}));
    } else {
        yield put(showFailedSnackbar({message: "Deiene Bankverbindungsdaten konnten nicht gespeichert wern. Wende dich bitte an mitgliedschaft@fcb-hohenfels.de."}));
    }
}

function* watchSaveUserPaymentDataChanged() {
    yield takeLatest(saveUserPaymentData.type, saveUserPaymentDataChanged)
}

function* checkSecureCodeData() {
    const userData: UserData = yield select((state: AppState) => state.userData);
    const currentUser: CurrentUser = userData.currentUser;

    const response: AxiosResponse = yield call(service.checkSecureCodeData(currentUser));
    let data = response.data;

    if (data === true) {
         yield put(showSuccededSnackbar({message: "Der eingegebene Code ist gültig."}));
    } else {
        yield put(showFailedSnackbar({message: "Der eingegebene Code ist ungültig. Bitte aktualisiere die Seite [F5] und entferne die Registrierung in deiner Authentifizierungs-App. Scanne den QR Code neu und wiederhole den Prozess. Bei Fragen wende dich bitte an webadmin@fcb-hohenfels.de!"}));
    }
}

function* watchCheckSecureCodeData() {
    yield takeLatest(checkSecureCode.type, checkSecureCodeData);
}

function* getUserEventsData() {
    const userData: UserData = yield select((state: AppState) => state.userData);
    const currentUser: CurrentUser = userData.currentUser;
    const response: AxiosResponse = yield call(service.getUserEvents(currentUser.Id));
    let data = response.data;
    yield put(getUserEventsSucceded({Events: data}));
}

function* watchGetUserEventsData() {
    yield takeLatest(getUserEvents.type, getUserEventsData);
}

function* resetUseToken() {
    const userData: UserData = yield select((state: AppState) => state.userData);
    const currentUser: CurrentUser = userData.currentUser;

    //  console.log(currentUser);

    const response: AxiosResponse = yield call(service.resetUseTokenData(currentUser));
    let data = response.data;

    //  console.log(data);

    if (data.Result === true) {
        yield put(showSuccededSnackbar({message: "Die Änderungen wurden gespeichert."}));
        yield put(resetUseTokenSucceded({UseToken: false}));
        yield put(getQrCodeUrlValue());
    } else {
        yield put(showFailedSnackbar({message: "Die Änderungen konnten nicht gespeichert werden!"}));
    }
}

function* watchResetUseTokenData() {
    yield takeLatest(resetUseTokenData.type, resetUseToken);
}

function* importUserData() {
    console.log("importUserData");
    const userData: UserData = yield select((state: AppState) => state.userData);
    const appData: AppData = yield select((state: AppState) => state.appData);
    yield put(setAppData({key: "openImportDialog", value: false}));
    const response: AxiosResponse = yield call(service.importUserData(userData.importFile));
    let data = response.data;
    console.log(data);
    if (data.ImportUserData === true) {
        yield put(showSuccededSnackbar({message: "Die Datei wurde erfolgreich importiert."}));
    } else {
        yield put(showFailedSnackbar({message: "Die Datei konnte nicht importiert werden!"}));
    }
    //  console.log(data);
}

function* watchImportUserData() {
    yield takeLatest(startImportUserData.type, importUserData);
}

function* getActiveUsersData() {
    const response: AxiosResponse = yield call(service.getActiveUsersData());
    let data = response.data;
    console.log(data);
    if (data.GetActiveUsers) {
        yield put(setActiveUsersData(data.Result));
    } else {
        yield put(showFailedSnackbar({message: "Benutzerliste konnte nicht geladen werden!"}));
    }

}

function* watchGetActiveUsersData() {
    yield takeLatest(getActiveUsers.type, getActiveUsersData);
}

function* getActiveUsersDataCount() {
    const response: AxiosResponse = yield call(service.getActiveUsersDataCount());
    let data = response.data;
    console.log(data);
    if (data.GetActiveUsersCount) {
        yield put(setActiveUsersDataCount(data.Result));
    } else {
        yield put(showFailedSnackbar({message: "Anzahl der Benutzer konnte nicht ermittelt werden!"}));
    }

}

function* watchGetActiveUsersDataCount() {
    yield takeLatest(getActiveUsersCount.type, getActiveUsersDataCount);
}

function* getNewPassword() {
    try {
        const loginData: LoginData = yield select((state: AppState) => state.userData.currentUser.Login);
        const response: AxiosResponse = yield call(service.sendNewUserPassword(loginData));
        let data = response.data;
        if (data.SendNewPassword) {
            if (data.Result.IsMember === true) {
                yield put(showSuccededSnackbar({message: `Wir haben dir eine E-Mail zugesandt. Bitte setze dein Passwort neu, indem du den Link in der Email-Nachricht aufrufst/anklickst.`}));
            } else {
                yield put(showFailedSnackbar({message: "Wir konnten deine Fanclubmitgliedschaft nicht bestätigen. Bitte wende dich an die Mitgliederverwaltung unter mitgliedschaft@fcb-hohenfels.de!"}));
            }
        }
    } catch (e) {
        console.log(e);
    }
}

function* watchGetNewPassword() {
    yield takeLatest(newPassword.type, getNewPassword);
}

function* getNewRegistration() {
    try {
        const loginData: LoginData = yield select((state: AppState) => state.userData.currentUser.Login);
        const response: AxiosResponse = yield call(service.sendNewUserRegistration(loginData));
        let data = response.data;
        if (data.UserRegistration) {
            if (data.Result.IsMember === true) {
                yield put(showSuccededSnackbar({message: `Wir haben dir eine E-Mail zugesandt. Bitte schließe die Registrierung nun ab, indem du den Link in der Email-Nachricht aufrufst/anklickst.`}));
            } else {
                yield put(showFailedSnackbar({message: "Wir konnten deine Fanclubmitgliedschaft nicht bestätigen. Bitte wende dich an die Mitgliederverwaltung unter mitgliedschaft@fcb-hohenfels.de!"}));
            }
        } else if ((data.UserRegistration !== undefined && data.UserRegistration === false) ||
            (data.SendNewPassword !== undefined && data.SendNewPassword === false)) {
            yield put(showFailedSnackbar({message: "Wir konnten deine Fanclubmitgliedschaft nicht bestätigen. Bitte wende dich an die Mitgliederverwaltung unter mitgliedschaft@fcb-hohenfels.de!"}));
        }
    } catch (e) {
        console.log(e);
    }
}

function* watchGetNewRegistration() {
    yield takeLatest(newRegistration.type, getNewRegistration);
}

function* saveEditUserDataChanged() {
    const userData: UserData = yield select((state: AppState) => state.userData);
    const editUser: EditUser = userData.editUser;

    console.log(editUser);

    let paymentSave = true;
    const responseBaseData: AxiosResponse = yield call(service.saveUserBaseData(editUser));
    const responseContactData: AxiosResponse = yield call(service.saveUserContactData(editUser));
    if (editUser.BIC.length > 0 && editUser.IBAN.length > 0) {
        const responsePaymentData: AxiosResponse = yield call(service.saveUserPaymentData(editUser));
        let paymentData = responsePaymentData.data
        paymentSave = paymentData.SaveUserPaymentData;
    }
    const responseTokenData: AxiosResponse = yield call(service.resetUseTokenData(editUser));
    let baseData = responseBaseData.data;
    let contactData = responseContactData.data;
    let tokenData = responseTokenData.data;

    let saved = baseData.SaveUserBaseData &&
        contactData.SaveUserContactData &&
        paymentSave &&
        tokenData.ResetUseTokenByUserId;

    if (saved === true) {
        yield put(showSuccededSnackbar({message: "Die Benutzerstammdaten wurden gespeichert."}));
    } else {
        yield put(showFailedSnackbar({message: "Die Benutzerdaten konnten nicht gespeichert werden. Wende dich bitte an webadmin@fcb-hohenfels.de."}));
    }
}

function* watchSaveEditUserDataChanged() {
    yield takeLatest(saveEditUserData.type, saveEditUserDataChanged)
}

function* registerUserToEvent() {
    try {
        const userData: CurrentUser = yield select((state: AppState) => state.userData.currentUser);
        const eventData: EventData = userData.currentEvent;
//alert(JSON.stringify(eventData));
        const response: AxiosResponse = yield call(service.registerUserDataToEvent(eventData));
        let data = response.data;
     //   alert(JSON.stringify(data));
        console.log(data);
        if (data.RegisterToEvent === false || data.Result.UserId == null || data.Result.UserId == 'null') {
            yield put(showFailedSnackbar({message: "Deine Anmeldung zur Veranstaltung war nicht erfolgreich. Kontaktiere uns bitte per E-Mail: mitgliedschaft@fcb-hohenfels.de"}));
        } else {
            yield put(showSuccededSnackbar({message: "Wir freuen uns auf dein kommen - deine Anmeldung war erfolgreich."}));
            yield put(addUserToEvent({UserId: data.Result.UserId}));
        }
    } catch (e) {
        console.log(e);
    }
}

function* watchRegisterUserToEvent() {
    yield takeLatest(registerToEvent.type, registerUserToEvent)
}



function* getEventByTypeData() {
    try {
        const response: AxiosResponse = yield call(service.getEventByTypeData(2));
        let data = response.data;
        console.log(data);
        if (data.GetEventByType && data.Result.length === 1) {
            yield put(getUserEventsSucceded({Events: data.Result}));
        }
    } catch (e) {
        console.log(e);
    }
}


function* watchGetEventByType() {
    yield takeLatest(getEventByType.type, getEventByTypeData)
}

function* saveUserAuthDataQr() {
    try {
        const currentUserData: CurrentUser = yield select((state: AppState) => state.userData.currentUser);
        const response: AxiosResponse = yield call(service.addUserLoginToken(currentUserData));
        let data = response.data;
        if (data.AddUserLoginToken) {
            yield put(showSuccededSnackbar({message: "Die Zwei-Faktor-Authentifizierung wurde an deinem Benutzerkonto aktiviert."}));
        } else {
            yield put(showFailedSnackbar({message: "Die Zwei-Faktor-Authentifizierung konnte an deinem Benutzerkonto nicht aktiviert werden. Wende dich bitte an webadmin@fcb-hohenfels.de"}));
        }
    } catch (e) {
        console.log(e);
    }
}

function* watchSaveUserAuthDataByType() {
    yield takeLatest(saveUserAuthData.type, saveUserAuthDataQr);
}

const userSaga = [
    fork(watchUserLogin),
    fork(watchConfirmRegistrations),
    fork(watchSetNewPassword),
    fork(watchGetNewPassword),
    fork(watchGetNewRegistration),
    fork(watchIsRegistrationValid),
    fork(watchLoadCurrentUserData),
    fork(watchSaveUserBaseDataChanged),
    fork(watchSaveUserContactDataChanged),
    fork(watchSaveUserPaymentDataChanged),
    fork(watchGetQrCodeUrlValueData),
    fork(watchCheckSecureCodeData),
    fork(watchResetUseTokenData),
    fork(watchImportUserData),
    fork(watchGetUserEventsData),
    fork(watchGetActiveUsersData),
    fork(watchGetActiveUsersDataCount),
    fork(watchRegisterUserToEvent),
    fork(watchSaveEditUserDataChanged),
    fork(watchSaveUserAuthDataByType)
];

export default userSaga;
