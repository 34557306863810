import React, {useEffect} from "react";
import ImageViewer from "../components/imageviewer";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {withRouter} from "react-router-dom";

const fanclubs = [
    {
        Name: "BFC Aschamooser",
        Url: "https://fanclub-aschamooser.jimdofree.com/",
        SnapshotUrl: "links/fanclubs/BFC_Aschamooser.jpg"
    },
    {
        Name: "Bayernkurve",
        Url: "https://www.bayernkurve.de/",
        SnapshotUrl: "links/fanclubs/Bayernkurve.jpg"
    },
    {
        Name: "BFC Büchelkühn",
        Url: "https://de-de.facebook.com/pages/category/Society---Culture-Website/FC-Bayern-Fanclub-Naabtal-B%C3%BCchelk%C3%BChn-338757800273192/",
        SnapshotUrl: "links/fanclubs/BFC_Buechelkuehn.jpg"
    },
    {
        Name: "RWC Dietfurt",
        Url: "https://www.rwc-dietfurt.de/",
        SnapshotUrl: "links/fanclubs/RWC_Dietfurt.jpg"
    },
    {
        Name: "BFC Emsland",
        Url: "https://www.bfc-emsland.de/",
        SnapshotUrl: "links/fanclubs/BFC_Emsland.jpg"
    },
    {
        Name: "BFC Hörmannsdorf",
        Url: "https://www.bayernfanclub-hoermannsdorf.de/",
        SnapshotUrl: "links/fanclubs/BFC_Hoermannsdorf.jpg"
    },
    {
        Name: "BFC Hohenschambach",
        Url: "https://www.fanclub-hohenschambach.de/",
        SnapshotUrl: "links/fanclubs/BFC_Hohenschambach.jpg"
    },
    {
        Name: "BFC Nabburg Oberpfalz",
        Url: "https://www.bayern-fanclub-nabburg.de/",
        SnapshotUrl: "links/fanclubs/BFC_Nabburg.jpg"
    },
    {
        Name: "Rote Zwerge",
        Url: "https://rotezwerge.de/",
        SnapshotUrl: "links/fanclubs/BFC_Rote_Zwerge.jpg"
    },
    {
        Name: "BFC Treue Rhein Main",
        Url: "https://www.facebook.com/Fc-Bayern-M%C3%BCnchen-Fanclub-145644725473849/",
        SnapshotUrl: "links/fanclubs/BFC_Treue_Rhein_Main.jpg"
    },
    {
        Name: "BFC Wappersdorf",
        Url: "https://www.bayern-fanatics-wappersdorf.de/",
        SnapshotUrl: "links/fanclubs/BFC_Wappersdorf.jpg"
    },
    {
        Name: "BFC Wildenau",
        Url: "http://fcb-fanclub-wildenau.de/",
        SnapshotUrl: "links/fanclubs/BFC_Wildenau.jpg"
    }
];

const fcBayernLinks = [
    {
        Name: "FC Bayern München",
        Url: "https://fcbayern.com/de",
        SnapshotUrl: "links/fussball/fcbayern.jpg"
    },
    {
        Name: "Allianz-Arena",
        Url: "https://allianz-arena.com/de",
        SnapshotUrl: "links/fussball/allianz-arena.jpg"
    },
    {
        Name: "Bundesliga - FC Bayern München",
        Url: "https://www.bundesliga.com/de/bundesliga/clubs/fc-bayern-muenchen/news",
        SnapshotUrl: "links/fussball/bundesliga.jpg"
    },
    {
        Name: "DFB",
        Url: "https://www.dfb.de/start/",
        SnapshotUrl: "links/fussball/dfb.jpg"
    },
    {
        Name: "UEFA",
        Url: "https://de.uefa.com/",
        SnapshotUrl: "links/fussball/uefa.jpg"
    }
];

const hohenfelserLand = [
    {
        Name: "Marktgemeinde Hohenfels",
        Url: "https://www.markt-hohenfels.de/",
        SnapshotUrl: "links/hohenfels/marktHohenfels.jpg"
    },
    {
        Name: "Burschenverein Hohenfels",
        Url: "https://de-de.facebook.com/Burschenverein/",
        SnapshotUrl: "links/hohenfels/burschenverein_hohenfels.jpg"
    },
    {
        Name: "TSV Hohenfels",
        Url: "http://www.tsv-hohenfels.de/",
        SnapshotUrl: "links/hohenfels/tsv_hohenfels.jpg"
    },
    {
        Name: "Kolping Hohenfels",
        Url: "https://www.kolping-hohenfels.de/",
        SnapshotUrl: "links/hohenfels/kolping_hohenfels.jpg"
    },
    {
        Name: "FFW Hohenfels",
        Url: "https://www.ffw-hohenfels.de/",
        SnapshotUrl: "links/hohenfels/ffw_hohenfels.jpg"
    }

]

function WebLinks() {
    useEffect(() => {
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            bg.className = 'hohenfels';
    }, []);

    return (
        <div className="content">
            <h2>Links zu befreundeten Fanclubs und anderen Webseiten</h2>
            <p><b><i>Es wird ausdrücklich darauf hingewiesen, dass wir keinerlei Einfluss auf den Inhalt der hier
                verlinkten Seiten haben.</i></b></p>
            <aside>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3 >FC Bayern Fanclub-Seiten</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul style={{listStyle: "none"}}>
                            {fanclubs.map((data: any) => {
                                let item = {
                                    name: data.Name,
                                    description: data.Url,
                                    src: data.SnapshotUrl
                                };
                                return (<li style={{float: "left", margin: 5}}>
                                    <div style={{height: '410px'}}>
                                        <ImageViewer autoPlay={false} indicators={false} items={[item]} untertitel="8px"
                                                     maxWidth={400} linkToDescription={true}/></div>
                                </li>)
                            })
                            }

                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3 >FC Bayern / Fu&szlig;ball</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul style={{listStyle: "none"}}>
                            {fcBayernLinks.map((data: any) => {
                                let item = {
                                    name: data.Name,
                                    description: data.Url,
                                    src: data.SnapshotUrl
                                };
                                return (<li style={{float: "left", margin: 5}}>
                                    <div style={{height: '410px'}}>
                                        <ImageViewer autoPlay={false} indicators={false} items={[item]} untertitel="8px"
                                                     maxWidth={400} linkToDescription={true}/></div>
                                </li>)
                            })
                            }

                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3 >Hohenfelser Land</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul style={{listStyle: "none"}}>
                            {hohenfelserLand.map((data: any) => {
                                let item = {
                                    name: data.Name,
                                    description: data.Url,
                                    src: data.SnapshotUrl
                                };
                                return (<li style={{float: "left", margin: 5}}>
                                    <div style={{height: '410px'}}>
                                        <ImageViewer autoPlay={false} indicators={false} items={[item]} untertitel="8px"
                                                     maxWidth={400} linkToDescription={true}/></div>
                                </li>)
                            })
                            }

                        </ul>
                    </AccordionDetails>
                </Accordion>
            </aside>
            <aside>
                <article>
                    <p>Wir w&uuml;rden uns freuen, wenn auch du zu unserer Webseite verlinken m&ouml;chtest - sprich uns an, wir sind dir dabei gerne behilflich.</p>
                </article>
            </aside>
        </div>
    )
}

export default withRouter(WebLinks);
