function DVInfo() {

    return (

        <div className="infoBox">
            <p>
                Der FC Bayern Fanclub Forellenbachtal Hohenfels erhebt, nutzt und verarbeitet die
                personenbezogenen Daten ausschlie&szlig;lich im Rahmen der datenschutzrechtlichen
                Bestimmungen. Weitere Informationen findest du hier: <a
                href="https://www.fcb-hohenfels.de/#/Datenverarbeitung">https://www.fcb-hohenfels.de/#/Datenverarbeitung</a>
            </p>

        </div>

    )
}

export default DVInfo;
