import ReCAPTCHA from "react-google-recaptcha";
import {Button, FormControl, FormControlLabel, Switch, TextField,} from "@material-ui/core";
import React, {useEffect} from "react";
import {isValidEmail, useStyles} from "../App";
import {withRouter} from "react-router-dom";
import AppSnackbar from "../components/snackbar";
import {addContact, setContactFormularData} from "../reducers/appReducer";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../app/appState";

function Kontakt() {

    const stateData = useSelector((state: AppState) => state);
    const appData = stateData.appData;
    const formularData = appData.contactFormular;

    const dispatch = useDispatch()

    const onCaptchaChange = (value: any) => {
        dispatch(setContactFormularData({key: "ValidCaptcha", value: value}));
    };

    const onCaptchaExpired = () => {
        dispatch(setContactFormularData({key: "ValidCaptcha", value: ""}));
    };

    const setNewState = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.id;
        if (event.target.type === "checkbox") {
            dispatch(setContactFormularData({key: name, value: event.target.checked}));
        } else {
            dispatch(setContactFormularData({key: name, value: String(event.target.value)}));
        }

    };


    const gotoStartView = () => {
        dispatch(setContactFormularData({key: "Subject", value: ""}));
        dispatch(setContactFormularData({key: "Message", value: ""}));
        window.location.href = "#/aktuelles";
    }

    const sendMail = () => {
        dispatch(addContact());
    };

    useEffect(() => {
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            bg.className = 'hohenfels';
    }, []);

    const classes = useStyles();

    return (
        <div className="content">
            <h2>Kontaktformular</h2>
            <article>
                <p>Habt ihr Fragen an den Fanclub, dann ist der Weg über das Kontaktformular der schnellste.
                    Einfach alle mit (<sup>&lowast;</sup>) gekenzeichneten Felder ausfüllen und dann absenden.</p>
                <FormControl className={classes.root}>
                    <TextField id="Name" label="Name" required fullWidth value={formularData.Name}
                               error={formularData.Name.length === 0}
                               onChange={setNewState}/>
                    <TextField id="City" label="Ort" fullWidth value={formularData.City} onChange={setNewState}/>
                    <TextField id="Email" type="email" label="E-Mail" required fullWidth value={formularData.Email}
                               onChange={setNewState}
                               error={formularData.Email.length === 0 || !isValidEmail(formularData.Email)}/>
                    <TextField id="Website" label="Webseite" fullWidth value={formularData.Website}
                               onChange={setNewState}/>
                    <TextField id="Subject" label="Betreff" required fullWidth value={formularData.Subject}
                               error={formularData.Subject.length === 0}
                               onChange={setNewState}/>
                    <TextField id="Message" label="Nachricht" required multiline className={classes.multiline}
                               error={formularData.Message.length === 0}
                               fullWidth value={formularData.Message} onChange={setNewState}/>

                    <p>Nachricht senden an:</p>
                    <ul>
                        <li>
                            <FormControlLabel control={
                                <Switch
                                    defaultChecked
                                    color="default"
                                    id="executive"
                                    checked={formularData.Recipient.executive}
                                    onChange={setNewState}
                                    inputProps={{'aria-label': 'Vorstand'}}
                                />} label="Vorstand"/></li>
                        <li>
                            <FormControlLabel control={
                                <Switch
                                    defaultChecked
                                    color="default"
                                    id="memberService"
                                    checked={formularData.Recipient.memberService}
                                    onChange={setNewState}
                                    inputProps={{'aria-label': 'Mitgliederservice'}}
                                />} label="Mitgliederservice"/></li>
                        <li>
                            <FormControlLabel control={
                                <Switch
                                    defaultChecked
                                    color="default"
                                    id="cardsService"
                                    checked={formularData.Recipient.cardsService}
                                    onChange={setNewState}
                                    inputProps={{'aria-label': 'Kartenbeauftragter'}}
                                />} label="Kartenbeauftragter"/></li>
                        <li>
                            <FormControlLabel control={
                                <Switch
                                    defaultChecked
                                    color="default"
                                    id="webMaster"
                                    checked={formularData.Recipient.webMaster}
                                    onChange={setNewState}
                                    inputProps={{'aria-label': 'Homepagebeauftragter'}}
                                />} label="Homepagebeauftragter"/></li>
                    </ul>
                    <p style={{marginTop: 5}}>
                        <ReCAPTCHA
                            onExpired={onCaptchaExpired}
                            sitekey="6Ldtc8YaAAAAAGdyYALYecbBAQCslkdaNnY_cckt"
                            onChange={onCaptchaChange}
                        />
                    </p>
                    {((formularData.Recipient.executive || formularData.Recipient.memberService ||
                        formularData.Recipient.cardsService || formularData.Recipient.webMaster) &&
                        formularData.Name.length > 0 && (formularData.Email.length > 0 && isValidEmail(formularData.Email)) &&
                        formularData.Subject.length > 0 && formularData.Message.length > 0 && formularData.ValidCaptcha.length > 0) &&
                    <Button variant="outlined" className="btnSubmit" onClick={sendMail}>
                        Senden
                    </Button>}

                    <AppSnackbar message={appData.succeded ? appData.succededText : appData.errorText}
                                 showSnackbar={appData.succeded || appData.failed}
                                 severity={appData.failed ? "error" : "success"}
                                 onClose={gotoStartView}
                    />
                </FormControl>
            </article>

        </div>
    )
}

export default withRouter(Kontakt);
