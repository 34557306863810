import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {AppBar, Divider, IconButton, List, ListItem, SwipeableDrawer, Toolbar, Typography} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import "../sass/menu.sass";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../app/appState";
import {currentUserData} from "../reducers/userReducer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        list: {
            width: 250,
        },
        fullList: {
            width: 'auto',
        },
        link: {
            textDecoration: "none",
            color: "#666",
            width: "100%",
            fontWeight: "normal"
        },
        listItem: {},
        listLink: {
            textDecoration: "none",
            color: "#666",
            fontWeight: "normal"
        }
    }),
);

type Anchor = 'top' | 'left' | 'bottom' | 'right';

function MainMenu() {
    const classes = useStyles();

    const [state, setState] = React.useState({
        left: false
    });

    const stateData = useSelector((state: AppState) => state);
    const userData = stateData.userData;
    const currentUser = userData.currentUser;
    const dispatch = useDispatch();

    const loadUserData = () =>{
        dispatch(currentUserData());
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({...state, [anchor]: open});
        if(open === true && document !== undefined){
            let btn = document.getElementById("uc-btn-close-x-corner-modal");
            if(btn !== null)
                btn.click();
        }
    };


    const list = (anchor: Anchor) => (
        <nav
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {['Startseite', 'Verein', 'Termine', 'Bilder', 'Links'].map((text, index) => (
                    <ListItem button key={text} className={classes.listItem}>
                        <Link to={text} className={classes.link}>{text}</Link>
                    </ListItem>
                ))}
            </List>
            <Divider/>
            <List>
                {['Kontakt', 'Impressum', 'Datenschutz'].map((text, index) => (
                    <ListItem button key={text} className={classes.listItem}>
                        <Link to={text} className={classes.link}>{text}</Link>
                    </ListItem>
                ))}
            </List>
            <Divider/>
            <List>
                {['Wir bei Facebook'].map((text, index) => (
                    <ListItem button key={text} className={classes.listItem}>
                        <Link to="Facebook" className={classes.link}>{text}</Link>
                    </ListItem>
                ))}
            </List>

            <Divider/>
            {(currentUser !== undefined && (currentUser.Id === undefined || currentUser.Id === null || currentUser.Id === "")) && <List>
                {['Anmelden'].map((text, index) => (
                    <ListItem button key={text} className={classes.listItem}>
                        <Link to={text} className={classes.link}>Mitgliederbereich</Link>
                    </ListItem>
                ))}
            </List>}
            {(currentUser !== undefined && currentUser.Id !== undefined && currentUser.Id !== null && currentUser.Id !== "") && <List>
                <ListItem button key={currentUser.Id} className={classes.listItem}>
                    <Link to="Benutzer" onClick={loadUserData}
                          className={classes.link}>{currentUser.FirstName + " " + currentUser.LastName}</Link>
                </ListItem>
                <ListItem button key="Abmelden" className={classes.listItem}>
                    <Link to="Abmelden" className={classes.link}>Abmelden</Link>
                </ListItem>
            </List>}
        </nav>
    );

    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar className="toolBar">
                    <SwipeableDrawer
                        anchor="left"
                        open={state["left"]}
                        onClose={toggleDrawer("left", false)}
                        onOpen={toggleDrawer("left", true)}
                    >
                        {list("left")}
                    </SwipeableDrawer>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                                onClick={toggleDrawer("left", true)}>
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" className="appTitle">
                        Willkommen beim FC Bayern Fanclub "Forellenbachtal" Hohenfels
                    </Typography>
                    <img className="logoWappen" src="./images/LogoWappen_sw.png" alt="Logo-Wappen"/>

                </Toolbar>
            </AppBar>
        </div>
    )
}

export default MainMenu;
