import React, {useEffect, useState} from 'react';
import {FormControlLabel, Switch} from "@material-ui/core";


const AuthData = (props: any) => {

    const UserAuthInitial = {
        AuthCode: {
            error: false,
            helperText: ''
        }
    };

    const [UserAuth, setUserAuthState] = React.useState(UserAuthInitial);

    useEffect(() => {
    }, []);

    const [constructorHasRun, setConstructorHasRun] = useState(false);

    const constructor = () => {
        if (constructorHasRun) return;
        setConstructorHasRun(true);
    };

    constructor();


    const setNewState = (event: React.ChangeEvent<HTMLInputElement>) =>{
        if(props.setNewState){
            props.setNewState(event);
        }
    }


    return (
        <div>
            <aside>
                <FormControlLabel control={
                    <Switch
                        defaultChecked
                        color="primary"
                        id="useToken"
                        checked={props.data.useToken}
                        onChange={setNewState}
                        inputProps={{'aria-label': 'Zwei-Faktor-Authentifizierung'}}
                    />}
                                  label="Die Zwei-Faktor-Authentifizierung nutzen, um meine Benutzerdaten noch besser zu sch&uuml;tzen."/>
            </aside>
        </div>
    )

}

export default AuthData;
