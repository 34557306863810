import React, {useEffect, useState} from "react";
import {AppBar, Button, MenuItem, Select, Tab, Tabs, TextField} from "@material-ui/core";
import SwipeableViews, {OnSwitchingCallbackTypeDescriptor} from "react-swipeable-views";
import {useTheme} from "@material-ui/core/styles";
import {vorstand} from "stammdaten";
import {useLocation, withRouter} from "react-router-dom";
import {TabPanel} from "App";
import AppSnackbar from "../components/snackbar";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../app/appState";
import {
    getActiveUsers,
    getActiveUsersCount,
    getUserEvents,
    resetCurrentEventData,
    setCurrentEventData,
    setCurrentEventUserData
} from "../reducers/userReducer";
import {getEventByType, registerToEvent, setEventByType} from "../reducers/appReducer";
import {EditUser} from "../app/dto/userData";
import ImageViewer from "../components/imageviewer";

function Termine() {
    const dispatch = useDispatch();
    const stateData = useSelector((state: AppState) => state);
    const appData = stateData.appData;
    const userData = stateData.userData;
    const currentUser = userData.currentUser;
    const [value, setValue] = React.useState(0);

    const [constructorHasRun, setConstructorHasRun] = useState(false);

    const constructor = () => {
        if (constructorHasRun) return;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const location = useLocation();
        const queryParams = new URLSearchParams(location.search);
        const value = queryParams.get('tab');
        if (value != null) {
            handleChangeIndex(Number(value));
        }
        setConstructorHasRun(true);
    };

    const loadData = () => {
        dispatch(getActiveUsers());
        dispatch(getUserEvents());
        dispatch(setEventByType({eventType: 2}));
        dispatch(getActiveUsersCount());
        dispatch(getEventByType());
    };

    const preselect = (reset = 0) => {
        loadData();
        setTimeout(() => {
            if (reset === 0) {
                if (userData.Count > 0 && (currentUser.currentEvent.userId == null || currentUser.currentEvent.userId.length === 0)) {
                    dispatch(setCurrentEventUserData({value: currentUser}));
                }
                if (currentUser.Events.length > 0) {
                    dispatch(setCurrentEventData({key: "eventId", value: currentUser.Events[0]?.Id}));
                }
            } else {
                if (userData.Count > 0 && (currentUser.Id != null)) {
                    dispatch(setCurrentEventUserData({value: currentUser}));
                }
                if (currentUser.Events.length > 0) {
                    dispatch(setCurrentEventData({key: "eventId", value: currentUser.Events[0]?.Id}));
                }
            }
            dispatch(getEventByType());
        }, 2000);
    }

    const swipeableViewSwitching = (index: number, type: OnSwitchingCallbackTypeDescriptor) => {
        if (type === "end")
            handleChangeIndex(index);
    }

    const tabIndexChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        handleChangeIndex(newValue);
    };

    // const abmelden = () => {
    //     dispatch(removeFromEvent());
    // };

    const setCurrentEvent = (event: any) => {
        let key = event.target.id;

        if (event.target.type === "radio") {
            if (key === "vater") {
                dispatch(setCurrentEventData({key, value: event.target.checked}));
                dispatch(setCurrentEventData({key: "mutter", value: !event.target.checked}));
            } else if (key === "mutter") {
                dispatch(setCurrentEventData({key, value: event.target.checked}));
                dispatch(setCurrentEventData({key: "vater", value: !event.target.checked}));
            }
        }
        if (event.target.type === "checkbox") {
            dispatch(setCurrentEventData({key, value: event.target.checked}));
        } else if (event.target.type === "text" || event.target.type === "textarea") {
            dispatch(setCurrentEventData({key, value: event.target.value}));
        }
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);

        setTimeout(preselect, 1000);
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            switch (index) {
                case 0:
                    bg.className = 'bayernFlagge2';
                    break;
                case 1:
                    bg.className = 'allianzArena_rot';
                    break;
                case 2:
                    bg.className = 'fasching';
                    setTimeout(preselect, 1000);
                    break;
                case 3:
                    bg.className = 'sommergrillen';
                    setTimeout(preselect, 1000);
                    break;
                case 4:
                    bg.className = 'weihnachten1';
                    setTimeout(preselect, 1000);
                    break;
                default:
                    bg.className = 'hohenfels';
                    break;
            }
    };

    const theme = useTheme();

    const eventRegister = () => {
        dispatch(registerToEvent());
    }

    constructor();

    useEffect(() => {
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            bg.className = 'bayernFlagge2';

    }, []);

    const gotoTab = (tabIndex: number) => {
        handleChangeIndex(tabIndex);
    };

    return (
        <div className="termine">
            <AppBar color="transparent" className="appBarSubMenu">
                <Tabs
                    variant="scrollable"
                    className="menuTabs"
                    value={value}
                    indicatorColor="primary"
                    onChange={tabIndexChange}

                >
                    <Tab label="Allgemein"/>
                    <Tab label="Fu&szlig;ball-Fahrten"/>
                    <Tab label="Fasching"/>
                    <Tab label="Sommergrillen"/>
                    <Tab label="Weihnachtsfeier"/>
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                enableMouseEvents={true}
                onSwitching={swipeableViewSwitching}
            >
                { /* Termine allgemein */}
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <article className="articleContent">
                        <h4>Aktuelle Termine</h4>
                        <ul>
                            <li style={{display: "none"}}>21.09.2024 - Einweihung des
                                Wasserkinderspielplatzes <i>Ziegelhütte</i></li>
                            <li style={{display: "none"}}>21.09.2024 - Sommergrillfest <a onClick={() => {
                                handleChangeIndex(3);
                            }}>Hier anmelden</a></li>
                            <li style={{display: "none"}}>30.11.2024 - Weihnachtsfeier des Fanclubs <a onClick={() => {
                                handleChangeIndex(4);
                            }}>Hier anmelden</a></li>
                            <li>07/08.12.2024 - Weihnachtsmarkt Hohenfels mit dem Fanclub</li>
                            <li>29.03.2025 - Starkbierfest des Fanclubs</li>
                            <li>26.04.2025 - Jahreshauptversammlung des Fanclubs</li>
                            <li>26.07.2025 - Sommergrillfest des Fanclubs</li>
                        </ul>

                    </article>
                    { /*Weihnachsfeier*/}
                    <article className="articleContent" style={{display: "none"}}>
                        <h3>Weihnachtsfeier im Gasthaus Zur Taverne in Hohenfels </h3>
                        <p>Der FC Bayern Fanclub Forellenbachtal Hohenfels trifft sich heute um 18:30 Uhr im Gasthaus
                            Zur Taverne zu seiner diesjährigen Weihnachtsfeier.</p>
                        <p>Im Rahmen der Weihnachtsfeier wird es neben dem gemütlichen Beisammensein, dem leckeren
                            Essen auch wieder die beliebte Tombola geben.</p>
                        <p>Der Vorstand wünscht allen Mitgliedern einen einen schönen gemeinsamen Abend.</p>
                        <div style={{color: '#b0acac'}} aria-disabled={true}>
                            <h3>Fahrt ins Blaue </h3>
                            <p>Am 23.09.2023 werden wir wieder eine <b><i>Fahrt ins Blaue</i></b> veranstalten.</p>
                            <p>Nachdem die erste Fahrt ins Blaue so viel positive Rückmeldungen brachte, werden wir es
                                dieses Jahr wiederholen - natürlich mit einem anderen Ziel. Was es ist, wird natürlich
                                wieder nicht verraten - lasst euch überraschen!</p>
                            <p>Anmeldungen erfolgen hierzu über Juliane Spangler oder Thomas Mulzer bzw. einfach über
                                das
                                Kontaktformular.</p>
                            <p>Erwachsene sind mit 35 € und Kinder bis 14 Jahre mit 25 € dabei.</p>
                            <p>Bitte beachtet folgenden Hinweis: wer sich anmeldet, muss bei späterer Absage selbst für
                                Ersatz sorgen. Bei Mitgliedern wird der Betrag automatisch abgebucht, Nichtmitglieder
                                müssen
                                entsprechend das Geld überweisen.</p>
                            <ImageViewer path="./images/termine/" items={[
                                {
                                    name: "",
                                    description: "Fahrt ins Blaue - 23.09.2023",
                                    src: "Fahrt_ins_Blaue_20230923.jpg"
                                }]}/>
                            <hr/>
                            <h3>Jahreshauptversammlung 2023</h3>
                            <p>Am 21.04.2023 ist unsere Jahreshauptversammlung.</p>

                            <p>Wir laden dazu alle Mitglieder recht herzlich ein, am 21.04.2023 um 20:00 Uhr im
                                Sportheim
                                Haarziegelhütte zur Jahreshauptversammlung zu erscheinen.</p>
                            <p>Am 22.04.2023 wird es eine Kirche für die verstorbenen Mitglieder geben.</p>
                            <p>Also merkt euch diesen Termin schon einmal vor.</p>
                            <hr/>
                            <h3>Bürgerfest Hohenfels </h3>
                            <p>Am 08./09.07.2023 veranstaltet der Markt Hohenfels sein Bürgerfest.</p>

                            <p>Vom FC Bayern Fanclub werden wir uns voraussichtlich beteiligen. Geplant ist ein
                                Dosenschießen
                                und ein Torwandschießen. Weiter ist geplant, Würstchengulasch mit Semmel und/oder Pommes
                                anzubieten.</p>
                            <p>Also merkt euch diesen Termin schon einmal vor.</p>
                            <hr/>
                            <h3>Besuch der FC Bayern Erlebniswelt mit Stadionführung </h3>
                            <p>Wer möchte nicht einmal die Erfolgsgeschichte des FC Bayern erleben, in die
                                Mannschaftskabine
                                schauen oder den Weg auf das Spielfeld gehen, den die Spieler kurz vor dem Anpfiff
                                beschreiten...?</p>
                            <p>Der Besuch der FC Bayern Erlebniswelt mit einer Stadionführung ist immer eine Reise
                                Wert.</p>
                            <p>Daher wollen wir mit interessierten im Sommer (Juli/August) eine Fahrt in die FC Bayern
                                Erlebniswelt mit
                                Stadionführung organisieren. Dabei richtet sich das Angebot nicht ausschließlich an
                                seine
                                Mitglieder bzw. Kinder und ihre Eltern, sondern auch an alle anderen Interessenten.</p>
                            <p>Der genaue Termin steht noch nicht fest, wird aber demnächst hier veröffentlicht.</p>
                            <p>Anmelden könnt ihr euch schon jetzt über das Kontaktformular der Webseite.</p>
                        </div>

                    </article>
                    { /*Weihnachsfeier*/}
                    <article className="articleContent" style={{display: "none"}}>
                        {currentUser.Role > 0 && (new Date() <= new Date("2022-12-15")) &&
                            <table style={{border: "solid 1px", borderColor: "#4f4b4b", width: "100%"}}>
                                <tr style={{
                                    color: "#fff",
                                    backgroundColor: "#1a5452",
                                    borderBottom: "solid 1px",
                                    borderBottomColor: "#342f2f"
                                }}>
                                    <th>Veranstaltung</th>
                                    <th>Benutzer</th>
                                </tr>
                                <tr>
                                    <td>
                                        <Select fullWidth
                                                id="event"
                                                value={(currentUser.currentEvent.eventId == null || currentUser.currentEvent.eventId.length === 0) ? currentUser.Events[0]?.Id : currentUser.currentEvent.eventId}
                                                onChange={(event: any) => {
                                                    let selectedEvent = currentUser.Events.find(x => x.Id === event.target.value);
                                                    if (selectedEvent != null) {
                                                        dispatch(setCurrentEventData({
                                                            key: "eventId",
                                                            value: selectedEvent.Id
                                                        }));
                                                    }
                                                }}
                                        >
                                            {currentUser.Events.map((item: any) => {
                                                return (<MenuItem key={"aa" + item.Id}
                                                                  value={item.Id}>{item.Description}</MenuItem>);
                                            })}

                                        </Select>
                                    </td>
                                    <td>
                                        <Select fullWidth
                                                id="eventUser"
                                                value={(currentUser.currentEvent.userId == null || currentUser.currentEvent.userId.length === 0) ? currentUser.Id : currentUser.currentEvent.userId}
                                                onChange={(event: any) => {
                                                    let selectedUser = userData.Items.find(x => x.Id === event.target.value);
                                                    if (selectedUser != null) {
                                                        dispatch(setCurrentEventUserData({value: selectedUser}));
                                                        dispatch(resetCurrentEventData());
                                                    }
                                                }
                                                }
                                        > {
                                            userData.Items.map((item: EditUser) => {
                                                // @ts-ignore
                                                let id = item.Id == null ? '' : item.Id.toString();
                                                return (<MenuItem key={"aa" + id}
                                                                  value={id}>{item.LastName + ", " + item.FirstName}</MenuItem>);
                                            })
                                        }

                                        </Select>
                                    </td>
                                </tr>
                            </table>
                        }
                        {currentUser.Id == null && <div>
                            <h3>Einladung zur Weihnachtsfeier am 16.12.2022 im Gasthaus Taverne in Hohenfels.</h3>
                            <p>Bitte registriere dich oder melde dich an, um dich zu der Veranstaltung online
                                anmelden
                                zu können.</p>
                            <p>Alternativ kannst du dich auch anmelden, indem du uns eine E-Mail schickst. Klicke
                                dazu <a
                                    href="mailto:mitgliedschaft@fcb-hohenfels.de?subject=Anmeldung zur Weihnachtsfeier am 16.12.2022&body=Hallo,%20%0D%0Agerne komme ich zur Weihnachtsfeier.%20%0D%0A%20%0D%0AMeine Anmeldenummer ist:%20%0D%0A%20%0D%0AIch bringe noch folgende Personen mit:%20%0D%0A%20%0D%0A%20%0D%0A%20%0D%0AViele liebe Grüße%20%0D%0A">hier!</a>
                            </p>
                        </div>}
                        {(currentUser.currentEvent.userId !== '' && currentUser.currentEvent.userId != null)
                            &&
                            <div key={currentUser.currentEvent.userId}>
                                <h3>Einladung zur Weihnachtsfeier am 16.12.2022 im Gasthaus Taverne in Hohenfels. </h3>
                                <div style={{maxWidth: 700, textAlign: "justify"}}>
                                    <div>
                                        <p>Servus {currentUser.currentEvent.firstName},</p>
                                    </div>
                                    <div>
                                        <p>hiermit laden wir Dich, {currentUser.currentEvent.firstName}, mit Deiner
                                            Familie herzlich zur Weihnachtsfeier des FC Bayern Fanclubs am<span
                                                style={{
                                                    fontWeight: "bold",
                                                    textDecoration: "underline",
                                                    fontStyle: "italic",
                                                    margin: "0px 5px"
                                                }}>Freitag, den 16. Dezember 2022</span>um<span
                                                style={{
                                                    fontWeight: "bold",
                                                    textDecoration: "underline",
                                                    fontStyle: "italic",
                                                    margin: "0px 5px"
                                                }}>18:00 Uhr</span>im<span
                                                style={{
                                                    fontWeight: "bold",
                                                    textDecoration: "underline",
                                                    fontStyle: "italic",
                                                    margin: "0px 5px"
                                                }}>Gasthaus Taverne in Hohenfels</span> ein.</p>
                                    </div>
                                    <div>
                                        <table style={{
                                            border: "solid 1px #666",
                                            borderCollapse: "collapse",
                                            borderSpacing: "initial"
                                        }}>
                                            <tr>
                                                <th style={{
                                                    borderBottom: "solid 1px #666",
                                                    backgroundColor: "lightgray",
                                                    padding: 10
                                                }}>
                                                    <span style={{fontWeight: "bold", textDecoration: "underline"}}>Bitte beachte:</span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td style={{padding: 10}}>
                                                    <p>Für Dich, als Vereinsmitglied, sind das Essen sowie 5,00 € für
                                                        Getränke frei (Bon für die
                                                        Freigetränke erhältst Du am Eingang von unserem Kassier).</p>
                                                    <p>Kinder unter 7 Jahre sind frei.</p>
                                                    <p> Solltest Du, trotz Anmeldung <span style={{
                                                        fontWeight: "bold",
                                                        textDecoration: "underline"
                                                    }}>nicht</span> auf der Weihnachtsfeier erscheinen, wird ein
                                                        Unkostenbeitrag i.
                                                        H. v. 10,00 € von Deinem
                                                        Konto abgebucht – gleiches gilt für Nichtmitglieder, die mit
                                                        Dir angemeldet, aber nicht
                                                        anwesend waren.
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>

                                    </div>
                                    <div><p style={{color: "#f00"}}>Wir von der Vorstandschaft möchten gerne unser
                                        optisches Auftreten erneuern und aus diesem Grund
                                        Softshell-Jacken und Polo-Shirts mit unserem Vereinslogo bestellen. Auch unsere
                                        Mitglieder sollen die
                                        Möglichkeit erhalten, sich die neue Vereinskleidung kaufen zu können. Dazu
                                        werden bei der
                                        Weihnachtsfeier Probeartikel der Jacken und der Polo-Shirts (in den Größen M, L,
                                        XL und XXL) zur
                                        Verfügung stehen. Nutze die Gelegenheit, Dich von der Qualität vorab zu
                                        überzeugen und ggf. die
                                        Passform für Dich zu prüfen. Es besteht kein Abnahmezwang.
                                    </p></div>
                                    <div style={{marginBottom: 5, marginTop: 5}}>
                                        Um für den Abend besser planen und das Essen in ausreichender Menge bestellen
                                        zu können, bitten wir Dich,
                                        Deine verbindliche Zusage bis spätestens
                                        <span style={{color: "#f00", fontWeight: "bold"}}>&nbsp;Sonntag, den 11. Dezember 2022&nbsp;</span>
                                        abzugeben.
                                    </div>
                                    <div style={{fontWeight: "bold", color: "#000", textAlign: "center"}}>
                                        <p>Auf Dein Kommen freut sich die Vorstandschaft des</p>
                                        <p> FC Bayern-Fanclubs Forellenbachtal Hohenfels</p>

                                    </div>
                                </div>
                                {(new Date() <= new Date("2022-12-12")) &&
                                    <div style={{borderTop: "dotted 1px #008BFF", marginTop: 20}}>
                                        <p style={{
                                            color: "#f00",
                                            fontWeight: "bold",
                                            maxWidth: 750,
                                            textAlign: "center"
                                        }}>Anmeldung zur Weihnachtsfeier des FC Bayern-Fanclubs Forellenbachtal
                                            Hohenfels am 16.12.2022 im Gasthaus Taverne in Hohenfels
                                        </p>

                                        <table>
                                            <tr>
                                                <th style={{maxWidth: "50%"}}>Anmeldebestätigung für</th>
                                                <th style={{maxWidth: "50%"}}>Ich bringe zur Veranstaltung noch
                                                    folgendes mit
                                                </th>
                                            </tr>
                                            <tr>
                                                <td><span
                                                    style={{color: "#bbb"}}> Anmeldenummer:</span> {currentUser.currentEvent.registerNumber}
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>{currentUser.currentEvent.firstName}&nbsp;{currentUser.currentEvent.lastName}</td>
                                                <td><label><input id="partner" onChange={setCurrentEvent}
                                                                  checked={currentUser.currentEvent.partner}
                                                                  type="checkbox" style={{
                                                    width: 25,
                                                    height: 25,
                                                    margin: 0,
                                                    marginRight: 14
                                                }}/>&nbsp;Partner/in</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td><label><input type="text" onChange={setCurrentEvent}
                                                                  id="kinderBis7"
                                                                  value={currentUser.currentEvent.kinderBis7}/>&nbsp;1x
                                                    Kind(er) unter 7 Jahre (frei)</label></td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td><label><input type="text" onChange={setCurrentEvent}
                                                                  id="kinderAb7"
                                                                  value={currentUser.currentEvent.kinderAb7}/>&nbsp;1x
                                                    Kind(er) älter oder gleich 7 Jahre</label></td>
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} style={{color: "#f00", fontWeight: "bold"}}>
                                                    <input type="checkbox" onChange={setCurrentEvent}
                                                           id="unkostenbeitrag"
                                                           checked={currentUser.currentEvent.unkostenbeitrag}/>
                                                    Über die anfallenden
                                                    Kosten von 10,00 €
                                                    bei Nichterscheinen trotz Anmeldung bin ich hiermit informiert. Mit
                                                    Absenden des Formulars
                                                    bestätige ich meine verbindliche Anmeldung zu der Veranstaltung.
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <TextField
                                                        style={{maxWidth: 650}} onKeyUp={setCurrentEvent}
                                                        id="bemerkungen"
                                                        label="Bemerkung"
                                                        multiline
                                                        rows={4}
                                                        defaultValue={currentUser.currentEvent.bemerkungen}
                                                        variant="outlined"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <Button variant="outlined" className="btnSubmit"
                                                            onClick={eventRegister}>
                                                        Jetzt anmelden
                                                    </Button>
                                                </td>

                                            </tr>
                                        </table>

                                    </div>
                                }
                                {(new Date() > new Date("2022-12-12")) &&
                                    <div style={{color: "#f00", fontSize: 20, fontWeight: "bold"}}>Die Anmeldefrist ist
                                        leider abgelaufen! Bitte wende dich direkt an den Vorstand, wenn du noch dabei
                                        sein möchtest.</div>
                                }

                            </div>}
                    </article>
                </TabPanel>
                { /* Fußballfahrten */}
                <TabPanel value={value} index={1} dir={theme.direction}>

                    <article className="articleContent">
                        <h3>Basketball</h3>
                        <div>
                            <p>Am 17.01.2025 fahren wir gemeinsam zum Basketballspiel Heimspiel des FC Bayern
                                München
                                gegen
                                den Virtus Bologna.</p>
                        </div>
                        <div><p>Es sind insgesamt 15 Karten verfügbar. Kartenanfragen bitte an unseren
                            Kartenbeauftragten <b>{vorstand.Kartenbeauftragter.Name}</b> per
                            E-Mail <i>{vorstand.Kartenbeauftragter.Mail}</i> richten.</p>
                            <p>Sobald konkrete Abfahrtzeiten bekannt sind, werde diese hier veröffentlicht.</p>
                        </div>
                    </article>
                    <article className="articleContent">
                        <h3>Fu&szlig;ballfahrten</h3>
                        <div>
                            <div>
                                <p>Am 29.01.2025 fahren wir gemeinsam zum Championsleague Heimspiel des FC Bayern
                                    München
                                    gegen
                                    den Slovan Bratislava.</p>
                                <p>Es sind insgesamt 45 Karten verfügbar. Kartenanfragen bitte an unseren
                                    Kartenbeauftragten <b>{vorstand.Kartenbeauftragter.Name}</b> per
                                    E-Mail <i>{vorstand.Kartenbeauftragter.Mail}</i> richten.</p>
                            </div>

                            <div style={{border: "solid 1px #f00;"}}>
                                <h3>Hinweis zur Abfahrt am 29.01.2025</h3>
                                <ol>
                                    <li>16:00 Uhr Lupburg Bushaltestelle Parsberger Str.</li>
                                    <li>16:15 Uhr Hohenfels Kommunmarkt</li>
                                </ol>
                            </div>
                            <hr/>
                        </div>
                        <div style={{color: '#b0acac'}} aria-disabled={true}>
                            <div>
                                <p>Am 29.11.2023 fahren wir gemeinsam zum Championsleague Heimspiel des FC Bayern
                                    München
                                    gegen
                                    den F.C. Copenhagen.</p>
                            </div>

                            <div style={{border: "solid 1px #f00;"}}>
                                <h3>Hinweis zur Abfahrt am 29.11.2023</h3>
                                <ol>
                                    <li>16:00 Uhr Lupburg Bushaltestelle Parsberger Str.</li>
                                    <li>16:15 Uhr Hohenfels Kommunmarkt</li>
                                </ol>
                            </div>
                            <hr/>
                            <div>
                                <p>Am Wochenende vom 11.11.2023 fahren wir gemeinsam zum Heimspiel des FC Bayern München
                                    gegen
                                    den FC Heidenheim.</p>
                            </div>

                            <div style={{border: "solid 1px #f00;"}}>
                                <h3>Hinweis zur Abfahrt am 11.11.2023</h3>
                                <ol>
                                    <li>10:15 Uhr Lupburg Bushaltestelle Parsberger Str.</li>
                                    <li>10:30 Uhr Hohenfels Kommunmarkt</li>
                                </ol>
                            </div>

                            <hr/>
                            <div>
                                <p>Am 04.04.2023 fahren wir gemeinsam zu der Begegnung im DFB Pokal FC Bayern München
                                    gegen
                                    den SC Freiburg.</p>
                            </div>

                            <div style={{border: "solid 1px #f00;"}}>
                                <h3>Hinweis zur Abfahrt am 04.04.2023</h3>
                                <ol>
                                    <li>16:00 Uhr Lupburg Bushaltestelle Parsberger Str.</li>
                                    <li>16:15 Uhr Hohenfels Kommunmarkt</li>
                                </ol>
                            </div>
                            <hr/>

                            <div>
                                <p>Am 01.11.2022 fahren wir gemeinsam zu der Begegnung FC Bayern München gegen
                                    den FC Internazionale Milano.</p>
                            </div>

                            <div style={{border: "solid 1px #f00;"}}>
                                <h3>Hinweis zur Abfahrt am 01.11.2022</h3>
                                <ol>
                                    <li>16:00 Uhr Lupburg Bushaltestelle Parsberger Str.</li>
                                    <li>16:15 Uhr Hohenfels Kommunmarkt</li>
                                </ol>
                            </div>
                            <hr/>
                            <div>
                                <p>Am 29.10.2022 fahren wir gemeinsam zu der Begegnung FC Bayern München gegen
                                    den 1. FSV Mainz 05.</p>
                            </div>

                            <div style={{border: "solid 1px #f00;"}}>
                                <h3>Hinweis zur Abfahrt am 29.10.2022</h3>
                                <ol>
                                    <li>10:15 Uhr Lupburg Bushaltestelle Parsberger Str.</li>
                                    <li>10:30 Uhr Hohenfels Kommunmarkt</li>
                                </ol>
                            </div>
                            <hr/>
                            <div>
                                <p>Am 13.09.2022 geht es zum Spiel nach München gegen den Barcelona, Anstoß 21:00
                                    Uhr.</p>
                            </div>
                            <div style={{border: "solid 1px #f00;"}}>
                                <h3>Hinweis zur Abfahrt am 13.09.2022</h3>
                                <ol>
                                    <li>16:30 Uhr Lupburg Bushaltestelle Parsberger Str.</li>
                                    <li>16:40 Uhr Hohenfels Kommunmarkt</li>
                                </ol>
                            </div>
                            <hr/>
                            <div>
                                <p>Am 10.09.2022 geht es zum Spiel nach München gegen den VfB Stuttgart, Anstoß 15:30
                                    Uhr.</p>
                            </div>
                            <div style={{border: "solid 1px #f00;"}}>
                                <h3>Hinweis zur Abfahrt am 10.09.2022</h3>
                                <ol>
                                    <li>11:00 Uhr Lupburg Bushaltestelle Parsberger Str.</li>
                                    <li>11:15 Uhr Hohenfels Kommunmarkt</li>
                                </ol>
                            </div>

                        </div>


                        <p>Unser Kartenbeauftragter:</p>
                        <p><b>{vorstand.Kartenbeauftragter.Name}</b></p>
                        <p><i>{vorstand.Kartenbeauftragter.Mail}</i></p>
                    </article>


                </TabPanel>
                { /* Fasching */}
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <article className="articleContent">
                        <h4>29.03.2025 - Termin zum Starkbierfest steht</h4>
                        <p>Merkt euch also diesen Termin schon mal vor.</p>
                        <div style={{display: "none"}}>
                            <ImageViewer path="./images/termine/2024/" items={[
                                {
                                    name: "",
                                    description: "",
                                    src: "FlyerStarkbierfest_2024.jpg"
                                }]}/>
                        </div>
                    </article>
                    <article className="articleContent" style={{display: "none"}}>
                        <h4>Termin zum Fasching steht</h4>
                        <p>Merkt euch also diesen Termin schon mal vor.</p>
                        <p>2025 ist unser Faschingsball am 08.02.2025 im Keltensaal.</p>
                    </article>
                </TabPanel>
                { /* Grillen */}
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <article className="articleContent">
                        <h3>Sommergrillen</h3>
                        <p>Der Termin für das Sommergrillen 2025 ist der 26.07.2025 - merkt euch diesen Termin schon mal vor.</p>
                        <p>Impressionen vom Sommergrillen 2024 findet ihr wieder unter dem
                            Menüpunkt <b><i>Bilder</i></b>.</p>
                    </article>
                    { /*Grillfest*/}
                    <article className="articleContent" style={{display: "none"}}>
                        {currentUser.Role > 0 && (new Date() <= new Date("2024-08-26")) &&
                            <table style={{border: "solid 1px", borderColor: "#4f4b4b", width: "100%"}}>
                                <tr style={{
                                    color: "#fff",
                                    backgroundColor: "#1a5452",
                                    borderBottom: "solid 1px",
                                    borderBottomColor: "#342f2f"
                                }}>
                                    <th>Veranstaltung</th>
                                    <th>Benutzer</th>
                                </tr>
                                <tr>
                                    <td>
                                        <Select fullWidth
                                                id="event"
                                                value={(currentUser.currentEvent.eventId == null || currentUser.currentEvent.eventId.length === 0) ? currentUser.Events[0]?.Id : currentUser.currentEvent.eventId}
                                                onChange={(event: any) => {
                                                    let selectedEvent = currentUser.Events.find(x => x.Id === event.target.value);
                                                    if (selectedEvent != null) {
                                                        dispatch(setCurrentEventData({
                                                            key: "eventId",
                                                            value: selectedEvent.Id
                                                        }));
                                                    }
                                                }}
                                        >
                                            {
                                                currentUser.Events.map((item: any) => {
                                                    return (<MenuItem key={"event_" + item.Id}
                                                                      value={item.Id}>{item.Description}</MenuItem>);
                                                })}

                                        </Select>
                                    </td>
                                    <td>
                                        <Select fullWidth
                                                id="eventUser"
                                                value={(currentUser.currentEvent.userId == null || currentUser.currentEvent.userId.length === 0) ? currentUser.Id : currentUser.currentEvent.userId}
                                                onChange={(event: any) => {
                                                    let selectedUser = userData.Items.find(x => x.Id === event.target.value);
                                                    if (selectedUser != null) {
                                                        dispatch(setCurrentEventUserData({value: selectedUser}));
                                                        dispatch(resetCurrentEventData());
                                                    }
                                                }
                                                }
                                        > {
                                            userData.Items.map((item: EditUser) => {
                                                // @ts-ignore
                                                let id = item.Id == null ? '' : item.Id.toString();
                                                return (<MenuItem key={"user_" + id}
                                                                  value={id}>{item.LastName + ", " + item.FirstName}</MenuItem>);
                                            })
                                        }

                                        </Select>
                                    </td>
                                </tr>
                            </table>
                        }
                        {currentUser.Id == null && <div>
                            <h3>Einladung zum Sommergrillen am 07.09.2024 im Sportheim am Sportplatz Haarziegelhütte
                                Hohenfels.</h3>
                            <p>Bitte registriere dich oder melde dich an, um dich zu der Veranstaltung online
                                anmelden
                                zu können.</p>
                            <p>Alternativ kannst du dich auch anmelden, indem du uns eine E-Mail schickst. Klicke
                                dazu <a
                                    href="mailto:mitgliedschaft@fcb-hohenfels.de?subject=Einladung zum Sommergrillen am 07.09.2024 im Sportheim am Sportplatz Haarziegelhütte Hohenfels&body=Hallo,%20%0D%0Agerne komme ich zum Sommergrillen am 07.09.2024.%20%0D%0A%20%0D%0AMeine Anmeldenummer ist:%20%0D%0A%20%0D%0AIch bringe noch folgende Personen mit:%20%0D%0A%20%0D%0A%20%0D%0A%20%0D%0AViele liebe Grüße%20%0D%0A">hier!</a>
                            </p>
                        </div>}
                        {(currentUser.currentEvent.userId !== '' && currentUser.currentEvent.userId != null)
                            &&
                            <div key={currentUser.currentEvent.userId}>
                                <h3>Einladung zum Grillfest des FC Bayern Fanclubs Hohenfels 2024 </h3>
                                <div style={{maxWidth: 700, textAlign: "justify"}}>
                                    <div>
                                        <p>Servus {currentUser.currentEvent.firstName},</p>
                                    </div>
                                    <div>
                                        <p>hiermit laden wir Dich, {currentUser.currentEvent.firstName}, mit Deiner
                                            Familie recht herzlich zu unserem diesjährigen Grillfest <span
                                                style={{
                                                    fontWeight: "bold",
                                                    textDecoration: "underline",
                                                    fontStyle: "italic",
                                                    margin: "0px 5px"
                                                }}>Samstag, den 07. September 2024</span>um<span
                                                style={{
                                                    fontWeight: "bold",
                                                    textDecoration: "underline",
                                                    fontStyle: "italic",
                                                    margin: "0px 5px"
                                                }}>16:30 Uhr</span>im<span
                                                style={{
                                                    fontWeight: "bold",
                                                    textDecoration: "underline",
                                                    fontStyle: "italic",
                                                    margin: "0px 5px"
                                                }}>Sportheim am Sportplatz Haarziegelhütte Hohenfels</span> ein. Für
                                            Speis´ und Trank ist wie immer bestens gesorgt!.</p>
                                    </div>
                                    <div>
                                        <table style={{
                                            border: "solid 1px #666",
                                            borderCollapse: "collapse",
                                            borderSpacing: "initial"
                                        }}>
                                            <tr>
                                                <th style={{
                                                    borderBottom: "solid 1px #666",
                                                    backgroundColor: "lightgray",
                                                    padding: 10
                                                }}>
                                                    <span style={{fontWeight: "bold", textDecoration: "underline"}}>Bitte beachte:</span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td style={{padding: 10}}>
                                                    <p style={{fontWeight: "bold"}}>Für Dich als Mitglied ist das
                                                        Grillfest selbstverständlich kostenlos.</p>
                                                    <p>
                                                        Nichtmitglieder, die Dich begleiten, haben einen Unkostenbeitrag
                                                        für das Essen i. H. v.
                                                        5,00 € bar zu entrichten. Kinder unter 7 Jahre sind frei.
                                                    </p>
                                                    <p>
                                                        Solltest Du, trotz Anmeldung <span style={{
                                                        fontWeight: "bold",
                                                        textDecoration: "underline"
                                                    }}>nicht</span> auf dem Grillfest erscheinen, wird der
                                                        Unkostenbeitrag
                                                        i. H. v. 5,00 € von Deinem Konto abgebucht – gleiches gilt für
                                                        Nichtmitglieder, die mit Dir angemeldet,
                                                        aber nicht anwesend waren.
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>

                                    </div>

                                    <div style={{marginBottom: 5, marginTop: 5}}>
                                        Um für das Grillfest besser planen zu können, bitten wir Dich,
                                        Deine verbindliche Zusage bis spätestens
                                        <span style={{color: "#f00", fontWeight: "bold"}}>&nbsp;Montag, den 25. August 2024&nbsp;</span>
                                        abzugeben.
                                    </div>
                                    <div style={{fontWeight: "bold", color: "#000", textAlign: "center"}}>
                                        <p>Auf Dein Kommen freut sich die Vorstandschaft des</p>
                                        <p> FC Bayern-Fanclubs Forellenbachtal Hohenfels</p>

                                    </div>
                                </div>
                                {(new Date() <= new Date("2024-08-26")) &&
                                    <div style={{borderTop: "dotted 1px #008BFF", marginTop: 20}}>
                                        <p style={{
                                            color: "#f00",
                                            fontWeight: "bold",
                                            maxWidth: 750,
                                            textAlign: "center"
                                        }}>Anmeldung zum Grillfest am 07.09.2024 im Sportheim am Sportplatz
                                            Haarziegelhütte, Hohenfels
                                        </p>

                                        <table style={{width: "100%"}}>
                                            <tr>
                                                <th style={{maxWidth: "50%"}}>Anmeldebestätigung für</th>
                                                <th style={{maxWidth: "50%"}}>Folgende Personen begleiten mich
                                                </th>
                                            </tr>
                                            <tr>
                                                <td><span
                                                    style={{color: "#bbb"}}> Anmeldenummer:</span> {currentUser.currentEvent.registerNumber}
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>{currentUser.currentEvent.firstName}&nbsp;{currentUser.currentEvent.lastName}</td>
                                                <td><label><input id="partner" onChange={setCurrentEvent}
                                                                  checked={currentUser.currentEvent.partner}
                                                                  type="checkbox" style={{
                                                    width: 25,
                                                    height: 25,
                                                    margin: 0,
                                                    marginRight: 14
                                                }}/>&nbsp;Partner/in</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td><label><input type="text" onChange={setCurrentEvent}
                                                                  id="kinderBis7"
                                                                  value={currentUser.currentEvent.kinderBis7}/>&nbsp;1x
                                                    Kind(er) unter 7 Jahre (frei)</label></td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td><label><input type="text" onChange={setCurrentEvent}
                                                                  id="kinderAb7"
                                                                  value={currentUser.currentEvent.kinderAb7}/>&nbsp;1x
                                                    Kind(er) älter oder gleich 7 Jahre</label></td>
                                            </tr>

                                            <tr>
                                                <td colSpan={2}>&nbsp;</td>
                                            </tr>
                                        </table>

                                        <table>
                                            <tr>
                                                <th colSpan={2}>Ich bringe zur Veranstaltung noch folgendes mit</th>
                                            </tr>
                                            <tr>
                                                <td><label><input type="checkbox" onChange={setCurrentEvent}
                                                                  id="gemischterSalat"
                                                                  checked={currentUser.currentEvent.gemischterSalat}/>&nbsp;1x
                                                    Gemischten Salat</label></td>

                                                <td><label><input type="checkbox" onChange={setCurrentEvent}
                                                                  id="kartoffelsalat"
                                                                  checked={currentUser.currentEvent.kartoffelsalat}/>&nbsp;1x
                                                    Kartoffelsalat</label></td>
                                            </tr>
                                            <tr>
                                                <td><label><input type="checkbox" onChange={setCurrentEvent}
                                                                  id="nudelsalat"
                                                                  checked={currentUser.currentEvent.nudelsalat}/>&nbsp;1x
                                                    Nudelsalat</label></td>

                                                <td><label><input type="checkbox" onChange={setCurrentEvent}
                                                                  id="wurstsalat"
                                                                  checked={currentUser.currentEvent.wurstsalat}/>&nbsp;1x
                                                    Wurstsalat</label></td>
                                            </tr>
                                            <tr>
                                                <td><label><input type="checkbox" onChange={setCurrentEvent}
                                                                  id="gurkensalat"
                                                                  checked={currentUser.currentEvent.gurkensalat}/>&nbsp;1x
                                                    Gurkensalat</label></td>

                                                <td><label><input type="checkbox" onChange={setCurrentEvent}
                                                                  id="tomatensalat"
                                                                  checked={currentUser.currentEvent.tomatensalat}/>&nbsp;1x
                                                    Tomatensalat</label></td>
                                            </tr>
                                            <tr>
                                                <td><label><input type="checkbox" onChange={setCurrentEvent}
                                                                  id="kuchen"
                                                                  checked={currentUser.currentEvent.kuchen}/>&nbsp;1x
                                                    Kuchen</label></td>
                                                <td>&nbsp;</td>
                                            </tr>

                                        </table>
                                        <hr/>
                                        <table>
                                            <tr>
                                                <td style={{color: "#f00", fontWeight: "bold"}}>
                                                    <input type="checkbox" onChange={setCurrentEvent}
                                                           id="unkostenbeitrag"
                                                           checked={currentUser.currentEvent.unkostenbeitrag}/>
                                                    Über die anfallenden
                                                    Kosten von 5,00 €
                                                    bei Nichterscheinen trotz Anmeldung bin ich hiermit informiert. Mit
                                                    Absenden des Formulars
                                                    bestätige ich meine verbindliche Anmeldung zu der Veranstaltung.
                                                </td>

                                            </tr>
                                            <tr>

                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextField
                                                        style={{maxWidth: 650}} onKeyUp={setCurrentEvent}
                                                        id="bemerkungen"
                                                        label="Bemerkung"
                                                        multiline
                                                        rows={4}
                                                        defaultValue={currentUser.currentEvent.bemerkungen}
                                                        variant="outlined"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <Button variant="outlined" className="btnSubmit"
                                                            onClick={eventRegister}>
                                                        Jetzt anmelden
                                                    </Button>
                                                </td>

                                            </tr>
                                        </table>

                                    </div>
                                }
                                {(new Date() >= new Date("2024-08-26")) &&
                                    <div style={{color: "#f00", fontSize: 20, fontWeight: "bold"}}>Die Anmeldefrist ist
                                        leider abgelaufen! Bitte wende dich direkt an den Vorstand, wenn du noch dabei
                                        sein möchtest.</div>
                                }

                            </div>}
                    </article>

                </TabPanel>
                { /* Weihnachtsfeier */}
                <TabPanel value={value} index={4} dir={theme.direction}>
                    <article className="articleContent" >
                        <p>Auch am Hohenfelser Weihnachtsmarkt vom 07. – 08.12.2024 wird der FC Bayern Fanclub
                            Forellenbachtal Hohenfels abermals teilnehmen und wie letztes Jahr Bratwürstlsemmel
                            verkaufen. </p>
                    </article>
                    <article className="articleContent" style={{display: "none"}}>
                        {currentUser.Role > 0 && (new Date() < new Date("2024-11-20")) &&
                            <table style={{border: "solid 1px", borderColor: "#4f4b4b", width: "100%"}}>
                                <tr style={{
                                    color: "#fff",
                                    backgroundColor: "#1a5452",
                                    borderBottom: "solid 1px",
                                    borderBottomColor: "#342f2f"
                                }}>
                                    <th>Benutzer</th>
                                </tr>
                                <tr>
                                    <td>
                                        <Select fullWidth
                                                id="eventUser"
                                                value={(currentUser.currentEvent.userId == null || currentUser.currentEvent.userId.length === 0) ? currentUser.Id : currentUser.currentEvent.userId}
                                                onChange={(event: any) => {
                                                    let selectedUser = userData.Items.find(x => x.Id === event.target.value);
                                                    if (selectedUser != null) {
                                                        dispatch(setCurrentEventUserData({value: selectedUser}));
                                                        dispatch(resetCurrentEventData());
                                                    }
                                                }
                                                }
                                        > {
                                            userData.Items.map((item: EditUser) => {
                                                // @ts-ignore
                                                let id = item.Id == null ? '' : item.Id.toString();
                                                return (<MenuItem key={"user_" + id}
                                                                  value={id}>{item.LastName + ", " + item.FirstName}</MenuItem>);
                                            })
                                        }

                                        </Select>
                                    </td>
                                </tr>
                            </table>
                        }
                        {currentUser.Id == null && <div>
                            <h3>Einladung zur Weihnachtsfeier am 30.11.2024 im Gasthaus Taverne in Hohenfels.</h3>
                            <p>Bitte registriere dich oder melde dich an, um dich zu der Veranstaltung online
                                anmelden
                                zu können.</p>
                            <p>Alternativ kannst du dich auch anmelden, indem du uns eine E-Mail schickst. Klicke
                                dazu <a
                                    href="mailto:mitgliedschaft@fcb-hohenfels.de?subject=Anmeldung zur Weihnachtsfeier am 30.11.2024 im Gasthaus Taverne in Hohenfels&body=Hallo,%20%0D%0Agerne komme ich zur Weihnachtsfeier am 30.11.2024.%20%0D%0A%20%0D%0AMeine Anmeldenummer ist:%20%0D%0A%20%0D%0A%20%0D%0AViele liebe Grüße%20%0D%0A">hier!</a>
                            </p>
                        </div>}
                        {(currentUser.currentEvent.userId !== '' && currentUser.currentEvent.userId != null)
                            &&
                            <div key={currentUser.currentEvent.userId}>
                                <h3>Einladung zur Weihnachtsfeier des FC Bayern Fanclubs Hohenfels 2023 </h3>
                                <div style={{maxWidth: 700, textAlign: "justify"}}>
                                    <div>
                                        <p>Servus {currentUser.currentEvent.firstName},</p>
                                    </div>
                                    <div>
                                        <p>hiermit laden wir Dich, {currentUser.currentEvent.firstName}, zu unserer
                                            Weihnachtsfeier am <span
                                                style={{
                                                    fontWeight: "bold",
                                                    textDecoration: "underline",
                                                    fontStyle: "italic",
                                                    margin: "0px 5px"
                                                }}>Samstag, den 30. November 2024</span>um<span
                                                style={{
                                                    fontWeight: "bold",
                                                    textDecoration: "underline",
                                                    fontStyle: "italic",
                                                    margin: "0px 5px"
                                                }}>18:30 Uhr</span>im<span
                                                style={{
                                                    fontWeight: "bold",
                                                    textDecoration: "underline",
                                                    fontStyle: "italic",
                                                    margin: "0px 5px"
                                                }}>Gasthaus Taverne in Hohenfels</span> ein. Für
                                            Speis´ und Trank ist wie immer bestens gesorgt!.</p>
                                    </div>
                                    <div>
                                        <table style={{
                                            border: "solid 1px #666",
                                            borderCollapse: "collapse",
                                            borderSpacing: "initial"
                                        }}>
                                            <tr>
                                                <th style={{
                                                    borderBottom: "solid 1px #666",
                                                    backgroundColor: "lightgray",
                                                    padding: 10
                                                }}>
                                                    <span style={{fontWeight: "bold", textDecoration: "underline"}}>Bitte beachte:</span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td style={{padding: 10}}>

                                                    <p>
                                                        Solltest Du, trotz Anmeldung <span style={{
                                                        fontWeight: "bold",
                                                        textDecoration: "underline"
                                                    }}>nicht</span> bei der Weihnachtsfeier erscheinen, wird der
                                                        Unkostenbeitrag i. H. v. 10,00 € von Deinem Konto abgebucht.
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>

                                    </div>

                                    <div style={{marginBottom: 5, marginTop: 5}}>
                                        Um für den Abend besser planen zu können, bitten wir Dich,
                                        Deine verbindliche Zusage bis spätestens
                                        <span style={{color: "#f00", fontWeight: "bold"}}>&nbsp;Sonntag, den 17. November 2024&nbsp;</span>
                                        abzugeben.
                                    </div>
                                    <div style={{fontWeight: "bold", color: "#000", textAlign: "center"}}>
                                        <p>Auf Dein Kommen freut sich die Vorstandschaft des</p>
                                        <p> FC Bayern-Fanclubs Forellenbachtal Hohenfels</p>

                                    </div>
                                </div>
                                {(new Date() < new Date("2024-11-20")) &&
                                    <div style={{borderTop: "dotted 1px #008BFF", marginTop: 20}}>
                                        <p style={{
                                            color: "#f00",
                                            fontWeight: "bold",
                                            maxWidth: 750,
                                            textAlign: "center"
                                        }}>Anmeldung zur Weihnachtsfeier am 30.11.2024 im Gasthaus Taverne, Hohenfels
                                        </p>

                                        <table style={{width: "100%"}}>
                                            <tr>
                                                <th style={{maxWidth: "50%"}}>Anmeldebestätigung für</th>
                                                <th style={{maxWidth: "50%"}}>Folgende Personen begleiten mich
                                                </th>
                                            </tr>
                                            <tr>
                                                <td><span
                                                    style={{color: "#bbb"}}> Anmeldenummer:</span> {currentUser.currentEvent.registerNumber}
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>{currentUser.currentEvent.firstName}&nbsp;{currentUser.currentEvent.lastName}</td>
                                                <td><label><input id="partner" onChange={setCurrentEvent}
                                                                  checked={currentUser.currentEvent.partner}
                                                                  type="checkbox" style={{
                                                    width: 25,
                                                    height: 25,
                                                    margin: 0,
                                                    marginRight: 14
                                                }}/>&nbsp;Partner/in</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td><label><input type="text" onChange={setCurrentEvent}
                                                                  id="kinderBis7"
                                                                  value={currentUser.currentEvent.kinderBis7}/>&nbsp;1x
                                                    Kind(er) unter 7 Jahre (frei)</label></td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td><label><input type="text" onChange={setCurrentEvent}
                                                                  id="kinderAb7"
                                                                  value={currentUser.currentEvent.kinderAb7}/>&nbsp;1x
                                                    Kind(er) älter oder gleich 7 Jahre</label></td>
                                            </tr>

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </table>

                                        <hr/>
                                        <table>
                                            <tr>
                                                <td style={{color: "#f00", fontWeight: "bold"}}>
                                                    <input type="checkbox" onChange={setCurrentEvent}
                                                           id="unkostenbeitrag"
                                                           checked={currentUser.currentEvent.unkostenbeitrag}/>
                                                    Über die anfallenden
                                                    Kosten von 10,00 €
                                                    bei Nichterscheinen trotz Anmeldung bin ich hiermit informiert. Mit
                                                    Absenden des Formulars
                                                    bestätige ich meine verbindliche Anmeldung zu der Veranstaltung.
                                                </td>

                                            </tr>
                                            <tr>

                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <TextField
                                                        style={{maxWidth: 650}} onKeyUp={setCurrentEvent}
                                                        id="bemerkungen"
                                                        label="Bemerkung"
                                                        multiline
                                                        rows={4}
                                                        defaultValue={currentUser.currentEvent.bemerkungen}
                                                        variant="outlined"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <Button variant="outlined" className="btnSubmit"
                                                            onClick={eventRegister}>
                                                        Jetzt anmelden
                                                    </Button>
                                                </td>

                                            </tr>
                                        </table>

                                    </div>
                                }
                                {(new Date() > new Date("2024-11-20")) &&
                                    <div style={{color: "#f00", fontSize: 20, fontWeight: "bold"}}>Die Anmeldefrist ist
                                        leider abgelaufen! Bitte wende dich direkt an den Vorstand, wenn du noch dabei
                                        sein möchtest.</div>
                                }

                            </div>}
                    </article>
                    <article className="articleContent" style={{display: "none"}}>
                        <h3>Weihnachtsfeier</h3>

                        <p>
                            Aktuell steht für Weihnachtsfeier 2023 noch kein Termin fest.
                        </p>
                        <p>Der Markt Hohenfels wird aber voraussichtlich wieder 2023 einen Weihnachtsmarkt
                            veranstalten.</p>
                        <p>Vom Bayern-Fanclub werden wir wieder mit einem Stand wieder dabei sein.</p>
                    </article>
                </TabPanel>
            </SwipeableViews>
            <AppSnackbar message={appData.succeded ? appData.succededText : appData.errorText}
                         showSnackbar={appData.succeded || appData.failed}
                         severity={appData.failed ? "error" : "success"}
            />
        </div>
    )
}

export default withRouter(Termine);
