import React, {useEffect} from "react";
import {vorstand} from "stammdaten";
import {withRouter} from "react-router-dom";


function Impressum() {

    useEffect(() => {
        let bg = document.getElementById("bg");
        if (bg !== undefined && bg !== null)
            bg.className = 'hohenfels';
    }, []);


    return (
        <div className="content">

            <div>
                <h2>Impressum des FC Bayern Fanclub Forellenbachtal Hohenfels</h2>
                <article>
                    <ul>
                        <li>{vorstand.ErsterVorstand.Name}</li>
                        <li>FC Bayern Fanclub Forellenbachtal Hohenfels</li>
                        <li>{vorstand.ErsterVorstand.Strasse}</li>
                        <li>{vorstand.ErsterVorstand.Ort}</li>
                        <li>Deutschland</li>
                        <li>Tel.:{vorstand.ErsterVorstand.Tel}</li>
                        <li>E-Mail:{vorstand.ErsterVorstand.Mail}</li>
                    </ul>
                    <p>
                        Plattform der EU-Kommission zur Online-Streitbeilegung: <a href="https://ec.europa.eu/odr"
                                                                                   target="_blank">https://ec.europa.eu/odr</a>
                    </p>
                    <p>
                        Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer
                        Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
                    </p>
                    <p>
                        Verantwortliche/r i.S.d. § 18 Abs. 2 MStV:
                    </p>
                    <ul>
                        <li>{vorstand.ErsterVorstand.Name}</li>
                        <li>FC Bayern Fanclub Forellenbachtal Hohenfels</li>
                        <li>{vorstand.ErsterVorstand.Strasse}</li>
                        <li>{vorstand.ErsterVorstand.Ort}</li>

                    </ul>
                </article>

                <article>
                    <h4>Urheberrechtshinweis</h4>
                    <p>Auf der Webseite werden teilweise Bilder von folgenden Drittanbietern verwendet:</p>
                    <ul>
                        <li>
                            Icon by <a href="https://freeicons.io/profile/3">icon king1</a> on <a href="https://freeicons.io">freeicons.io</a>
                        </li>
                        <li>
                            Icon by <a href="https://freeicons.io/profile/714">Raj Dev</a> on <a href="https://freeicons.io">freeicons.io</a>
                        </li>
                        <li>
                            Icon by <a href="https://freeicons.io/profile/2257">www.wishforge.games</a> on <a href="https://freeicons.io">freeicons.io</a>
                        </li>
                        <li>
                            Icon by <a href="https://freeicons.io/profile/730">Anu Rocks</a> on <a href="https://freeicons.io">freeicons.io</a>
                        </li>

                    </ul>

                </article>
            </div>
        </div>
    )
}

export default withRouter(Impressum);
