import {Route, Switch} from "react-router"
import Aktuelles from "../views/aktuelles";
import BilderArchiv from "../views/bilder";
import Datenschutz from "../views/datenschutz";
import Impressum from "../views/impressum";
import Verein from "../views/verein";
import Kontakt from "../views/kontakt";
import Termine from "../views/termine";
import WebArchive from "../views/archiv";
import WebLinks from "../views/links";
import FacebookSite from "../views/facebook";
import BenutzerSeite from "../views/benutzer";
import AnmeldeSeite from "../views/anmelden";
import AdminSeite from "../views/admin";
import AbmeldeSeite from "../views/abmelden";
import DatenverarbeitungSeite from "../views/datenverarbeitung";

function AppRouter() {
    return (
        <Switch >
            <Route exact path="/">
                <Aktuelles/>
            </Route>
            <Route path="/Aktuelles">
                <Aktuelles/>
            </Route>
            <Route path="/Verein">
                <Verein/>
            </Route>
            <Route path="/Termine">
                <Termine/>
            </Route>
            <Route path="/Bilder">
                <BilderArchiv/>
            </Route>
            <Route path="/Kontakt">
                <Kontakt/>
            </Route>
            <Route path="/Impressum">
                <Impressum/>
            </Route>
            <Route path="/Datenschutz">
                <Datenschutz/>
            </Route>
            <Route path="/Links">
                <WebLinks/>
            </Route>
            <Route path="/Archiv">
                <WebArchive />
            </Route>
            <Route path="/Facebook">
                <FacebookSite />
            </Route>
            <Route path="/Admin">
                <AdminSeite />
            </Route>
            <Route path="/Anmelden">
                <AnmeldeSeite />
            </Route>
            <Route path="/Benutzer">
                <BenutzerSeite />
            </Route>
            <Route path="/Abmelden">
                <AbmeldeSeite />
            </Route>
            <Route path="/Datenverarbeitung">
                <DatenverarbeitungSeite />
            </Route>
            <Route  >
                <Aktuelles/>
            </Route>
        </Switch>
    )
}

export default AppRouter;
