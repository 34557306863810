import "../sass/footer.sass";

function SiteFooter() {
    return (
        <footer className="copyright">

            &copy; 2021 - FC Bayern Fanclub Forellenbachtal Hohenfels
        </footer>
    )
}

export default SiteFooter;
